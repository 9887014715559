import { Component } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import BookingManagerListItem from './booking_manager_list_item';

class BookingManagerListItems extends Component {
  renderItems() {
    if (this.props.bookings.length === 0) {
      return (
        <Card>
          <Card.Body>
            <p className="text-center">No Records</p>
          </Card.Body>
        </Card>
      );
    }
    return this.props.bookings.map((model, index) => (
      <BookingManagerListItem
        key={index}
        model={model}
        currentContact={this.props.currentContact}
        onListItemCalendarClicked={this.props.onListItemCalendarClicked}
        onFlightCompleteClicked={this.props.onFlightCompleteClicked}
        onDeleteClicked={this.props.onDeleteClicked}
        onCloneClicked={this.props.onCloneClicked}
        currentSettingsBookingCalendarTypeBanner={
          this.props.currentSettingsBookingCalendarTypeBanner
        }
        currentSettingsBookingCalendarTypeFlight={
          this.props.currentSettingsBookingCalendarTypeFlight
        }
        currentSettingsBookingStatusCancelled={
          this.props.currentSettingsBookingStatusCancelled
        }
        aircraftQuickData={this.props.aircraftQuickData}
        contactQuickData={this.props.contactQuickData}
      />
    ));
  }

  render() {
    return this.renderItems();
  }
}

BookingManagerListItems.propTypes = {
  currentContact: PropTypes.object,
  onListItemCalendarClicked: PropTypes.func,
  onFlightCompleteClicked: PropTypes.func,
  onDeleteClicked: PropTypes.func,
  onCloneClicked: PropTypes.func,
};

export default BookingManagerListItems;
