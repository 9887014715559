import { Modal, Button, ToggleButton, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

class FlightSegmentAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      includePax: false,
    };
    this.handlePaxToggle = this.handlePaxToggle.bind(this);
    this.handleAddCancel = this.handleAddCancel.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
  }

  handleAddCancel() {
    this.resetState();
    if (this.props.handleAddFlightSegmentCancel) {
      this.props.handleAddFlightSegmentCancel();
    }
  }

  handleAddSubmit(e) {
    const addType = e.currentTarget.getAttribute('data-add-type');
    if (this.props.handleAddFlightSegmentSubmit) {
      this.props.handleAddFlightSegmentSubmit(addType, this.state.includePax);
    }
    this.resetState();
  }

  handlePaxToggle() {
    this.setState((prevState) => ({ includePax: !prevState.includePax }));
  }

  resetState() {
    this.setState({
      includePax: false,
    });
  }

  render() {
    const { show, index } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${index}-add-modal`}
        show={show}
        onHide={this.handleAddCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add flight segment</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              <Button type="button" variant="danger" onClick={this.handleAddCancel}>
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          <ButtonToolbar>
            <ButtonGroup>
              <ToggleButton
                id="toggle-pax"
                type="checkbox"
                variant="secondary"
                onChange={this.handlePaxToggle}
                checked={this.state.includePax}
              >
                {this.state.includePax ? 'With Pax' : 'No Pax'}
              </ToggleButton>
            </ButtonGroup>
          </ButtonToolbar>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                data-add-type={index === 'last' ? 'new' : 'newAbove'}
                type="button"
                variant="primary"
                onClick={this.handleAddSubmit}
              >
                {index === 'last' ? 'New' : 'Above'}
              </Button>
              <Button
                data-add-type={index === 'last' ? 'return' : 'newBelow'}
                type="button"
                variant="primary"
                onClick={this.handleAddSubmit}
              >
                {index === 'last' ? 'Return' : 'Below'}
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlightSegmentAddModal;
