import { useState, useCallback, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';

import last from 'lodash.last';
import get from 'lodash.get';

import InputField from './input_field';
import HelpBlock from './help_block';
import InvalidBlock from './invalid_block';
import FileTypeIcon from '../file_type_icon';

const DocumentField = (props) => {
  const {
    formValues: { document_file_name: documentFileName },
    labelWidth,
    inputWidth,
    size,
    helpText,
    input,
    meta,
    children,
  } = props;

  const [fileExtension, setFileExtension] = useState('');

  useEffect(() => {
    if (documentFileName) {
      const fileNameParts = documentFileName.split('.');
      if (fileNameParts.length > 1) {
        setFileExtension(last(fileNameParts).toLowerCase().replace('jpeg', 'jpg'));
      } else {
        setFileExtension('unknown');
      }
    } else {
      setFileExtension('unknown');
    }
  }, [documentFileName]);

  const handleFileChange = useCallback(
    (e) => {
      const file = get(e, 'target.files.0');
      if (file) {
        const newFileName = get(file, 'name');
        const fileParts = newFileName.split('.');
        if (fileParts.length > 1) {
          setFileExtension(last(fileParts).toLowerCase().replace('jpeg', 'jpg'));
        } else {
          setFileExtension('unknown');
        }
        input.onChange(file);
      }
    },
    [input]
  );

  return (
    <>
      <Row>
        <Col>
          <InputField
            size={size}
            labelWidth={labelWidth}
            inputWidth={inputWidth}
            input={{
              name: input.name,
            }}
            innerContent={
              <>
                <Form.Control type="file" value={undefined} onChange={handleFileChange} />
                <InvalidBlock meta={meta} helpText={helpText} force />
                <HelpBlock meta={meta} helpText={helpText} />
              </>
            }
          >
            {children}
          </InputField>
        </Col>
      </Row>
      <Row>
        <Col sm={{ span: inputWidth, offset: labelWidth }}>
          <FileTypeIcon extension={fileExtension} size="x2" />
        </Col>
      </Row>
    </>
  );
};

DocumentField.defaultProps = {
  labelWidth: 3,
  inputWidth: 9,
  helpText: '',
  size: 'sm',
};

export default DocumentField;
