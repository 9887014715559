import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { Col, Row, Form, Button } from 'react-bootstrap';
import {
  reduxForm,
  Field,
  Fields,
  FieldArray,
  getFormValues,
  arrayPush as pushArrayValue,
  arrayRemove as removeArrayValue,
  change as changeFieldValue,
} from 'redux-form';
import moment from 'moment';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import forEach from 'lodash.foreach';
import get from 'lodash.get';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';

import InputField from '../components/form/input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import DocumentFields from '../components/form/document_fields';
import Glyphicon from '../components/glyphicon';
import Title from '../components/title';
import Loader from '../components/loader';
import FormButtons from '../components/form/form_buttons';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import checkQuery from '../queries/check_query';
import checkUpdateMutation from '../mutations/check_update_mutation';
import checkCreateMutation from '../mutations/check_create_mutation';
import checkTypeListQuery from '../queries/check_type_list_query';
import checkCategoryListQuery from '../queries/check_category_list_query';
import otherAssetListQuery from '../queries/other_asset_list_query';
import contactListQuery from '../queries/contact_list_query';

import { queriesReady, queryReady, typeInput, getSelectable } from '../lib/utils';

moment.updateLocale('en-nz');

let isInitialisedCheckForm = false;

class CheckForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCompleteSubmit = this.handleCompleteSubmit.bind(this);
    this.handleRolloverSubmit = this.handleRolloverSubmit.bind(this);
    this.handleNewDocumentClick = this.handleNewDocumentClick.bind(this);
    this.handleDeleteDocumentClick = this.handleDeleteDocumentClick.bind(this);
    this.mapCheckableOptions = this.mapCheckableOptions.bind(this);
    this.renderDocumentFieldArray = this.renderDocumentFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      get(this.props, 'formValues.check_category_id') &&
      nextProps.formValues.check_category_id !== this.props.formValues.check_category_id
    ) {
      if (nextProps.formValues.check_type_id) {
        this.props.changeFieldValue(this.props.form, 'check_type_id', '');
      }
      if (nextProps.formValues.checkable_id) {
        this.props.changeFieldValue(this.props.form, 'checkable_id', '');
      }
    }
  }

  componentWillUnmount() {
    isInitialisedCheckForm = false;
  }

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    }
    return 'No';
  };

  getSelectableManagers() {
    return getSelectable(
      get(this.props, 'managerListQuery.data', []),
      get(this.props, 'formValues.manager_id'),
      'fullName',
      'roleManager'
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    forEach(submitData.documents_attributes, (da) => {
      if (da.document) {
        // eslint-disable-next-line no-param-reassign
        da.uploader_id = this.props.currentContact.id;
        // eslint-disable-next-line no-param-reassign
        da.uploaded_at = moment().format();
      }
    });
    const checkCategoryIdValue = submitData.check_category_id;
    if (checkCategoryIdValue) {
      const checkCategory = this.props.checkCategoryListQuery.data.find(
        (model) => model.id === parseInt(checkCategoryIdValue, 10)
      );
      submitData.checkable_type =
        this.props.currentSettingsCheckCategoryCheckableGroups[
          checkCategory.checkable_group
        ];
    }
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(submitData) },
      context: { hasUpload: true },
    };
    if (this.state.updating) {
      mutation = this.props.checkUpdateMutation;
      mutationMessage = 'Check update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.checkCreateMutation;
      mutationMessage = 'Check create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/checks');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleCompleteSubmit(data) {
    const submitData = cloneDeep(data);
    const today = new Date();
    submitData.check_complete_on = today.toISOString().substring(0, 10);
    this.handleSubmit(submitData);
  }

  handleRolloverSubmit(data) {
    const submitData = cloneDeep(data);
    submitData.rollover = true;
    this.handleCompleteSubmit(submitData);
  }

  handleNewDocumentClick() {
    this.props.pushArrayValue(this.props.form, 'documents_attributes', {});
  }

  handleDeleteDocumentClick(index, id) {
    if (id) {
      this.props.changeFieldValue(
        this.props.form,
        `documents_attributes[${index}]._destroy`,
        true
      );
    } else {
      this.props.removeArrayValue(this.props.form, 'documents_attributes', index);
    }
  }

  mapCheckableOptions() {
    const checkCategoryIdValue = this.props.formValues.check_category_id;
    if (checkCategoryIdValue) {
      const { checkable_group: checkableGroup } =
        this.props.checkCategoryListQuery.data.find(
          (model) => model.id === parseInt(checkCategoryIdValue, 10)
        );
      if (checkableGroup === 'pilots') {
        return getSelectable(
          get(this.props, 'pilotListQuery.data', []),
          get(this.props, 'formValues.checkable_id'),
          'fullName',
          'rolePilot'
        );
      }
      if (checkableGroup === 'employees') {
        return getSelectable(
          get(this.props, 'employeeListQuery.data', []),
          get(this.props, 'formValues.checkable_id'),
          'fullName',
          'roleEmployee'
        );
      }
      if (checkableGroup === 'passengers') {
        return getSelectable(
          get(this.props, 'passengerListQuery.data', []),
          get(this.props, 'formValues.checkable_id'),
          'fullName',
          'rolePassenger'
        );
      }
      if (checkableGroup === 'equipment') {
        return this.props.otherAssetListQuery.data.map((model) => ({
          id: model.id,
          name: model.name,
        }));
      }
      if (checkableGroup === 'tenant') {
        return [
          { id: this.props.currentTenant.id, name: this.props.currentTenant.fullName },
        ];
      }
      return [];
    }
    return [];
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.checkTypeListQuery,
      testProps.checkCategoryListQuery,
      testProps.otherAssetListQuery,
      testProps.pilotListQuery,
      testProps.managerListQuery,
      testProps.employeeListQuery,
      testProps.passengerListQuery,
      [testProps.checkQuery, true] // ignore if undefined
    );
  }

  renderDocumentFieldArray(documents) {
    return (
      <Row>
        {documents.fields.map((field, index) => {
          const values = this.props.formValues.documents_attributes[index] || {};
          if (!values._destroy) {
            return (
              <Fields
                key={field}
                index={index}
                formValues={values}
                names={[`${field}.document`, `${field}.description`]}
                component={DocumentFields}
                handleDelete={this.handleDeleteDocumentClick}
              />
            );
          }
          return undefined;
        })}
        <Col sm={{ span: 9, offset: 3 }}>
          <Button type="button" variant="dark" onClick={this.handleNewDocumentClick}>
            <Glyphicon glyph="plus" />
            Add Document
          </Button>
        </Col>
      </Row>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedCheckForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, formValues } = this.props;
      const checkTypeId = this.props.formValues.check_type_id;
      let checkType = {
        critical: false,
        lead_period_days: '',
        rollover_period_months: '',
      };
      if (checkTypeId) {
        checkType = this.props.checkTypeListQuery.data.find(
          (model) => model.id === parseInt(checkTypeId, 10)
        );
      }
      const {
        critical,
        rollover_period_months: rolloverPeriodMonths,
        lead_period_days: leadPeriodDays,
      } = checkType;

      const completed = !!this.props.formValues.check_complete_on;

      return (
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Title form updating={this.state.updating}>
            Check
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Check Details</legend>
            <Field
              type="text"
              name="check_category_id"
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.checkCategoryListQuery.data.map((model) => ({
                id: model.id,
                name: model.name,
              }))}
            >
              Category
            </Field>
            <Field
              type="text"
              name="check_type_id"
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.checkTypeListQuery.data
                .filter(
                  (model) =>
                    model.check_category_id === parseInt(formValues.check_category_id, 10)
                )
                .map((model) => ({ id: model.id, name: model.name }))}
            >
              Type
            </Field>
            <Field
              type="text"
              name="checkable_id"
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.mapCheckableOptions()}
            >
              Checkable
            </Field>
            <Field
              type="text"
              name="manager_id"
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableManagers()}
            >
              Responsible Manager
            </Field>
            <Field
              name="check_end_on"
              inputWidth={3}
              component={ReactDateTimeField}
              helpText="DD/MM/YYYY"
              dateFormat="DD/MM/YYYY"
              timeFormat={false}
              closeOnSelect
            >
              Check Due On
            </Field>
            {this.state.updating && (
              <InputField
                type="text"
                plainText
                labelWidth={3}
                inputWidth={3}
                input={{ name: 'critical', value: this.displayBoolean(critical) }}
              >
                Critical
              </InputField>
            )}
            {this.state.updating && (
              <InputField
                type="text"
                plainText
                labelWidth={3}
                inputWidth={3}
                input={{ name: 'rollover_period_months', value: rolloverPeriodMonths }}
              >
                Rollover Period Months
              </InputField>
            )}
            {this.state.updating && (
              <InputField
                type="text"
                plainText
                labelWidth={3}
                inputWidth={3}
                input={{ name: 'lead_period_days', value: leadPeriodDays }}
              >
                Lead Period Days
              </InputField>
            )}
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Manage Documents</legend>
            <FieldArray
              name="documents_attributes"
              component={this.renderDocumentFieldArray}
            />
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/checks"
              handleSubmit={handleSubmit}
              name="Check"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function validate() {
  return {};
}

const checkWhiteList = [
  'id',
  'check_type_id',
  'check_category_id',
  'checkable_type',
  'checkable_id',
  'manager_id',
  'check_start_on',
  'check_end_on',
  'check_complete_on',
];

const documentWhiteList = ['id', 'document_file_name', 'description'];

function pickInitialValues(checkQueryInitial, updating) {
  if (!isInitialisedCheckForm) {
    if (!updating) {
      isInitialisedCheckForm = true;
      return {
        check_end_on: moment().add(1, 'months').format('YYYY-MM-DD'),
      };
    }
    if (queryReady(checkQueryInitial)) {
      isInitialisedCheckForm = true;
      const check = pick(
        omitBy(cloneDeep(checkQueryInitial.data), isNil),
        checkWhiteList
      );
      check.documents_attributes = clone(
        get(checkQueryInitial, 'data.documents', [])
      ).map((doc) => pick(omitBy(doc, isNil), documentWhiteList));
      return check;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.checkQuery, !!props.params.id);
  return {
    initialValues,
    currentTenant: state.currentTenant,
    currentContact: state.currentContact,
    // eslint-disable-next-line max-len
    currentSettingsCheckCategoryCheckableGroups:
      state.currentSettings.check_category_checkable_groups,
    currentSettingsMutating: state.currentSettings.mutating,
    formValues: getFormValues('CheckForm')(state),
  };
}

export default compose(
  graphql(checkCreateMutation, {
    name: 'checkCreateMutation',
  }),
  graphql(checkUpdateMutation, {
    name: 'checkUpdateMutation',
  }),
  graphql(contactListQuery, {
    name: 'managerListQuery',
    options: { variables: { role: 'manager' } },
  }),
  graphql(contactListQuery, {
    name: 'pilotListQuery',
    options: { variables: { role: 'pilot' } },
  }),
  graphql(contactListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  graphql(contactListQuery, {
    name: 'passengerListQuery',
    options: { variables: { role: 'passenger' } },
  }),
  graphql(checkTypeListQuery, {
    name: 'checkTypeListQuery',
  }),
  graphql(checkCategoryListQuery, {
    name: 'checkCategoryListQuery',
  }),
  graphql(otherAssetListQuery, {
    name: 'otherAssetListQuery',
  }),
  graphql(checkQuery, {
    name: 'checkQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    pushArrayValue,
    removeArrayValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'CheckForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(CheckForm);
