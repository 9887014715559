const DlHorizontal = ({ dt, dd, children, size, nameWidth, descriptionWidth }) => (
  <dl className="row mb-2">
    <dt className={`col-${size}-${nameWidth} text-end`}>{dt}</dt>
    <dd className={`ps-0 mb-0 col-${size}-${descriptionWidth}`}>{dd || children}</dd>
  </dl>
);

DlHorizontal.defaultProps = {
  size: 'sm',
  nameWidth: 5,
  descriptionWidth: 7,
};

export default DlHorizontal;
