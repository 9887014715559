import actionTypes from '../constants';

import { datasLoad, datasReset } from './data_actions';

export function storeReset() {
  return function storeResetDispatch(dispatch) {
    dispatch(datasReset());
  };
}

export function storeLoad() {
  return function storeLoadDispatch(dispatch) {
    dispatch(datasLoad());
  };
}

export function storeReload() {
  return function storeReloadDispatch(dispatch) {
    dispatch(storeReset());
    dispatch(storeLoad());
  };
}

export function storeLocalLoad() {
  return {
    type: actionTypes.LOCAL_LOAD,
  };
}
