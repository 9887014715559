import { useState, useEffect } from 'react';
import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import InputField from '../form/input_field';

function DutyEventModal(props) {
  const {
    show,
    rosterId,
    dutyEvent = {},
    handleDutyEventModalCancel,
    handleDutyEventModalUpdate,
    handleDutyEventModalDelete,
  } = props;
  const {
    id: dutyEventId,
    name,
    start_time: origStartTime,
    end_time: origEndTime,
    duty_notes: origDutyNotes,
  } = dutyEvent;

  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [dutyNotes, setDutyNotes] = useState('');

  useEffect(() => {
    let newStartTime = '';
    if (origStartTime) {
      newStartTime = origStartTime;
    }
    setStartTime(newStartTime);
  }, [origStartTime]);

  useEffect(() => {
    let newEndTime = '';
    if (origEndTime) {
      newEndTime = origEndTime;
    }
    setEndTime(newEndTime);
  }, [origEndTime]);

  useEffect(() => {
    let newDutyNotes = '';
    if (origDutyNotes) {
      newDutyNotes = origDutyNotes;
    }
    setDutyNotes(newDutyNotes);
  }, [origDutyNotes]);

  return (
    <Modal
      id={`roster-${rosterId}-duty-event-modal-${dutyEventId}`}
      show={show}
      onHide={handleDutyEventModalCancel}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={4}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-start-time`,
            value: startTime,
            onChange: (e) => {
              setStartTime(e.target.value);
            },
          }}
        >
          Duty Start Time
        </InputField>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={4}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-end-time`,
            value: endTime,
            onChange: (e) => {
              setEndTime(e.target.value);
            },
          }}
        >
          Duty End Time
        </InputField>
        <InputField
          type="text"
          size="sm"
          labelWidth={4}
          inputWidth={8}
          asElement="textarea"
          rows={3}
          input={{
            name: `roster-${rosterId}-duty-event-modal-${dutyEventId}-duty-notes`,
            value: dutyNotes,
            onChange: (e) => {
              setDutyNotes(e.target.value);
            },
          }}
        >
          Duty Notes
        </InputField>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="d-flex justify-content-between w-100">
          <ButtonGroup>
            <Button type="button" variant="danger" onClick={handleDutyEventModalDelete}>
              Delete Duty Event
            </Button>
          </ButtonGroup>
          <ButtonGroup>
            <Button
              type="button"
              variant="secondary"
              onClick={handleDutyEventModalCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              variant="primary"
              // disabled={!this.state.firstName}
              onClick={() =>
                handleDutyEventModalUpdate({ startTime, endTime, dutyNotes })
              }
            >
              Save
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
}

export default DutyEventModal;
