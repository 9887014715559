import { Col, Row, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import {
  reduxForm,
  Field,
  Fields,
  FieldArray,
  getFormValues,
  arrayPush as pushArrayValue,
  arrayRemove as removeArrayValue,
  change as changeFieldValue,
} from 'redux-form';

import get from 'lodash.get';
import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import intersection from 'lodash.intersection';
import isEqual from 'lodash.isequal';
import isNil from 'lodash.isnil';
import forEach from 'lodash.foreach';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';
import snakeCase from 'lodash.snakecase';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { tagsCreate } from '../actions/tag_actions';

import Loader from '../components/loader';
import DocumentFields from '../components/form/document_fields';
import InputField from '../components/form/input_field';
import TagField from '../components/form/tag_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import CheckboxMultipleInputField from '../components/form/checkbox_multiple_input_field';
import ReactDateTimeField from '../components/form/react_date_time_field';
import Glyphicon from '../components/glyphicon';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';

import ContactFormPhotoField from '../components/contact_form/contact_form_photo_field';
import ContactFormEmployeeColorField from '../components/contact_form/contact_form_employee_color_field';
import ContactFormPhoneNumberFieldArray from '../components/contact_form/contact_form_phone_number_field_array';
import ContactFormAddressFieldArray from '../components/contact_form/contact_form_address_field_array';

import {
  queriesReady,
  queryReady,
  queryJustReady,
  typeInput,
  getTaggingsNameArray,
  getSelectable,
} from '../lib/utils';

import contactEditQuery from '../queries/contact_edit_query';
import contactUpdateMutation from '../mutations/contact_update_mutation';
import contactCreateMutation from '../mutations/contact_create_mutation';

import aircraftListQuery from '../queries/aircraft_list_query';
import contactListQuery from '../queries/contact_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import roleListQuery from '../queries/role_list_query';
import statutoryRoleListQuery from '../queries/statutory_role_list_query';

moment.updateLocale('en-nz');

let isInitialisedContactForm = false;

class ContactForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      isPassworded: false,
      isChargeable: false,
      isEmployee: false,
      isManager: false,
      isProvider: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagCreate = this.handleTagCreate.bind(this);
    this.renderPhoneNumberFieldArray = this.renderPhoneNumberFieldArray.bind(this);
    this.renderAddressFieldArray = this.renderAddressFieldArray.bind(this);
    this.handleNewDocumentClick = this.handleNewDocumentClick.bind(this);
    this.handleDeleteDocumentClick = this.handleDeleteDocumentClick.bind(this);
    this.renderDocumentFieldArray = this.renderDocumentFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (queryReady(nextProps.roleListQuery)) {
      if (
        queryJustReady(this.props.roleListQuery, nextProps.roleListQuery) ||
        !isEqual(
          get(this.props, 'formValues.role_ids'),
          get(nextProps, 'formValues.role_ids')
        )
      ) {
        const roles = nextProps.roleListQuery.data.filter(
          (role) => get(nextProps, 'formValues.role_ids', []).indexOf(role.id) > -1
        );
        this.setState({
          isManager: roles.some((role) => role.manager),
          isEmployee: roles.some((role) => role.employee),
          isChargeable: roles.some((role) => role.chargeable),
          isProvider: roles.some((role) => role.provider),
          isPassworded: roles.some((role) => role.passworded),
        });
      }
    }
  }

  componentWillUnmount() {
    isInitialisedContactForm = false;
  }

  getSelectableProviders() {
    return getSelectable(
      get(this.props, 'contactListQuery.data', []),
      get(this.props, 'formValues.default_provider_id'),
      'fullName',
      'roleProvider'
    );
  }

  getSelectableAircrafts() {
    const aircrafts = get(this.props, 'aircraftListQuery.data', []).filter(
      (a) => a.provider_id === parseInt(this.props.params.id, 10)
    );
    return getSelectable(
      aircrafts,
      get(this.props, 'formValues.default_aircraft_id'),
      'registration_abbreviated'
    );
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftListQuery,
      testProps.flightTypeListQuery,
      testProps.contactListQuery,
      testProps.roleListQuery,
      testProps.statutoryRoleListQuery,
      [testProps.contactQuery, true] // ignore if undefined
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    submitData.tag_list = submitData.tag_collection.map((tag) => tag.text).join(',');
    delete submitData.tag_collection;
    forEach(submitData.documents_attributes, (da) => {
      if (da.document) {
        // eslint-disable-next-line no-param-reassign
        da.uploader_id = this.props.currentContact.id;
        // eslint-disable-next-line no-param-reassign
        da.uploaded_at = moment().format();
      }
    });
    if (!this.state.isManager) {
      submitData.statutory_role_ids = [];
    }
    if (submitData.company) {
      const companyRoleIds = this.props.roleListQuery.data
        .filter((role) => role.chargeable || role.provider)
        .map((role) => role.id);
      submitData.role_ids = intersection(companyRoleIds, submitData.role_ids);
    }
    if (!this.state.isEmployee) {
      delete submitData.employer_id;
    }
    if (!this.state.isChargeable) {
      delete submitData.default_provider_id;
      delete submitData.default_flight_type_id;
    }
    if (!this.state.isProvider) {
      delete submitData.default_aircaft_id;
    }
    submitData.last_updated_by_id = this.props.currentContact.id;
    // return
    // console.log(submitData)
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(submitData) },
      context: { hasUpload: true },
    };
    if (this.state.updating) {
      mutation = this.props.contactUpdateMutation;
      mutationMessage = 'Contact update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.contactCreateMutation;
      mutationMessage = 'Contact create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/contacts');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleNewDocumentClick() {
    this.props.pushArrayValue(this.props.form, 'documents_attributes', {});
  }

  handleDeleteDocumentClick(index, id) {
    if (id) {
      this.props.changeFieldValue(
        this.props.form,
        `documents_attributes[${index}]._destroy`,
        true
      );
    } else {
      this.props.removeArrayValue(this.props.form, 'documents_attributes', index);
    }
  }

  handleTagCreate(tag) {
    this.props.tagsCreate({ value: snakeCase(tag), label: tag, type: 'Contact' });
  }

  selectOptionsRoles() {
    const {
      formValues,
      currentContact,
      roleListQuery: { data: roleListQueryData },
    } = this.props;
    return roleListQueryData.filter((role) => {
      const { chargeable, provider, passworded, pilot } = role;
      if (formValues.company) {
        return (chargeable && !pilot) || provider;
      }
      if (currentContact['manager?']) {
        return true;
      }
      if (!passworded && !provider) {
        return true;
      }
      return false;
    });
  }

  renderPhoneNumberFieldArray(phoneNumbers) {
    const formKeys = ['phone_numbers_attributes'];
    return (
      <ContactFormPhoneNumberFieldArray
        phoneNumbers={phoneNumbers}
        formValues={pick(this.props.formValues, formKeys)}
        phoneNumberTypes={this.props.currentSettingsPhoneNumberTypes.map((pnt) => ({
          id: pnt,
          name: pnt,
        }))}
        change={this.props.change}
      />
    );
  }

  renderAddressFieldArray(addresses) {
    const formKeys = ['addresses_attributes'];
    return (
      <ContactFormAddressFieldArray
        addresses={addresses}
        formValues={pick(this.props.formValues, formKeys)}
        addressTypes={this.props.currentSettingsAddressTypes.map((at) => ({
          id: at,
          name: at,
        }))}
        change={this.props.change}
      />
    );
  }

  renderDocumentFieldArray(documents) {
    return (
      <Row>
        {documents.fields.map((field, index) => {
          const values = this.props.formValues.documents_attributes[index] || {};
          if (!values._destroy) {
            return (
              <Fields
                key={field}
                index={index}
                formValues={values}
                names={[`${field}.document`, `${field}.description`]}
                component={DocumentFields}
                handleDelete={this.handleDeleteDocumentClick}
              />
            );
          }
          return undefined;
        })}
        <Col sm={{ span: 9, offset: 3 }}>
          <Button type="button" variant="dark" onClick={this.handleNewDocumentClick}>
            <Glyphicon glyph="plus" />
            Add Document
          </Button>
        </Col>
      </Row>
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedContactForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting, currentContact } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Title form updating={this.state.updating}>
            Contact
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Company or Individual</legend>
            <Field
              name="company"
              labelWidth={3}
              inputWidth={3}
              component={CheckboxInputField}
              helpText="Leave unchecked for person"
            >
              Is Company
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Roles</legend>
            <Field
              name="role_ids"
              component={CheckboxMultipleInputField}
              candidateList={this.selectOptionsRoles()}
              selectedIds={get(this.props, 'formValues.role_ids', [])}
              labelWidth={3}
              inputWidth={9}
              inputItemWidth={3}
            >
              Roles
            </Field>
            {currentContact['manager?'] &&
              this.state.isManager &&
              !this.props.formValues.company && (
                <Field
                  name="statutory_role_ids"
                  component={CheckboxMultipleInputField}
                  candidateList={this.props.statutoryRoleListQuery.data}
                  selectedIds={get(this.props, 'formValues.statutory_role_ids', [])}
                  labelWidth={3}
                  inputWidth={9}
                  inputItemWidth={3}
                >
                  Statutory Roles
                </Field>
              )}
            {this.state.isEmployee && (
              <Field
                type="text"
                name="employer_id"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={[
                  {
                    id: this.props.currentTenant.id,
                    name: this.props.currentTenant.fullName,
                  },
                ]}
              >
                Employer (because employee)
              </Field>
            )}
            {this.state.isEmployee && (
              <Field
                name="employee_color"
                component={ContactFormEmployeeColorField}
                employeeColor={get(this.props, 'formValues.employee_color')}
              >
                Employee Color (because employee)
              </Field>
            )}
            {this.state.isChargeable && (
              <Field
                type="text"
                name="default_provider_id"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.getSelectableProviders()}
              >
                Default Provider (because chargeable)
              </Field>
            )}
            {this.state.isChargeable && (
              <Field
                type="text"
                name="default_flight_type_id"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.props.flightTypeListQuery.data.map((ft) => ({
                  id: ft.id,
                  name: ft.name,
                }))}
              >
                Default Flight Type (because chargeable)
              </Field>
            )}
            {this.state.isProvider && this.state.updating && (
              <Field
                type="text"
                name="default_aircraft_id"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.getSelectableAircrafts()}
              >
                Default Aircraft (because provider)
              </Field>
            )}
            {this.state.isProvider && this.state.updating && (
              <Field
                name="use_first_if_no_default_aircraft"
                labelWidth={3}
                inputWidth={3}
                component={CheckboxInputField}
                helpText="Leave unchecked for blank aircraft selector in forms"
              >
                Use first aircraft if no default
              </Field>
            )}
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Tags</legend>
            <Field
              type="text"
              name="tag_collection"
              component={TagField}
              suggestions={this.props.tagsCollection.filter(
                (t) => t && t.type === 'Contact'
              )}
              handleTagCreate={this.handleTagCreate}
            >
              Tags
            </Field>
          </fieldset>
          {this.props.formValues.company ? (
            <fieldset className="border rounded-3 p-3">
              <legend className="float-none w-auto px-3 fs-6">Company Details</legend>
              <Field
                type="text"
                name="company_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Company Name
              </Field>
              <Field
                type="text"
                name="company_legal_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Company Legal Name
              </Field>
              <Field
                type="text"
                name="company_contact_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Company Contact Name
              </Field>
            </fieldset>
          ) : (
            <fieldset className="border rounded-3 p-3">
              <legend className="float-none w-auto px-3 fs-6">Individual Details</legend>
              <Field
                type="text"
                name="salutation"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.props.salutations.map((item) => ({
                  id: item,
                  name: item,
                }))}
              >
                Salutation
              </Field>
              <Field
                type="text"
                name="first_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                First Name
              </Field>
              <Field
                type="text"
                name="last_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Last Name
              </Field>
              <Field
                type="text"
                name="display_name"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Display Name
              </Field>
              <Field
                type="text"
                name="weight"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
                postAddon="kg"
              >
                Weight
              </Field>
              <Field
                name="declared_weight"
                labelWidth={3}
                inputWidth={3}
                component={CheckboxInputField}
                helpText="Weight declared"
              >
                Declared Weight
              </Field>
              <Field
                name="dob"
                inputWidth={3}
                component={ReactDateTimeField}
                helpText="DD/MM/YYYY"
                dateFormat="DD/MM/YYYY"
                timeFormat={false}
                closeOnSelect
              >
                Date of Birth
              </Field>
              <Field name="photo" component={ContactFormPhotoField}>
                {this.state.updating ? 'Update Photo' : 'Add Photo'}
              </Field>
            </fieldset>
          )}
          {this.state.isPassworded && (
            <fieldset className="border rounded-3 p-3">
              <legend className="float-none w-auto px-3 fs-6">Password Details</legend>
              <Field
                type="password"
                name="password"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Password
              </Field>
              <Field
                type="password"
                name="password_confirmation"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                Password Confirmation
              </Field>
            </fieldset>
          )}
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">
              Default Contact Details
            </legend>
            <Field
              type="text"
              name="email"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Email
            </Field>
            <Field
              type="text"
              name="flight_notes"
              labelWidth={3}
              inputWidth={6}
              component={InputField}
              asElement="textarea"
              helpText="Eg requires small step ladder and stowage of cane."
            >
              Flight Notes
            </Field>
            <Field
              type="text"
              name="other_notes"
              labelWidth={3}
              inputWidth={6}
              component={InputField}
              asElement="textarea"
              helpText="Eg notes not related to flying."
            >
              Other Notes
            </Field>
          </fieldset>

          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Phone Number Details</legend>
            <FieldArray
              name="phone_numbers_attributes"
              component={this.renderPhoneNumberFieldArray}
            />
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Address Details</legend>
            <FieldArray
              name="addresses_attributes"
              component={this.renderAddressFieldArray}
            />
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Manage Documents</legend>
            <FieldArray
              name="documents_attributes"
              component={this.renderDocumentFieldArray}
            />
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/contacts"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

// function validate(values) {
function validate() {
  return {};
}

const contactWhiteList = [
  'id',
  'company',
  'company_name',
  'company_legal_name',
  'company_contact_name',
  'default_provider_id',
  'default_flight_type_id',
  'default_aircraft_id',
  'declared_weight',
  'use_first_if_no_default_aircraft',
  'display_name',
  'dob',
  'email',
  'employee_color',
  'employer_id',
  'first_name',
  'flight_notes',
  'last_name',
  'last_updated_by_id',
  'other_notes',
  'photo',
  'password',
  'password_confirmation',
  'salutation',
  'weight',
];

const addressWhiteList = [
  'id',
  'address_type',
  'description',
  'prefix',
  'street_number',
  'suffix',
  'street',
  'suburb',
  'city',
  'region',
  'postcode',
  'post_office_box',
];

const phoneNumberWhiteList = [
  'id',
  'phone_number_type',
  'description',
  'country_code',
  'area_code',
  'phone_number',
  'extension',
  'default_phone_number',
];

const documentWhiteList = ['id', 'document_file_name', 'description'];

function pickInitialValues(contactQuery, updating) {
  if (!isInitialisedContactForm) {
    if (!updating) {
      isInitialisedContactForm = true;
      return {
        company: false,
        employee_color: '#BD10E0',
        role_ids: [],
        statutory_role_ids: [],
        tag_collection: [],
        phone_numbers_attributes: [],
        addresses_attributes: [],
      };
    }
    if (queryReady(contactQuery)) {
      isInitialisedContactForm = true;
      const contact = pick(omitBy(cloneDeep(contactQuery.data), isNil), contactWhiteList);
      contact.employee_color = contact.employee_color || '#BD10E0';
      contact.role_ids = clone(get(contactQuery, 'data.roles', [])).map((r) => r.id);
      contact.statutory_role_ids = clone(
        get(contactQuery, 'data.statutoryRoles', [])
      ).map((sr) => sr.id);
      contact.tag_collection = getTaggingsNameArray(
        clone(get(contactQuery, 'data.taggings', []))
      ).map((name) => ({ id: snakeCase(name), text: name }));
      contact.addresses_attributes = clone(get(contactQuery, 'data.addresses', [])).map(
        (a) => pick(omitBy(a, isNil), addressWhiteList)
      );
      contact.phone_numbers_attributes = clone(
        get(contactQuery, 'data.phoneNumbers', [])
      ).map((pn) => pick(omitBy(pn, isNil), phoneNumberWhiteList));
      contact.documents_attributes = clone(get(contactQuery, 'data.documents', [])).map(
        (doc) => pick(omitBy(doc, isNil), documentWhiteList)
      );
      return contact;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.contactQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentTenant: state.currentTenant,
    currentSettingsMutating: state.currentSettings.mutating,
    tagsCollection: state.tags.collection,
    currentSettingsAddressTypes: state.currentSettings.address_types,
    currentSettingsPhoneNumberTypes: state.currentSettings.phone_number_types,
    salutations: state.currentSettings.salutations,
    formValues: getFormValues('ContactForm')(state),
  };
}

export default compose(
  graphql(contactCreateMutation, {
    name: 'contactCreateMutation',
  }),
  graphql(contactUpdateMutation, {
    name: 'contactUpdateMutation',
  }),
  graphql(contactListQuery, {
    name: 'contactListQuery',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(roleListQuery, {
    name: 'roleListQuery',
  }),
  graphql(statutoryRoleListQuery, {
    name: 'statutoryRoleListQuery',
  }),
  graphql(contactEditQuery, {
    name: 'contactQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    pushArrayValue,
    removeArrayValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
    tagsCreate,
  }),
  reduxForm({
    form: 'ContactForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(ContactForm);
