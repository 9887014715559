import { Row, Col, Button, Card } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import cloneDeep from 'lodash.clonedeep';
import debounce from 'lodash.debounce';
import get from 'lodash.get';
import uniq from 'lodash.uniq';

import {
  aircraftsData,
  contactsData,
  flightTypesData,
  fuelBowsersData,
  fuelTankersData,
  locationsData,
} from '../selectors';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import FlightSignoffInfo from '../components/flight_signoff/flight_signoff_info';
import FlightSignoffForm from '../components/flight_signoff/flight_signoff_form';

import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import {
  queriesReady,
  typeInput,
  mapPickValues,
  pickValues,
  mapOmitValues,
  omitValues,
} from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import bookingAdminListQuery from '../queries/booking_admin_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import locationListQuery from '../queries/location_min_list_query';

const bookingWhiteList = ['id'];

const flightSegmentWhiteList = [
  'end_location_id',
  'end_location_landing_fee',
  'id',
  'oncharge_end_location_landing_fee',
];

class FlightSignoff extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
      submitting: false,
    };
    this.submitting = false;
    this.handleDisplayAircraftIdChange = this.handleDisplayAircraftIdChange.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this.handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
    if (this.isLoaded(this.props)) {
      this.receiveBookings(this.props);
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      this.props.bookingListQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterAircraftIds } = this.state;
    if (!this.submitting && this.isLoaded(nextProps)) {
      filterAircraftIds = uniq(
        nextProps.bookingListQuery.data.map((booking) => booking.aircraft_id)
      );
    }
    this.setState({
      filterAircraftIds,
    });
    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this.handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const newProps = props || this.props;
    return !queriesReady(
      newProps.bookingListQuery,
      newProps.aircraftListQuery,
      newProps.aircraftEngineListQuery,
      newProps.flightTypeListQuery,
      newProps.fuelBowserListQuery,
      newProps.fuelTankerListQuery,
      newProps.locationListQuery
    );
  }

  receiveBookings(props) {
    if (!this.submitting) {
      const filterAircraftIds = uniq(
        props.bookingListQuery.data.map((booking) => booking.aircraft_id)
      );
      this.setState({
        filterAircraftIds,
      });
    }
  }

  handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  onSubmit(bookingId) {
    return (formData) => {
      const data = cloneDeep(formData);
      data.updated_by_admin_id = this.props.currentContactId;
      if (!data.admin_flight_record_attributes.admin_id) {
        data.admin_flight_record_attributes.admin_id = this.props.currentContactId;
      }
      return this.props
        .bookingUpdateMutation({
          variables: {
            id: bookingId,
            input: typeInput(data),
          },
        })
        .then(() => {
          this.props.mutationSuccess('Booking signoff');
        })
        .catch((err) => this.props.mutationFailure(err, true));
    };
  }

  pickInitialValues = (b) => {
    const bookingData = cloneDeep(b);
    const booking = pickValues(bookingData, bookingWhiteList);
    booking.flight_segments_attributes = mapPickValues(
      cloneDeep(bookingData.flightSegments),
      flightSegmentWhiteList
    );
    booking.admin_flight_record_attributes = omitValues(get(b, 'adminFlightRecord'));
    booking.pilot_flight_expenses_attributes = mapOmitValues(
      get(b, 'pilotFlightExpenses')
    );
    return booking;
  };

  renderAircrafts() {
    return this.state.filterAircraftIds.map((aircraftId) => {
      if (this.state.filterAircraftId && aircraftId !== this.state.filterAircraftId) {
        return false;
      }
      const bookings = this.props.bookingListQuery.data.filter(
        (booking) => booking.aircraft_id === aircraftId
      );
      const aircraftRegistrationAbbreviated = get(this.props.aircraftsDataSelector, [
        aircraftId,
        'registrationAbbreviated',
      ]);
      return (
        <Row key={aircraftId} xs={1} className="mb-2">
          <Col>
            <h4>{aircraftRegistrationAbbreviated}</h4>
          </Col>
          <Col>
            {bookings.map((booking) => (
              <Card key={booking.id} className="mb-2">
                <Card.Body>
                  <Row className="justify-content-center">
                    <Col xs="auto">
                      <LinkContainer to={`/flights/${booking.id}/edit`}>
                        <Button variant="link" className="border-0 p-0 pb-2">
                          {`#${booking.reference}`}
                        </Button>
                      </LinkContainer>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6} className="border-end">
                      <FlightSignoffInfo
                        booking={booking}
                        currentSettingsFuelBowserFillDefaultQuantityUnit={
                          this.props.currentSettingsFuelBowserFillDefaultQuantityUnit
                        }
                        contactsDataSelector={this.props.contactsDataSelector}
                        flightTypesDataSelector={this.props.flightTypesDataSelector}
                        fuelBowsersDataSelector={this.props.fuelBowsersDataSelector}
                        fuelTankersDataSelector={this.props.fuelTankersDataSelector}
                      />
                    </Col>
                    <Col md={6}>
                      <FlightSignoffForm
                        booking={booking}
                        form={`AdminSignOff-${booking.id}`}
                        enableReinitialize
                        keepDirtyOnReinitialize
                        initialValues={this.pickInitialValues(booking)}
                        onSubmit={this.onSubmit(booking.id)}
                        currentContactId={this.props.currentContactId}
                        locationsDataSelector={this.props.locationsDataSelector}
                        contactsDataSelector={this.props.contactsDataSelector}
                        currentSettingsPilotFlightExpenseOvernightText={
                          this.props.currentSettingsPilotFlightExpenseOvernightText
                        }
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </Col>
        </Row>
      );
    });
  }

  renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterAircraftId',
          value: this.state.filterAircraftId,
          onChange: this.handleDisplayAircraftIdChange,
        }}
        asElement="select"
        selectOptions={dataAircrafts}
        optionKey="registration_abbreviated"
        defaultSelectOptionName="All"
      />
    );
  }

  render() {
    if ((this.submitting && this.state.submitting) || this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Admin Sign Off Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          {this.renderAircraftFilter()}
        </Row>
        <Row>
          <hr />
        </Row>
        {this.renderAircrafts()}
      </>
    );
  }
}

function mapStateToProps(state, props) {
  return {
    currentSettingsPilotFlightExpenseOvernightText:
      state.currentSettings.pilot_flight_expense_overnight_text,
    currentSettingsFuelBowserFillDefaultQuantityUnit:
      state.currentSettings.fuel_bowser_fill_default_quantity_unit,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentContactId: state.currentContact.id,
    aircraftsDataSelector: aircraftsData(props),
    contactsDataSelector: contactsData(props),
    flightTypesDataSelector: flightTypesData(props),
    fuelBowsersDataSelector: fuelBowsersData(props),
    fuelTankersDataSelector: fuelTankersData(props),
    locationsDataSelector: locationsData(props),
  };
}

export default compose(
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(aircraftEngineListQuery, {
    name: 'aircraftEngineListQuery',
  }),
  graphql(flightTypeListQuery, {
    name: 'flightTypeListQuery',
  }),
  graphql(fuelBowserListQuery, {
    name: 'fuelBowserListQuery',
  }),
  graphql(fuelTankerListQuery, {
    name: 'fuelTankerListQuery',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
  }),
  graphql(bookingAdminListQuery, {
    name: 'bookingListQuery',
    options: (props) => ({
      variables: {
        start_at: props.currentSettingsReportStart,
        end_at: props.currentSettingsReportEnd,
      },
    }),
  })
)(FlightSignoff);
