import { Row, Form } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';

import { reduxForm, Field, Fields, FieldArray, getFormValues } from 'redux-form';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import pick from 'lodash.pick';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import FormButtons from '../components/form/form_buttons';
import Loader from '../components/loader';
import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import CheckboxMultipleInputField from '../components/form/checkbox_multiple_input_field';
import Title from '../components/title';
import LocationFormFuelBowserFieldArray from '../components/location_form/location_form_fuel_bowser_field_array';
import LocationFormLocationImageFieldArray from '../components/location_form/location_form_location_image_field_array';
import LocationFormGeometryFields from '../components/location_form/location_form_geometry_fields';
import LocationFormMapFields from '../components/location_form/location_form_map_fields';

import { queriesReady, queryReady, typeInput, getSelectable } from '../lib/utils';

import locationEditQuery from '../queries/location_edit_query';
import locationUpdateMutation from '../mutations/location_update_mutation';
import locationCreateMutation from '../mutations/location_create_mutation';

import contactListQuery from '../queries/contact_list_query';

import locationAvailabilityListQuery from '../queries/location_availability_list_query';
import locationLightingListQuery from '../queries/location_lighting_list_query';
import locationServiceListQuery from '../queries/location_service_list_query';
import locationStatusListQuery from '../queries/location_status_list_query';
import locationSurfaceListQuery from '../queries/location_surface_list_query';
import locationTypeListQuery from '../queries/location_type_list_query';

import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelAvailabilityListQuery from '../queries/fuel_availability_list_query';
import fuelCompanyListQuery from '../queries/fuel_company_list_query';
import fuelPumpAccessListQuery from '../queries/fuel_pump_access_list_query';

moment.updateLocale('en-nz');

let isInitialisedLocationForm = false;

class LocationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderFuelBowserFieldArray = this.renderFuelBowserFieldArray.bind(this);
    this.renderLocationImageFieldArray = this.renderLocationImageFieldArray.bind(this);
  }

  componentWillUnmount() {
    isInitialisedLocationForm = false;
  }

  getLocationImageUrls() {
    return get(this.props, 'locationQuery.data.locationImages', []).reduce(
      (result, li) => {
        const newResult = { ...result, [li.id]: get(li, 'imageMediumUrl.url') };
        return newResult;
      },
      {}
    );
  }

  getSelectableEmployees() {
    return getSelectable(
      get(this.props, 'contactListQuery.data', []),
      get(this.props, 'formValues.manager_id'),
      'fullName',
      'roleEmployee'
    );
  }

  getSelectableNamedPilots() {
    return getSelectable(
      get(this.props, 'contactListQuery.data', []),
      get(this.props, 'formValues.named_pilot_ids'),
      'fullName',
      'rolePilot'
    );
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.contactListQuery,
      testProps.fuelTypeListQuery,
      testProps.fuelAvailabilityListQuery,
      testProps.fuelCompanyListQuery,
      testProps.fuelPumpAccessListQuery,
      testProps.locationAvailabilityListQuery,
      testProps.locationLightingListQuery,
      testProps.locationServiceListQuery,
      testProps.locationStatusListQuery,
      testProps.locationSurfaceListQuery,
      testProps.locationTypeListQuery,
      [testProps.locationQuery, true] // ignore if undefined
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    if (get(submitData, 'location_images_attributes')) {
      let undeletedIndex = -1;
      submitData.location_images_attributes.forEach((li) => {
        // eslint-disable-next-line no-param-reassign
        delete li.imageMediumUrl;
        // eslint-disable-next-line no-underscore-dangle
        if (!li._destroy) {
          undeletedIndex += 1;
          // eslint-disable-next-line no-param-reassign
          li.position = undeletedIndex;
        }
      });
    }
    if (!submitData.sensitive_location) {
      submitData.sensitive_nature = '';
    }
    if (!submitData.named_pilots_only) {
      submitData.named_pilot_ids = [];
    }
    // console.log('submit')
    // console.log(data)
    // console.log(submitData)
    // return
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: {
        input: typeInput(submitData),
      },
      context: {
        hasUpload: true,
      },
    };
    if (this.state.updating) {
      mutation = this.props.locationUpdateMutation;
      mutationMessage = 'Location update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.locationCreateMutation;
      mutationMessage = 'Location create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/locations');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  renderFuelBowserFieldArray(fuelBowsers) {
    const formKeys = ['fuel_bowsers_attributes'];
    return (
      <LocationFormFuelBowserFieldArray
        fuelBowsers={fuelBowsers}
        formValues={pick(this.props.formValues, formKeys)}
        fuelAvailabilities={this.props.fuelAvailabilityListQuery.data}
        fuelCompanies={this.props.fuelCompanyListQuery.data}
        fuelPumpAccesses={this.props.fuelPumpAccessListQuery.data}
        fuelTypes={this.props.fuelTypeListQuery.data}
        change={this.props.change}
      />
    );
  }

  renderLocationImageFieldArray(locationImages) {
    const formKeys = ['location_images_attributes'];
    return (
      <LocationFormLocationImageFieldArray
        locationImages={locationImages}
        formValues={pick(this.props.formValues, formKeys)}
        locationImageUrls={this.getLocationImageUrls()}
        change={this.props.change}
      />
    );
  }

  renderPalFrequency() {
    const palLocationLightingId = get(
      this.props.locationLightingListQuery.data.find((a) => a.name === 'PAL'),
      'id'
    );
    const selectedLocationLightingId = get(this.props, 'formValues.location_lighting_id');
    return (
      selectedLocationLightingId &&
      parseInt(selectedLocationLightingId, 10) === palLocationLightingId
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedLocationForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Title form updating={this.state.updating}>
            Location
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">General Details</legend>
            <Field
              type="text"
              name="long_name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Long Name
            </Field>
            <Field
              type="text"
              name="location_status_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.locationStatusListQuery.data}
            >
              Location Status
            </Field>
            <Field
              type="text"
              name="location_type_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.locationTypeListQuery.data}
            >
              Location Type
            </Field>
            <Field
              type="text"
              name="manager_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableEmployees()}
            >
              Person Responsible
            </Field>
            <Field
              type="text"
              name="short_name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Short Name
            </Field>
            <Field
              type="text"
              name="shorthand_name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Shorthand Name
            </Field>
            <Field
              type="text"
              name="gps_waypoint_name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              GPS Waypoint Name
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Mapping Details</legend>
            <Fields
              names={['display_format', 'latitude', 'longitude']}
              component={LocationFormGeometryFields}
              formValues={pick(this.props.formValues, [
                'display_format',
                'latitude',
                'longitude',
              ])}
              locationDisplayFormats={this.props.currentSettingsLocationDisplayFormats}
            />
            {get(this.props.formValues, 'latitude') &&
              get(this.props.formValues, 'longitude') && (
                <Fields
                  names={['latitude', 'longitude']}
                  component={LocationFormMapFields}
                  formValues={pick(this.props.formValues, ['latitude', 'longitude'])}
                />
              )}
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">General Details</legend>
            <Field
              type="text"
              name="location_availability_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.locationAvailabilityListQuery.data}
            >
              Location Availability
            </Field>
            <Field
              type="text"
              name="location_surface_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.locationSurfaceListQuery.data}
            >
              Location Surface
            </Field>
            <Field
              type="text"
              name="location_lighting_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.locationLightingListQuery.data}
            >
              Location Lighting
            </Field>
            {this.renderPalFrequency() && (
              <Field
                type="text"
                name="pal_frequency"
                labelWidth={3}
                inputWidth={3}
                component={InputField}
              >
                PAL Frequency
              </Field>
            )}
            <Field
              name="location_service_ids"
              component={CheckboxMultipleInputField}
              candidateList={this.props.locationServiceListQuery.data}
              selectedIds={get(this.props, 'formValues.location_service_ids', [])}
              labelWidth={3}
              inputWidth={3}
              inputItemWidth={4}
            >
              Location Services
            </Field>
            <Field
              type="text"
              name="landing_fee"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              preAddon="$"
              postAddon=".00"
            >
              Landing Fee
            </Field>
            <Field
              type="text"
              name="airways_fee"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              preAddon="$"
              postAddon=".00"
            >
              Airways Fee
            </Field>
            <Field
              type="text"
              name="elevation"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              postAddon="ft"
              helpText="feet above mean sea level"
            >
              Elevation
            </Field>
            <Field
              name="sensitive_location"
              labelWidth={3}
              inputWidth={3}
              component={CheckboxInputField}
            >
              Sensitive Location
            </Field>
            {this.props.formValues.sensitive_location && (
              <Field
                type="text"
                name="sensitive_nature"
                labelWidth={3}
                inputWidth={6}
                asElement="textarea"
                component={InputField}
              >
                Sensitive Nature
              </Field>
            )}
            <Field
              type="text"
              name="approach_depart_procedures"
              labelWidth={3}
              inputWidth={6}
              asElement="textarea"
              component={InputField}
            >
              Approach & Departure Procedures
            </Field>
            <Field
              type="text"
              name="hazards"
              labelWidth={3}
              inputWidth={6}
              asElement="textarea"
              component={InputField}
            >
              Hazards
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Other Details</legend>
            <Field
              name="named_pilots_only"
              labelWidth={3}
              inputWidth={3}
              component={CheckboxInputField}
            >
              Named Pilots Only
            </Field>
            {this.props.formValues.named_pilots_only && (
              <Field
                name="named_pilot_ids"
                component={CheckboxMultipleInputField}
                candidateList={this.getSelectableNamedPilots()}
                selectedIds={get(this.props, 'formValues.named_pilot_ids', [])}
                labelWidth={3}
                inputWidth={9}
                inputItemWidth={3}
              >
                Named Pilots
              </Field>
            )}
            <Field
              type="text"
              name="notes"
              labelWidth={3}
              inputWidth={6}
              asElement="textarea"
              component={InputField}
            >
              Notes
            </Field>
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Fuel Details</legend>
            <Field
              type="text"
              name="fuel_notes"
              labelWidth={3}
              inputWidth={6}
              asElement="textarea"
              component={InputField}
            >
              Fuel Notes
            </Field>
            <FieldArray
              name="fuel_bowsers_attributes"
              component={this.renderFuelBowserFieldArray}
            />
          </fieldset>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Manage Images</legend>
            <FieldArray
              name="location_images_attributes"
              component={this.renderLocationImageFieldArray}
            />
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/locations"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function validate() {
  return {};
}

const locationWhiteList = [
  'id',
  'airways_fee',
  'approach_depart_procedures',
  'designator',
  'display_format',
  'elevation',
  'fuel_notes',
  'gps_waypoint_name',
  'hazards',
  'location_availability_id',
  'location_lighting_id',
  'location_status_id',
  'location_surface_id',
  'location_type_id',
  'landing_fee',
  'latitude',
  'longitude',
  'long_name',
  'manager_id',
  'named_pilots_only',
  'notes',
  'pal_frequency',
  'sensitive_location',
  'sensitive_nature',
  'short_name',
  'shorthand_name',
];

const fuelBowserWhiteList = [
  'id',
  'fuel_bowser_id',
  'fuel_type_id',
  'fuel_company_id',
  'fuel_availability_id',
  'fuel_pump_access_id',
  'price',
  'active',
];

const locationImagesWhiteList = ['id', 'position'];

function pickInitialValues(locationQuery, updating, locationDefaultDisplayFormatId) {
  if (!isInitialisedLocationForm) {
    if (!updating) {
      isInitialisedLocationForm = true;
      return {
        location_service_ids: [],
        named_pilot_ids: [],
        location_images_attributes: [],
        fuel_bowsers_attributes: [],
        latitude: -36.8484,
        longitude: 174.7622,
        display_format: locationDefaultDisplayFormatId,
      };
    }
    if (queryReady(locationQuery)) {
      isInitialisedLocationForm = true;
      const location = pick(
        omitBy(cloneDeep(locationQuery.data), isNil),
        locationWhiteList
      );
      location.location_service_ids = get(locationQuery, 'data.locationServices', []).map(
        (ls) => ls.id
      );
      location.named_pilot_ids = get(locationQuery, 'data.namedPilots', []).map(
        (np) => np.id
      );
      location.fuel_bowsers_attributes = clone(
        get(locationQuery, 'data.fuelBowsers', [])
      ).map((a) => pick(omitBy(a, isNil), fuelBowserWhiteList));
      location.location_images_attributes = clone(
        get(locationQuery, 'data.locationImages', [])
      )
        .map((a) => pick(omitBy(a, isNil), locationImagesWhiteList))
        .sort((a, b) => a.position - b.position);
      return location;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.locationQuery,
    !!props.params.id,
    state.currentSettings.location_default_display_format_id
  );
  return {
    initialValues,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsLocationDisplayFormats: state.currentSettings.location_display_formats,
    formValues: getFormValues('LocationForm')(state),
  };
}

export default compose(
  graphql(locationCreateMutation, {
    name: 'locationCreateMutation',
  }),
  graphql(locationUpdateMutation, {
    name: 'locationUpdateMutation',
  }),
  graphql(contactListQuery, {
    name: 'contactListQuery',
  }),
  graphql(fuelTypeListQuery, {
    name: 'fuelTypeListQuery',
  }),
  graphql(fuelAvailabilityListQuery, {
    name: 'fuelAvailabilityListQuery',
  }),
  graphql(fuelCompanyListQuery, {
    name: 'fuelCompanyListQuery',
  }),
  graphql(fuelPumpAccessListQuery, {
    name: 'fuelPumpAccessListQuery',
  }),
  graphql(locationAvailabilityListQuery, {
    name: 'locationAvailabilityListQuery',
  }),
  graphql(locationLightingListQuery, {
    name: 'locationLightingListQuery',
  }),
  graphql(locationServiceListQuery, {
    name: 'locationServiceListQuery',
  }),
  graphql(locationStatusListQuery, {
    name: 'locationStatusListQuery',
  }),
  graphql(locationSurfaceListQuery, {
    name: 'locationSurfaceListQuery',
  }),
  graphql(locationTypeListQuery, {
    name: 'locationTypeListQuery',
  }),
  graphql(locationEditQuery, {
    name: 'locationQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet }),
  reduxForm({
    form: 'LocationForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(LocationForm);
