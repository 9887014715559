import {
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import clone from 'lodash.clone';
import last from 'lodash.last';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import contactDeleteMutation from '../mutations/contact_delete_mutation';

import {
  queriesReady,
  getExport,
  getTaggingsNameArray,
  getPhoneNumberToString,
  getAddressToString,
} from '../lib/utils';

import contactShowQuery from '../queries/contact_show_query';

moment.updateLocale('en-nz');

class ContactShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const displayName = e.target.getAttribute('data-display-name');
    getExport(reportName, {}, null, exportType, displayName)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.contactQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const contactId = last(e.currentTarget.id.split('-'));
    this.props
      .contactDeleteMutation({
        variables: {
          id: contactId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Contact delete');
        this.props.navigate('/contacts');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderDocumentsTable() {
    const { documents, id } = this.props.contactQuery.data;
    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>Document</th>
            <th>Uploader</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {documents?.map((document) => {
            const {
              id: documentId,
              document_file_name: documentFileName,
              uploaded_at: uploadedAt,
              uploader,
              description,
            } = document;
            const documentFileExtension = documentFileName
              ? documentFileName.split('.').pop()
              : 'unknown';
            return (
              <tr key={`${id}-${documentId}`}>
                <td>{description}</td>
                <td>{uploader ? uploader.display_name : '-'}</td>
                <td>{uploadedAt ? moment(uploadedAt).format('lll') : '-'}</td>
                <td>
                  <Button
                    variant="link"
                    size="xsmall"
                    data-report-name={`documents/${documentId}`}
                    data-display-name={documentFileName}
                    data-export-type={documentFileExtension}
                    onClick={this.getExport}
                  >
                    {documentFileExtension === 'pdf' ? 'view' : 'download'}
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderActivationsTable() {
    const { activations, id } = this.props.contactQuery.data;
    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>ID</th>
            <th>Active From</th>
            <th>Active Thru</th>
          </tr>
        </thead>
        <tbody>
          {(clone(activations) || [])
            .sort((a, b) => b.active_from.localeCompare(a.active_from))
            .map((activation) => {
              const {
                id: activationId,
                active_from: activeFrom,
                active_thru: activeThru,
              } = activation;
              return (
                <tr key={`${id}-${activationId}`}>
                  <td>{activationId}</td>
                  <td>{activeFrom}</td>
                  <td>{activeThru}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  renderData() {
    if (this.isLoaded()) {
      const contact = this.props.contactQuery.data;
      const {
        addresses,
        contact_number: contactNumber,
        company,
        company_name: companyName,
        company_legal_name: companyLegalName,
        company_contact_name: companyContactName,
        dob,
        defaultPhoneNumber,
        defaultFlightType,
        defaultProvider,
        display_name: displayName,
        email,
        id,
        employer,
        first_name: firstName,
        flight_notes: flightNotes,
        fullName,
        last_name: lastName,
        other_notes: otherNotes,
        phoneNumbers,
        photoMediumUrl: { url: photoMediumUrlUrl },
        roles,
        roleEmployee,
        roleChargeable,
        salutation,
        statutoryRoles,
        taggings,
        weight,
      } = contact;

      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show fullName={fullName}>
                Contact
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        confirmId={`contact-delete-${id}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Contact"
                        body="Are you sure you want to delete this Contact?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/contacts/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/contacts">
                        <Button variant="primary">All Contacts</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Company/Individual</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Contact Class"
                        dd={company ? 'Company' : 'Individual'}
                      />
                      <DlHorizontal
                        dt="Tags"
                        dd={
                          taggings &&
                          getTaggingsNameArray(taggings).map((name) => (
                            <span key={name}>
                              {name}
                              <br />
                            </span>
                          ))
                        }
                      />

                      {roleEmployee && (
                        <DlHorizontal dt="Employer" dd={employer?.fullName} />
                      )}
                      {roleChargeable && (
                        <DlHorizontal
                          dt="Default Provider"
                          dd={defaultProvider?.fullName}
                        />
                      )}
                      {roleChargeable && (
                        <DlHorizontal
                          dt="Default Flight Type"
                          dd={defaultFlightType?.name}
                        />
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Role Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Roles"
                        dd={roles?.map((role) => (
                          <span key={role.id}>
                            {role.name}
                            <br />
                          </span>
                        ))}
                      />
                      <DlHorizontal
                        dt="Statutory Roles"
                        dd={statutoryRoles?.map((sr) => (
                          <span key={sr.id}>
                            {sr.name}
                            <br />
                          </span>
                        ))}
                      />
                      <DlHorizontal
                        dt="Permissions"
                        dd={[
                          'Passworded',
                          'Employee',
                          'OfficeAdmin',
                          'Manager',
                          'Pilot',
                          'Passenger',
                          'Provider',
                          'Chargeable',
                        ].map((name) => (
                          <span key={name}>
                            {`${name}: ${this.displayBoolean(contact[`role${name}`])}`}
                            <br />
                          </span>
                        ))}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  {company ? (
                    <Card>
                      <Card.Header>Company Details</Card.Header>
                      <Card.Body>
                        <DlHorizontal dt="Company Name" dd={companyName} />
                        <DlHorizontal dt="Company Legal Name" dd={companyLegalName} />
                        <DlHorizontal dt="Company Contact Name" dd={companyContactName} />
                      </Card.Body>
                    </Card>
                  ) : (
                    <Card>
                      <Card.Header>Individual Details</Card.Header>
                      <Card.Body>
                        <DlHorizontal dt="Salutation" dd={salutation} />
                        <DlHorizontal dt="First Name" dd={firstName} />
                        <DlHorizontal dt="Last Name" dd={lastName} />
                        <DlHorizontal dt="Display Name" dd={displayName} />
                        <DlHorizontal dt="Weight" dd={weight} />
                        <DlHorizontal dt="DOB" dd={dob} />
                      </Card.Body>
                    </Card>
                  )}
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Basic Contact Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Contact Number (DEPRECATED)" dd={contactNumber} />
                      <DlHorizontal
                        dt="Default Phone Number"
                        dd={
                          defaultPhoneNumber && getPhoneNumberToString(defaultPhoneNumber)
                        }
                      />
                      <DlHorizontal dt="Email" dd={email} />
                      <DlHorizontal dt="Flight Notes" dd={flightNotes} />
                      <DlHorizontal dt="Other Notes" dd={otherNotes} />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Phone Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Phone Details"
                        dd={phoneNumbers?.map((phoneNumber) => (
                          <span key={phoneNumber.id}>
                            {getPhoneNumberToString(phoneNumber)}
                            <br />
                          </span>
                        ))}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Address Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal
                        dt="Address Details"
                        dd={addresses?.map((address) => (
                          <span key={address.id}>
                            {getAddressToString(address)}
                            <br />
                          </span>
                        ))}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Photo</Card.Header>
                    <Card.Img
                      src={
                        photoMediumUrlUrl ||
                        'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
                      }
                    />
                  </Card>
                </Col>
              </Row>
              <Row className="mb-4">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Documents</Card.Header>
                    <Card.Body>{this.renderDocumentsTable()}</Card.Body>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Activations</Card.Header>
                    <Card.Body>{this.renderActivationsTable()}</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(contactDeleteMutation, {
    name: 'contactDeleteMutation',
  }),
  graphql(contactShowQuery, {
    name: 'contactQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(ContactShow);
