import { useState, useEffect } from 'react';
import {
  Navbar,
  Nav,
  NavDropdown,
  OverlayTrigger,
  Tooltip,
  Button,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';

import first from 'lodash.first';
import upperFirst from 'lodash.upperfirst';

import Api from '../lib/api';
import Glyphicon from '../components/glyphicon';
import { storeReload } from '../actions/store_actions';
import { currentSettingsSet } from '../actions/current_setting_actions';

export default function Header() {
  const dispatch = useDispatch();
  const currentContact = useSelector((state) => state.currentContact);
  const versionCurrent = useSelector((state) => state.currentSettings.versionCurrent);
  const currentBootswatchStyle = useSelector(
    (state) => state.currentSettings.bootswatchStyle
  );
  const [isEmployee, setIsEmployee] = useState(false);
  const [isOfficeAdmin, setIsOfficeAdmin] = useState(false);
  const [isDeveloper, setIsDeveloper] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let newIsDeveloper = false;
    let newIsEmployee = false;
    let newIsOfficeAdmin = false;
    if (currentContact && currentContact.id) {
      newIsEmployee = currentContact['employee?'];
      newIsOfficeAdmin = currentContact['office_admin?'];
      newIsDeveloper = currentContact.email === 'gordon.king02@gmail.com';
    }
    setIsDeveloper(newIsDeveloper);
    setIsEmployee(newIsEmployee);
    setIsOfficeAdmin(newIsOfficeAdmin);
  }, [currentContact]);

  useEffect(() => {
    async function fetchData() {
      let newOptions = [];
      if (searchStr) {
        setIsLoading(true);
        try {
          const resp = await Api.get(`/api/search/all/${searchStr}`);
          newOptions = resp.data.sort((a, b) => a.name.localeCompare(b.name));
        } catch (err) {
          console.log(err.toString());
        }
      }
      setOptions(newOptions);
      setIsLoading(false);
    }
    fetchData();
  }, [searchStr]);

  useEffect(() => {
    const link = document.querySelector('link[title=bootswatch]');
    if (link) {
      const href = link.getAttribute('href');
      if (href) {
        const newHref = href.replace(
          /dist\/.+\/bootstrap.min.css/,
          `dist/${currentBootswatchStyle}/bootstrap.min.css`
        );
        link.setAttribute('href', newHref);
      }
    }
  }, [currentBootswatchStyle]);

  const reloadPage = () => {
    window.location.reload(true);
  };

  const handleStoreReload = () => dispatch(storeReload());

  const handleOptionChange = (selectedOptions) => {
    if (selectedOptions && selectedOptions.length > 0) {
      const { id, klass } = first(selectedOptions);
      const path = `/${klass}s/${id}`;
      window.open(path, upperFirst(klass) + id, 'width=1200, height=800');
    }
  };

  const handleStyleChange = (bootswatchStyle) =>
    dispatch(
      currentSettingsSet({
        bootswatchStyle,
      })
    );

  const renderAdminLink = () => {
    const tenant = window.location.hostname.split('.')[0];
    if (process.env.NODE_ENV === 'development') {
      return `http://${tenant}.lvh.me:1527/admin`;
    }
    return `https://${tenant}.rails.saam.co.nz/admin`;
  };

  const renderOption = (option) => {
    const { name, klass } = option;
    let icon = 'glass';
    switch (klass) {
      case 'contact':
        icon = 'user';
        break;
      case 'location':
        icon = 'mountains';
        break;
      default:
        break;
    }
    return (
      <div>
        <Glyphicon glyph={icon} />
        <span>{name}</span>
      </div>
    );
  };

  return (
    <Navbar defaultExpanded expand="xl" variant="light" bg="light" className="px-4">
      <Navbar.Brand>
        <Link to="/">SAAM FLIGHT</Link>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <div className="w-100 d-flex justify-content-between flex-nowrap">
        <Navbar.Collapse id="left-navbar-nav" className="justify-content-start">
          <Nav>
            <Nav.Item className="me-2">
              <LinkContainer to={{ pathname: '/bookings' }}>
                <Nav.Link eventKey={2}>Bookings</Nav.Link>
              </LinkContainer>
            </Nav.Item>
            {isEmployee && (
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/locations' }}>
                  <Nav.Link eventKey={3}>Locations</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {isEmployee && (
              <Nav.Item className="me-2">
                <LinkContainer to={{ pathname: '/contacts' }}>
                  <Nav.Link eventKey={4}>Contacts</Nav.Link>
                </LinkContainer>
              </Nav.Item>
            )}
            {isEmployee && (
              <NavDropdown title="Other" id="basic-nav-dropdown-other" className="me-2">
                {isOfficeAdmin && (
                  <LinkContainer to={{ pathname: '/authorizations' }}>
                    <NavDropdown.Item eventKey={5.1}>Authorizations</NavDropdown.Item>
                  </LinkContainer>
                )}
                <LinkContainer to={{ pathname: '/documents' }}>
                  <NavDropdown.Item eventKey={5.2}>Documents</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/checks' }}>
                  <NavDropdown.Item eventKey={5.3}>Checks</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/fuel_tankers' }}>
                  <NavDropdown.Item eventKey={5.4}>Fuel Tankers</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/locations/merge' }}>
                  <NavDropdown.Item eventKey={5.5}>Merge Locations</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to={{ pathname: '/reports/pilot_duty_record' }}>
                  <NavDropdown.Item eventKey={5.6}>Pilot Duty Records</NavDropdown.Item>
                </LinkContainer>
                {isOfficeAdmin && (
                  <LinkContainer to={{ pathname: '/rosters' }}>
                    <NavDropdown.Item eventKey={5.7}>Rosters</NavDropdown.Item>
                  </LinkContainer>
                )}
                {isDeveloper && (
                  <>
                    <NavDropdown.Divider />
                    <LinkContainer to={{ pathname: '/aircraft_configurations' }}>
                      <NavDropdown.Item eventKey={9.0}>
                        Aircraft Configurations
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/wb_limits' }}>
                      <NavDropdown.Item eventKey={9.1}>WB Limits</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/seat_configurations' }}>
                      <NavDropdown.Item eventKey={9.2}>
                        Seat Configurations
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/hold_configurations' }}>
                      <NavDropdown.Item eventKey={9.3}>
                        Hold Configurations
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/tank_configurations' }}>
                      <NavDropdown.Item eventKey={9.4}>
                        Fuel Tank Configurations
                      </NavDropdown.Item>
                    </LinkContainer>
                  </>
                )}
              </NavDropdown>
            )}
            {isEmployee && (
              <NavDropdown
                title="Reports"
                id="basic-nav-dropdown-reports"
                className="me-2"
              >
                {isOfficeAdmin ? (
                  <>
                    <LinkContainer to={{ pathname: '/reports/integrity' }}>
                      <NavDropdown.Item eventKey={6.1}>Data Integrity</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/flights/signoff' }}>
                      <NavDropdown.Item eventKey={6.2}>Admin Sign Off</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/aircraft_flight_record' }}>
                      <NavDropdown.Item eventKey={6.3}>
                        Aircraft Flight Record
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/aircraft_flight_time_event' }}
                    >
                      <NavDropdown.Item eventKey={6.4}>
                        Aircraft Daily Hours & Cycles
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/aircraft_flight_type_category' }}
                    >
                      <NavDropdown.Item eventKey={6.5}>
                        Aircraft Operating Statistics
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/allocation' }}>
                      <NavDropdown.Item eventKey={6.6}>Allocation</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/provider_flight_hour' }}>
                      <NavDropdown.Item eventKey={6.7}>
                        Flight Hour Matrix
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/chargeable_flight_record' }}>
                      <NavDropdown.Item eventKey={6.8}>
                        Customer Flight Record
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/chargeable_invoice' }}>
                      <NavDropdown.Item eventKey={6.9}>
                        Customer Invoice Report
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/pilot_flight_expense' }}>
                      <NavDropdown.Item eventKey={6.101}>
                        Pilot Flight Expense
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/pilot_flight_log' }}>
                      <NavDropdown.Item eventKey={6.102}>
                        Pilot Flight Log
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/pilot_cumulative_hour' }}>
                      <NavDropdown.Item eventKey={6.11}>
                        Pilot Cumulative Hours
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/provider_flight_record' }}>
                      <NavDropdown.Item eventKey={6.12}>
                        Provider Flight Record
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/provider_swap_summary' }}>
                      <NavDropdown.Item eventKey={6.13}>
                        Provider Swap Summary
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/provider_swap_detail' }}>
                      <NavDropdown.Item eventKey={6.14}>
                        Provider Swap Detail
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/location_landing_fee' }}>
                      <NavDropdown.Item eventKey={6.15}>
                        Location Landing Fee
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/fuel_bowser_fill_for_aircraft' }}
                    >
                      <NavDropdown.Item eventKey={6.16}>
                        Aircraft Refuel by Bowser
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/fuel_tanker_fill_for_aircraft' }}
                    >
                      <NavDropdown.Item eventKey={6.17}>
                        Aircraft Refuel by Tanker
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/fuel_bowser_fill_for_fuel_tanker' }}
                    >
                      <NavDropdown.Item eventKey={6.18}>
                        Tanker Refuel by Bowser
                      </NavDropdown.Item>
                    </LinkContainer>
                  </>
                ) : (
                  <>
                    <LinkContainer to={{ pathname: '/reports/aircraft_flight_record' }}>
                      <NavDropdown.Item eventKey={6.3}>
                        Aircraft Flight Record
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      to={{ pathname: '/reports/aircraft_flight_time_event' }}
                    >
                      <NavDropdown.Item eventKey={6.4}>
                        Aircraft Daily Hours & Cycles
                      </NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer to={{ pathname: '/reports/pilot_flight_log' }}>
                      <NavDropdown.Item eventKey={6.102}>
                        Pilot Flight Log
                      </NavDropdown.Item>
                    </LinkContainer>
                  </>
                )}
              </NavDropdown>
            )}
            {versionCurrent || (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="tooltip-header-version-link">
                    Your application version is out of date. Click to refresh your browser
                  </Tooltip>
                }
              >
                <Button variant="danger" onClick={reloadPage}>
                  <Glyphicon glyph="exclamation-sign" />
                </Button>
              </OverlayTrigger>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse id="right-navbar-nav" className="justify-content-end">
          <Nav>
            {isEmployee && (
              <AsyncTypeahead
                clearButton
                delay={400}
                filterBy={() => true}
                id="async-header-search"
                isLoading={isLoading}
                labelKey="name"
                // minLength={3}
                onSearch={setSearchStr}
                onChange={handleOptionChange}
                options={options}
                placeholder="search..."
                promptText="search..."
                searchText="searching..."
                renderMenuItemChildren={renderOption}
              />
            )}
            {isEmployee && (
              <Nav.Item id="header-safety-link" className="mx-2">
                <Nav.Link
                  target="_blank"
                  eventKey={9}
                  href="https://aviation-sms.knack.com/risk-registers#advanced-flight-registers/"
                >
                  Safety Report
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Nav activeKey={currentBootswatchStyle} onSelect={handleStyleChange}>
            <NavDropdown title="Themes" id="basic-nav-dropdown-themes">
              <NavDropdown.Item eventKey="simplex">Simplex</NavDropdown.Item>
              <NavDropdown.Item eventKey="sandstone">Sandstone</NavDropdown.Item>
              <NavDropdown.Item eventKey="darkly">Darkly</NavDropdown.Item>
              <NavDropdown.Item eventKey="zephyr">Zephyr</NavDropdown.Item>
              <NavDropdown.Item eventKey="cosmo">Cosmo</NavDropdown.Item>
              <NavDropdown.Item eventKey="flatly">Flatly</NavDropdown.Item>
              <NavDropdown.Item eventKey="sketchy">Sketchy</NavDropdown.Item>
              <NavDropdown.Item eventKey="spacelab">Spacelab</NavDropdown.Item>
              <NavDropdown.Item eventKey="journal">Journal</NavDropdown.Item>
              <NavDropdown.Item eventKey="morph">Morph</NavDropdown.Item>
              <NavDropdown.Item eventKey="yeti">Yeti</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <NavDropdown align="end" title="Tools" id="basic-nav-dropdown-other">
              {isEmployee && (
                <NavDropdown.Item eventKey={8.1} target="_blank" href={renderAdminLink()}>
                  Admin
                </NavDropdown.Item>
              )}
              <NavDropdown.Item eventKey={8.2} href="#" onClick={handleStoreReload}>
                Reload
              </NavDropdown.Item>
              <LinkContainer to={{ pathname: '/auth/logout' }}>
                <NavDropdown.Item eventKey={8.3}>Sign Out</NavDropdown.Item>
              </LinkContainer>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </div>
    </Navbar>
  );
}
