import { useSelector, useDispatch } from 'react-redux';
import useDimensions from 'react-cool-dimensions';

import { ToastContainer } from 'react-toastify';
import moment from 'moment';
import 'moment/locale/en-nz';

import L from 'leaflet';
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';

import ErrorBoundaryApp from './components/error_boundary_app';
import CheckVersion from './components/check_version';
import Header from './containers/header';

import AppRoutes from './app_routes';
import { currentSettingsSet } from './actions/current_setting_actions';

// bootstrap
// require('./css/bootswatch4.css');
// require('./css/bootswatch5.2.3-sandstone.css');
// require('./css/bootswatch5.2.3-simplex.css');
// require('./css/bootswatch_local.css')
// maps
require('leaflet/dist/leaflet.css');
// datetime picker
require('react-datetime/css/react-datetime.css');
// toggle
require('react-bootstrap-toggle/dist/bootstrap2-toggle.css');
// icons
require('./css/glyphicons.css');
require('./css/glyphicons-filetypes.css');
require('./css/glyphicons-bootstrap.css');
require('./css/glyphicons-local.css');
// typeaheads
require('react-bootstrap-typeahead/css/Typeahead.css');
require('react-bootstrap-typeahead/css/Typeahead.bs5.css');
require('./css/react_bootstrap_typeahead_local.css');
// tags
// require('./css/react_tags_autocomplete.css');
require('react-tag-autocomplete/example/src/styles.css');
// toasts
require('react-toastify/dist/ReactToastify.css');
// full calendar
// require('./css/full_calendar_local.css');
// sundry
require('./css/local.css');
require('./css/todo_local.scss');

// TODO leaflet doesn't show markers when imported with webpack otherwise.
// https://github.com/PaulLeCam/react-leaflet/issues/255
delete L.Icon.Default.prototype._getIconUrl; // eslint-disable-line no-underscore-dangle
L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

moment.updateLocale('en-nz');
const App = () => {
  const dispatch = useDispatch();
  const { observe } = useDimensions({
    onResize: ({ width, height }) => {
      dispatch(
        currentSettingsSet({
          containerWidth: width,
          containerHeight: height,
        })
      );
    },
  });
  const authIsAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const containerHeight = useSelector((state) => state.currentSettings.containerHeight);
  return (
    <div style={{ height: 'calc(100vh)', width: '100%' }} ref={observe}>
      <ErrorBoundaryApp containerHeight={containerHeight}>
        <ToastContainer />
        {authIsAuthenticated && <CheckVersion />}
        {authIsAuthenticated && <Header />}
        <AppRoutes />
      </ErrorBoundaryApp>
    </div>
  );
};

export default App;
