import { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import last from 'lodash.last';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';
import { toastSuccess, toastError } from '../lib/action_helpers';
import { getTaggingsNameString, getPhoneNumberToString } from '../lib/utils';

import contactDeleteMutation from '../mutations/contact_delete_mutation';
import contactActivateMutation from '../mutations/contact_activate_mutation';
import contactDeactivateMutation from '../mutations/contact_deactivate_mutation';
import pageContactListQuery from '../queries/page_contact_list_query';

const ContactList = () => {
  const dispatch = useDispatch();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentContact = useSelector((state) => state.currentContact);
  const [contactDelete] = useMutation(contactDeleteMutation);
  const [contactActivate] = useMutation(contactActivateMutation);
  const [contactDeactivate] = useMutation(contactDeactivateMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageContactListQuery, { notifyOnNetworkStatusChange: true });

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Type',
      id: 'company',
      accessorFn: (row) => (row.company ? 'company' : 'individual'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'State',
      id: 'activeActivationState',
      accessorFn: (row) => (row.activeActivation ? 'active' : 'inactive'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
    {
      header: 'Full Name',
      accessorKey: 'fullName',
      filterFn: 'includesString',
      headerClassName: 'w-100',
    },
    {
      header: 'Default Number',
      id: 'defaultPhoneNumber',
      cell: ({ row }) => getPhoneNumberToString(row.original.defaultPhoneNumber),
    },
    {
      header: 'Roles',
      id: 'roles',
      accessorFn: (row) => (row.roles || []).map((role) => role.name),
      cell: ({ getValue }) => getValue().join(', '),
      filterFn: 'arrIncludes',
      filterType: 'dropdown',
    },
    {
      header: 'Stat Roles',
      id: 'statRoles',
      accessorFn: (row) => (row.statutoryRoles || []).map((role) => role.name),
      cell: ({ getValue }) => getValue().join(', '),
      filterFn: 'arrIncludes',
      filterType: 'dropdown',
    },
    {
      header: 'Tags',
      id: 'tags',
      cell: ({ row }) => getTaggingsNameString(row.original.taggings),
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleDelete = useCallback(
    async (e) => {
      const contactId = last(e.currentTarget.id.split('-'));
      const mutationData = {
        variables: { id: contactId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await contactDelete(mutationData);
        toastSuccess('Contact delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Contact delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [contactDelete, dispatch]
  );

  const handleActivate = useCallback(
    async (e) => {
      const contactId = last(e.currentTarget.id.split('-'));
      const mutationData = {
        variables: { id: contactId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await contactActivate(mutationData);
        toastSuccess('Contact activated');
      } catch (err) {
        console.log(err.toString());
        toastError('Contact activation failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [contactActivate, dispatch]
  );

  const handleDeactivate = useCallback(
    async (e) => {
      const contactId = last(e.currentTarget.id.split('-'));
      const mutationData = {
        variables: { id: contactId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await contactDeactivate(mutationData);
        toastSuccess('Contact deactivated');
      } catch (err) {
        console.log(err.toString());
        toastError('Contact deactivation failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [contactDeactivate, dispatch]
  );

  const getTableTools = () => {
    if (currentContact['manager?'] || currentContact['office_admin?']) {
      return {
        handleDelete,
        handleActivate,
        handleDeactivate,
      };
    }
    return {};
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Contacts</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/contacts/new">
                <Button size="sm" variant="primary">
                  Add a Contact
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pageData?.contactList.length ? (
        <ReactTable
          rootName="contact"
          parentColumns={parentColumns}
          data={pageData.contactList}
          initialStateSorting={[{ id: 'fullName', desc: false }]}
          {...getTableTools()}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No contacts</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default ContactList;
