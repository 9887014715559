import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, typeInput } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import fuelBowserFillDetailForMonthForAircraftQuery from '../queries/fuel_bowser_fill_detail_for_month_for_aircraft_query';
import locationListQuery from '../queries/location_min_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportFuelBowserFill extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterLocationId: this.props.currentSettingsReportLocationId,
      filterLocationIds: [],
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
      fuel_bowser_fill_ids: {},
      submitting: false,
    };
    this.submitting = false;
    this._handleDisplayLocationIdChange = this._handleDisplayLocationIdChange.bind(this);
    this._handleDisplayAircraftIdChange = this._handleDisplayAircraftIdChange.bind(this);
    this._handleFuelBowserFillInvoicedChange =
      this._handleFuelBowserFillInvoicedChange.bind(this);
    this._handleSaveFuelBowserFillInvoicedClick =
      this._handleSaveFuelBowserFillInvoicedClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('month').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.aircraftId) {
      this._handleDisplayAircraftIdChange({
        target: { value: this.props.params.aircraftId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      if (this.submitting) {
        this.submitting = false;
        this.setState({
          submitting: false,
        });
      }
      this.props.fuelBowserFillDetailForMonthForAircraftQuery.refetch();
      this.setState({
        fuel_bowser_fill_ids: {},
      });
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterLocationIds } = this.state;
    let { filterAircraftIds } = this.state;
    if (!this.submitting && this.isLoaded(nextProps)) {
      filterLocationIds = nextProps.fuelBowserFillDetailForMonthForAircraftQuery.data.map(
        (data) => data.location_id
      );
      filterAircraftIds = nextProps.fuelBowserFillDetailForMonthForAircraftQuery.data.map(
        (data) => data.aircraft_id
      );
    }
    filterLocationIds = [...new Set(filterLocationIds)];
    filterAircraftIds = [...new Set(filterAircraftIds)];
    this.setState({
      filterLocationIds,
      filterAircraftIds,
    });
    let filterLocationId = nextProps.currentSettingsReportLocationId;
    if (filterLocationId && this.isLoaded(nextProps)) {
      if (
        filterLocationIds.length > 0 &&
        filterLocationIds.indexOf(filterLocationId) === -1
      ) {
        filterLocationId = '';
      }
    }
    if (filterLocationId !== this.props.currentSettingsReportLocationId) {
      this._handleDisplayLocationIdChange({ target: { value: filterLocationId } });
    }

    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this._handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.fuelBowserFillDetailForMonthForAircraftQuery,
      props.locationListQuery,
      props.aircraftListQuery
    );
  }

  _handleDisplayLocationIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterLocationId: value,
    });
    this.props.currentSettingsSet({
      reportLocationId: value,
    });
  }

  _handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  _handleSaveFuelBowserFillInvoicedClick() {
    const { fuel_bowser_fill_ids } = this.state;
    Object.keys(fuel_bowser_fill_ids).forEach((fuel_bowser_fill_id) => {
      if (!this.submitting) {
        this.submitting = true;
        this.setState({ submitting: true });
      }
      const { booking_id, quantity_value, invoiced } =
        fuel_bowser_fill_ids[fuel_bowser_fill_id];
      this.props
        .bookingUpdateMutation({
          variables: {
            id: booking_id,
            input: typeInput({
              id: booking_id,
              fuel_bowser_fills_attributes: {
                id: fuel_bowser_fill_id,
                quantity_value,
                invoiced,
              },
            }),
          },
        })
        .then((res) => {
          this.props.mutationSuccess('Booking fuel invoice');
          this.delayedHandleRefetch();
        })
        .catch((err) => {
          if (this.submitting) {
            this.submitting = false;
            this.setState({ submitting: false });
          }
          this.props.mutationFailure(err);
        });
    });
  }

  _handleFuelBowserFillInvoicedChange(e) {
    const { fuel_bowser_fill_ids } = this.state;
    const [, booking_id, fuel_bowser_fill_id, quantity_value] = e.target.id.split('-');
    fuel_bowser_fill_ids[fuel_bowser_fill_id] = {
      booking_id,
      quantity_value: parseFloat(quantity_value),
      invoiced: e.target.checked,
    };
    this.setState({
      fuel_bowser_fill_ids,
    });
  }

  _renderFixed(value) {
    return value ? value.toFixed(2) : '-';
  }

  _renderRow(fuel_bowser_fill) {
    const {
      id,
      fuel_bowser_fill_id,
      booking_id,
      booking_reference,
      booking_start_at_s,
      aircraft_registration_abbreviated,
      fuel_bowser_name,
      location_short_name,
      price,
      quantity_value,
      quantity_s,
      invoiced,
    } = fuel_bowser_fill;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${booking_reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{booking_start_at_s}</td>
        <td>{location_short_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>{fuel_bowser_name}</td>
        <td className="text-end">{quantity_s}</td>
        <td className="text-end">
          <input
            id={`toggleinvoiced-${booking_id}-${fuel_bowser_fill_id}-${quantity_value}`}
            type="checkbox"
            checked={
              this.state.fuel_bowser_fill_ids[fuel_bowser_fill_id] !== undefined
                ? this.state.fuel_bowser_fill_ids[fuel_bowser_fill_id].invoiced
                : invoiced
            }
            onChange={this._handleFuelBowserFillInvoicedChange}
          />
        </td>
      </tr>
    );
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Location</th>
          <th className="border-top-0">Registration</th>
          <th className="border-top-0">Bowser</th>
          <th className="text-end border-top-0">Qty</th>
          <th className="text-end border-top-0">Received</th>
        </tr>
      </thead>
    );
  }

  _renderFuelBowserFills() {
    const fuel_bowser_fills =
      this.props.fuelBowserFillDetailForMonthForAircraftQuery.data.filter(
        (fuel_bowser_fill) => {
          if (
            this.state.filterAircraftId &&
            fuel_bowser_fill.aircraft_id !== this.state.filterAircraftId
          ) {
            return false;
          }
          if (
            this.state.filterLocationId &&
            fuel_bowser_fill.location_id !== this.state.filterLocationId
          ) {
            return false;
          }
          return true;
        }
      );
    return (
      <Row xs={1}>
        <Col className="d-flex justify-content-end align-items-start">
          <Button
            variant="primary"
            onClick={this._handleSaveFuelBowserFillInvoicedClick}
            className="py-0"
          >
            Save Received
          </Button>
        </Col>
        <Col>
          <Table striped size="sm">
            {this._renderHeaderRow()}
            <tbody>
              {fuel_bowser_fills.map((fuel_bowser_fill) =>
                this._renderRow(fuel_bowser_fill)
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }

  _renderLocationFilter() {
    const dataLocations = this.props.locationListQuery.data.filter(
      (model) => this.state.filterLocationIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterLocationId',
          value: this.state.filterLocationId,
          onChange: this._handleDisplayLocationIdChange,
        }}
        asElement="select"
        selectOptions={dataLocations}
        optionKey="fullName"
        defaultSelectOptionName="All"
      />
    );
  }

  _renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterAircraftId',
          value: this.state.filterAircraftId,
          onChange: this._handleDisplayAircraftIdChange,
        }}
        asElement="select"
        selectOptions={dataAircrafts}
        optionKey="registration_abbreviated"
        defaultSelectOptionName="All"
      />
    );
  }

  render() {
    if ((this.submitting && this.state.submitting) || this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Aircraft Refuelling by Bowser Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          {this._renderLocationFilter()}
          {this._renderAircraftFilter()}
        </Row>
        {this._renderFuelBowserFills()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportLocationId: state.currentSettings.reportLocationId,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(fuelBowserFillDetailForMonthForAircraftQuery, {
    name: 'fuelBowserFillDetailForMonthForAircraftQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportFuelBowserFill);
