import { Row, Col, Table, Form, Button } from 'react-bootstrap';
import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';
import accounting from 'accounting';
import { connect } from 'react-redux';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { mutationSuccess, mutationFailure } from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, typeInput } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import flightSegmentLandingFeeDetailForMonthQuery from '../queries/flight_segment_landing_fee_detail_for_month_query';
import locationListQuery from '../queries/location_min_list_query';
import aircraftListQuery from '../queries/aircraft_list_query';

moment.updateLocale('en-nz');

class ReportLocationLandingFee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterLocationId: this.props.currentSettingsReportLocationId,
      filterLocationIds: [],
      filterAircraftId: this.props.currentSettingsReportAircraftId,
      filterAircraftIds: [],
      flightSegmentIds: {},
      submitting: false,
    };
    this.submitting = false;
    this.handleDisplayLocationIdChange = this.handleDisplayLocationIdChange.bind(this);
    this.handleDisplayAircraftIdChange = this.handleDisplayAircraftIdChange.bind(this);
    this.handleFlightSegmentInvoicedChange =
      this.handleFlightSegmentInvoicedChange.bind(this);
    this.handleSaveFlightSegmentInvoicedClick =
      this.handleSaveFlightSegmentInvoicedClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.locationId) {
      this.handleDisplayLocationIdChange({
        target: { value: this.props.params.locationId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce(() => {
      if (this.submitting) {
        this.submitting = false;
        this.setState({
          submitting: false,
        });
      }
      this.props.flightSegmentLandingFeeDetailForMonthQuery.refetch();
      this.setState({
        flightSegmentIds: {},
      });
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterLocationIds } = this.state;
    let { filterAircraftIds } = this.state;
    if (!this.submitting && this.isLoaded(nextProps)) {
      filterLocationIds = nextProps.flightSegmentLandingFeeDetailForMonthQuery.data.map(
        (data) => data.end_location_id
      );
      filterAircraftIds = nextProps.flightSegmentLandingFeeDetailForMonthQuery.data.map(
        (data) => data.booking_aircraft_id
      );
    }
    filterLocationIds = [...new Set(filterLocationIds)];
    filterAircraftIds = [...new Set(filterAircraftIds)];
    this.setState({
      filterLocationIds,
      filterAircraftIds,
    });
    let filterLocationId = nextProps.currentSettingsReportLocationId;
    if (filterLocationId && this.isLoaded(nextProps)) {
      if (
        filterLocationIds.length > 0 &&
        filterLocationIds.indexOf(filterLocationId) === -1
      ) {
        filterLocationId = '';
      }
    }
    if (filterLocationId !== this.props.currentSettingsReportLocationId) {
      this.handleDisplayLocationIdChange({ target: { value: filterLocationId } });
    }

    let filterAircraftId = nextProps.currentSettingsReportAircraftId;
    if (filterAircraftId && this.isLoaded(nextProps)) {
      if (
        filterAircraftIds.length > 0 &&
        filterAircraftIds.indexOf(filterAircraftId) === -1
      ) {
        filterAircraftId = '';
      }
    }
    if (filterAircraftId !== this.props.currentSettingsReportAircraftId) {
      this.handleDisplayAircraftIdChange({ target: { value: filterAircraftId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  handleDisplayLocationIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterLocationId: value,
    });
    this.props.currentSettingsSet({
      reportLocationId: value,
    });
  }

  handleDisplayAircraftIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value, 10))
      ? ''
      : parseInt(e.target.value, 10);
    this.setState({
      filterAircraftId: value,
    });
    this.props.currentSettingsSet({
      reportAircraftId: value,
    });
  }

  handleSaveFlightSegmentInvoicedClick() {
    const { flightSegmentIds } = this.state;
    Object.keys(flightSegmentIds).forEach((flightSegmentId) => {
      if (!this.submitting) {
        this.submitting = true;
        this.setState({ submitting: true });
      }
      const { booking_id, invoiced } = flightSegmentIds[flightSegmentId];
      this.props
        .bookingUpdateMutation({
          variables: {
            id: booking_id,
            input: typeInput({
              id: booking_id,
              flight_segments_attributes: {
                id: flightSegmentId,
                end_location_landing_fee_invoiced: invoiced,
              },
            }),
          },
        })
        .then((res) => {
          this.props.mutationSuccess('Booking landing fee invoice');
          this.delayedHandleRefetch();
        })
        .catch((err) => {
          if (this.submitting) {
            this.submitting = false;
            this.setState({ submitting: false });
          }
          this.props.mutationFailure(err);
        });
    });
  }

  handleFlightSegmentInvoicedChange(e) {
    const { flightSegmentIds } = this.state;
    const [, booking_id, flightSegmentId] = e.target.id.split('-');
    flightSegmentIds[flightSegmentId] = { booking_id, invoiced: e.target.checked };
    this.setState({
      flightSegmentIds,
    });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.flightSegmentLandingFeeDetailForMonthQuery,
      props.locationListQuery,
      props.aircraftListQuery
    );
  }

  renderLandingFeeList = (list) => {
    if (list === null) {
      return (
        <td className="text-end">
          <small>&ndash;</small>
        </td>
      );
    }
    return <td className="text-end">{accounting.formatMoney(list)}</td>;
  };

  renderLandingFeeOriginal = (list, original) => {
    if (original === null) {
      return (
        <td className="text-end">
          <small>&ndash;</small>
        </td>
      );
    }
    if (list !== original) {
      return (
        <td className="text-end" style={{ border: '2px solid red' }}>
          {accounting.formatMoney(original)}
        </td>
      );
    }
    return <td className="text-end">{accounting.formatMoney(original)}</td>;
  };

  renderLandingFee = (list, original, fee) => {
    if (list === null && original === null && (fee === null || fee === 0)) {
      return (
        <td className="text-end">
          <small>&ndash;</small>
        </td>
      );
    }
    if (original !== null && original !== fee) {
      return (
        <td className="text-end" style={{ border: '2px solid red' }}>
          {fee !== null ? accounting.formatMoney(fee) : <span>&ndash;</span>}
        </td>
      );
    }
    if (original === null && list !== null && list !== fee) {
      return (
        <td className="text-end" style={{ border: '2px solid red' }}>
          {fee !== null ? accounting.formatMoney(fee) : <span>&ndash;</span>}
        </td>
      );
    }
    return <td className="text-end">{accounting.formatMoney(fee)}</td>;
  };

  renderFixed(value) {
    return value ? value.toFixed(2) : '-';
  }

  renderAircraftReconciliation(flight_segments) {
    const reconciliationData = flight_segments.reduce(
      (results, flight_segment) => {
        const result = results[flight_segment.booking_aircraft_id] || {
          aircraft_registration_abbreviated:
            flight_segment.aircraft_registration_abbreviated,
          total: 0,
          count: 0,
        };
        results.total += flight_segment.end_location_landing_fee;
        result.total += flight_segment.end_location_landing_fee;
        result.count += 1;
        results[flight_segment.booking_aircraft_id] = result;
        return results;
      },
      { total: 0 }
    );
    const { total } = reconciliationData;
    delete reconciliationData.total;
    return (
      <Row xs={1}>
        <Col>
          <h4>Aircraft Reconciliation</h4>
        </Col>
        <Col md={6}>
          <Table striped size="sm">
            <tbody>
              {Object.values(reconciliationData).map((flight_segment) => {
                const { count, aircraft_registration_abbreviated, total } =
                  flight_segment;
                return (
                  <tr
                    key={`aircraft-reconciliation-${aircraft_registration_abbreviated}`}
                  >
                    <td>{aircraft_registration_abbreviated}</td>
                    <td>{count}</td>
                    <td className="text-end">{accounting.formatMoney(total)}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-end" colSpan={2}>
                  Aircraft Total
                </th>
                <th className="text-end">{accounting.formatMoney(total)}</th>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    );
  }

  renderProviderReconciliation(flight_segments) {
    const reconciliationData = flight_segments.reduce(
      (results, flight_segment) => {
        const result = results[flight_segment.booking_provider_id] || {
          provider_full_name: flight_segment.provider_full_name,
          total: 0,
          count: 0,
        };
        results.total += flight_segment.end_location_landing_fee;
        result.total += flight_segment.end_location_landing_fee;
        result.count += 1;
        results[flight_segment.booking_provider_id] = result;
        return results;
      },
      { total: 0 }
    );
    const { total } = reconciliationData;
    delete reconciliationData.total;
    return (
      <Row xs={1}>
        <Col>
          <h4>Provider Reconciliation</h4>
        </Col>
        <Col md={6}>
          <Table striped size="sm">
            <tbody>
              {Object.values(reconciliationData).map((flight_segment) => {
                const { count, provider_full_name, total } = flight_segment;
                return (
                  <tr key={`provider-reconciliation-${provider_full_name}`}>
                    <td>{provider_full_name}</td>
                    <td>{count}</td>
                    <td className="text-end">{accounting.formatMoney(total)}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-end" colSpan={2}>
                  Aircraft Total
                </th>
                <th className="text-end">{accounting.formatMoney(total)}</th>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    );
  }

  renderChargeableReconciliation(flight_segments) {
    const reconciliationData = flight_segments.reduce(
      (results, flight_segment) => {
        flight_segment.chargeables.forEach((chargeable) => {
          const { chargeable_id, chargeable_full_name, pax, denominator } = chargeable;
          const result = results[chargeable_id] || {
            chargeable_full_name,
            total: 0,
            count: 0,
          };
          result.total += (flight_segment.end_location_landing_fee / denominator) * pax;
          result.count += 1;
          results[chargeable_id] = result;
        });
        results.total += flight_segment.end_location_landing_fee;
        return results;
      },
      { total: 0 }
    );
    const { total } = reconciliationData;
    delete reconciliationData.total;
    return (
      <Row xs={1}>
        <Col>
          <h4>Chargeable Reconciliation</h4>
        </Col>
        <Col md={6}>
          <Table striped size="sm">
            <tbody>
              {Object.values(reconciliationData).map((flight_segment) => {
                const { count, chargeable_full_name, total } = flight_segment;
                return (
                  <tr key={`chargeable-reconciliation-${chargeable_full_name}`}>
                    <td>{chargeable_full_name}</td>
                    <td>{count}</td>
                    <td className="text-end">{accounting.formatMoney(total)}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <th className="text-end" colSpan={2}>
                  Aircraft Total
                </th>
                <th className="text-end">{accounting.formatMoney(total)}</th>
              </tr>
            </tfoot>
          </Table>
        </Col>
      </Row>
    );
  }

  renderRow(flight_segment) {
    const {
      id,
      booking_id,
      flight_segment_id: flightSegmentId,
      booking_reference,
      booking_start_at_s,
      aircraft_registration_abbreviated,
      pilot_display_name,
      copilot_display_name,
      chargeable_full_names,
      end_location_landing_fee_list,
      end_location_landing_fee,
      end_location_landing_fee_original,
      end_location_landing_fee_invoiced,
      end_location_full_name,
    } = flight_segment;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${booking_reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{booking_start_at_s}</td>
        <td>{end_location_full_name}</td>
        <td>{aircraft_registration_abbreviated}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(',')}
        </td>
        <td>{chargeable_full_names}</td>
        {this.renderLandingFeeList(end_location_landing_fee_list)}
        {this.renderLandingFeeOriginal(
          end_location_landing_fee_list,
          end_location_landing_fee_original
        )}
        {this.renderLandingFee(
          end_location_landing_fee_list,
          end_location_landing_fee_original,
          end_location_landing_fee
        )}
        <td className="text-end">
          <input
            id={`toggleinvoiced-${booking_id}-${flightSegmentId}`}
            type="checkbox"
            checked={
              this.state.flightSegmentIds[flightSegmentId] !== undefined
                ? this.state.flightSegmentIds[flightSegmentId].invoiced
                : end_location_landing_fee_invoiced
            }
            onChange={this.handleFlightSegmentInvoicedChange}
          />
        </td>
      </tr>
    );
  }

  renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Location</th>
          <th className="border-top-0">Registration</th>
          <th className="border-top-0">Pilot</th>
          <th className="border-top-0">Customer(s)</th>
          <th className="text-end border-top-0">List Fee</th>
          <th className="text-end border-top-0">Original Fee</th>
          <th className="text-end border-top-0">Amount Charged</th>
          <th className="text-end border-top-0">Received</th>
        </tr>
      </thead>
    );
  }

  renderFlightSegments(flight_segments) {
    return (
      <Row xs={1}>
        <Col className="d-flex justify-content-end align-items-start">
          <Button
            variant="primary"
            onClick={this.handleSaveFlightSegmentInvoicedClick}
            className="py-0"
          >
            Save Received
          </Button>
        </Col>
        <Col>
          <Table striped size="sm">
            {this.renderHeaderRow()}
            <tbody>
              {flight_segments.map((flight_segment) => this.renderRow(flight_segment))}
            </tbody>
          </Table>
        </Col>
      </Row>
    );
  }
  renderSections() {
    const flight_segments =
      this.props.flightSegmentLandingFeeDetailForMonthQuery.data.filter(
        (flight_segment) => {
          if (
            this.state.filterAircraftId &&
            flight_segment.booking_aircraft_id !== this.state.filterAircraftId
          ) {
            return false;
          }
          if (
            this.state.filterLocationId &&
            flight_segment.end_location_id !== this.state.filterLocationId
          ) {
            return false;
          }
          return true;
        }
      );
    return (
      <>
        {this.renderFlightSegments(flight_segments)}
        {this.renderAircraftReconciliation(flight_segments)}
        {this.renderProviderReconciliation(flight_segments)}
        {this.renderChargeableReconciliation(flight_segments)}
      </>
    );
  }

  renderLocationFilter() {
    const dataLocations = this.props.locationListQuery.data.filter(
      (model) => this.state.filterLocationIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterLocationId',
          value: this.state.filterLocationId,
          onChange: this.handleDisplayLocationIdChange,
        }}
        asElement="select"
        selectOptions={dataLocations}
        optionKey="fullName"
        defaultSelectOptionName="All"
      />
    );
  }

  renderAircraftFilter() {
    const dataAircrafts = this.props.aircraftListQuery.data.filter(
      (model) => this.state.filterAircraftIds.indexOf(model.id) > -1
    );
    return (
      <InputField
        size="sm"
        labelWidth={0}
        inputWidth={0}
        input={{
          name: 'filterAircraftId',
          value: this.state.filterAircraftId,
          onChange: this.handleDisplayAircraftIdChange,
        }}
        asElement="select"
        selectOptions={dataAircrafts}
        optionKey="registration_abbreviated"
        defaultSelectOptionName="All"
      />
    );
  }

  render() {
    if ((this.submitting && this.state.submitting) || this.isLoading()) {
      return <Loader />;
    }
    return (
      <>
        <Row className="my-3">
          <Col>
            <ReportHeader
              title="Location Landing Fee Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          {this.renderLocationFilter()}
          {this.renderAircraftFilter()}
        </Row>
        {this.renderSections()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportLocationId: state.currentSettings.reportLocationId,
    currentSettingsReportAircraftId: state.currentSettings.reportAircraftId,
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(flightSegmentLandingFeeDetailForMonthQuery, {
    name: 'flightSegmentLandingFeeDetailForMonthQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportLocationLandingFee);
