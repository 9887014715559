import { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Row,
  Col,
  Card,
  ButtonGroup,
  Button,
  OverlayTrigger,
  Tooltip,
  Accordion,
} from 'react-bootstrap';
import get from 'lodash.get';
import compact from 'lodash.compact';
import last from 'lodash.last';
import upperFirst from 'lodash.upperfirst';
import pick from 'lodash.pick';
import transform from 'lodash.transform';
import { camelCase } from 'change-case';

import Confirm from '../confirm';
import Glyphicon from '../glyphicon';
import OverlayableLinkContainer from '../overlayable_link_container';
import CardHeaderToggle from '../card_header_toggle';

import {
  isStatus,
  isCalendarType,
  isAdminComplete,
  isPilotComplete,
  isAuditComplete,
} from '../lib/bookingHelpers';

import { renderMultlilineText } from '../../lib/utils';

// class BookingManagerListItem extends Component {
const BookingManagerListItem = ({
  model,
  onListItemCalendarClicked,
  onFlightCompleteClicked,
  onDeleteClicked,
  onCloneClicked,
  currentSettingsBookingStatusCancelled,
  currentSettingsBookingCalendarTypeFlight,
  currentSettingsBookingCalendarTypeBanner,
  contactQuickData,
  aircraftQuickData,
  currentContact,
}) => {
  const [booking, setBooking] = useState({});
  const [showNotes, setShowNotes] = useState(false);
  const [showEmptyFlightSegments, setShowEmptyFlightSegments] = useState(true);

  useEffect(() => {
    let newBooking = {};
    if (model) {
      newBooking = pick(model, [
        'id',
        'reference',
        'requested_by',
        'start_at',
        'end_at',
        'calendar_type',
        'aircraft_id',
        'pilot_id',
        'copilot_id',
        'status',
        'bookingChargeables',
        'bookingEmployees',
        'contact_flight_notes',
        'job_notes',
        'pilot_notes',
        'public_notes',
        'flightSegmentSummaries',
        'admin_completed_at',
        'audit_created_at',
        'hobbRecord',
      ]);
      newBooking = transform(newBooking, (result, val, key) => {
        // eslint-disable-next-line no-param-reassign
        result[camelCase(key)] = val;
      });
    }
    setBooking(newBooking);
  }, [model]);

  const humanize = (string) => upperFirst(string.split('_').join(' '));

  const handleCalendarClick = (e) => {
    if (onListItemCalendarClicked) {
      onListItemCalendarClicked(parseInt(last(e.currentTarget.id.split('-')), 10));
    }
  };

  const handleFlightCompleteClick = (e) => {
    if (onFlightCompleteClicked) {
      onFlightCompleteClicked(parseInt(last(e.currentTarget.id.split('-')), 10));
    }
  };

  const handleDeleteClick = (e) => {
    if (onDeleteClicked) {
      onDeleteClicked(last(e.currentTarget.id.split('-')));
    }
  };

  const handleCloneDayClick = (e) => {
    if (onCloneClicked) {
      onCloneClicked(last(e.currentTarget.id.split('-')), 'day');
    }
  };

  const handleCloneHelicopterClick = (e) => {
    if (onCloneClicked) {
      onCloneClicked(last(e.currentTarget.id.split('-')), 'heli');
    }
  };

  const renderBodyButton = (index, { icon, tooltip, onclick, confirm, id, link }) => {
    const tip = <Tooltip id={`tooltip_${index}`}>{tooltip}</Tooltip>;
    if (confirm) {
      return (
        <Confirm
          confirmId={`booking-item-button-${index}-${id}`}
          key={index}
          onConfirm={onclick}
          title={tooltip}
          body={confirm}
          confirmText="Confirm"
          tip={tip}
        >
          <Button variant="link" size="sm" className="p-0">
            <Glyphicon glyph={icon} />
          </Button>
        </Confirm>
      );
    }
    if (link) {
      // todo this OverlayableLinkCOntainer is not working.  still no tooltip, but error suppressed
      return (
        <OverlayTrigger key={index} placement="top" overlay={tip}>
          <OverlayableLinkContainer to={`/${link}/${id}/edit`}>
            <Button variant="link" size="sm" onClick={onclick} className="p-0">
              <Glyphicon glyph={icon} />
            </Button>
          </OverlayableLinkContainer>
        </OverlayTrigger>
      );
    }
    return (
      <OverlayTrigger key={index} placement="top" overlay={tip}>
        <Button
          id={`booking-item-button-${index}-${id}`}
          variant="link"
          size="sm"
          onClick={onclick}
          className="p-0"
        >
          <Glyphicon glyph={icon} />
        </Button>
      </OverlayTrigger>
    );
  };

  const renderBodyButtons = () => {
    const { id, endAt, calendarType } = booking;
    const humanCalendarType = humanize(calendarType);
    const isStatusCancelled = isStatus(currentSettingsBookingStatusCancelled, booking);
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    const isCalendarTypeBanner = isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    const adminComplete = isAdminComplete(booking);
    const auditComplete = isAuditComplete(booking);
    const buttons = [];
    if (!adminComplete) {
      if (isCalendarTypeFlight) {
        if (auditComplete) {
          buttons.push({
            id,
            link: 'flights',
            icon: 'plane',
            tooltip: 'Edit completed flight',
          });
        } else if (isStatusCancelled) {
          buttons.push({
            id,
            link: 'flights',
            icon: 'edit',
            tooltip: 'Edit flight',
          });
        } else {
          let completeMessage = 'Are you sure you want to complete this booking?';
          if (moment(endAt).isAfter(moment(), 'minute')) {
            completeMessage = `${completeMessage} Booking is not due to end until ${moment(
              endAt
            ).format('ddd, DD MMM YYYY')} at ${moment(endAt).format('hh:mma')}.`;
          }
          buttons.push({
            id,
            link: 'flights',
            icon: 'edit',
            tooltip: 'Edit flight',
          });
          buttons.push({
            id,
            confirm: completeMessage,
            icon: 'plane',
            tooltip: 'Complete flight',
            onclick: handleFlightCompleteClick,
          });
        }
      } else {
        buttons.push({
          id,
          link: 'nonflights',
          icon: 'edit',
          tooltip: `Edit ${humanCalendarType}`,
        });
      }
    }
    if (!(isStatusCancelled || isCalendarTypeBanner)) {
      buttons.push({
        id,
        icon: 'calendar',
        tooltip: 'Calendar',
        onclick: handleCalendarClick,
      });
    }
    if (!adminComplete) {
      if (currentContact['office_admin?']) {
        if (!isStatusCancelled && isCalendarTypeFlight && !auditComplete) {
          buttons.push({
            id,
            icon: 'copy',
            tooltip: 'Multi Day Clone',
            onclick: handleCloneDayClick,
          });
          buttons.push({
            id,
            icon: 'copy',
            tooltip: 'Multi Helicopter Clone',
            onclick: handleCloneHelicopterClick,
          });
        }
        buttons.push({
          id,
          confirm: 'Are you sure you want to delete this booking?',
          icon: 'bin',
          tooltip: 'Remove',
          onclick: handleDeleteClick,
        });
      }
    }

    return (
      <ButtonGroup>
        {buttons.map((button, index) => renderBodyButton(index, button))}
      </ButtonGroup>
    );
  };

  const renderHeaderDate = () => {
    const { startAt, endAt } = booking;
    return (
      <span>
        {moment(startAt).isSame(moment(endAt), 'day')
          ? moment(startAt).format('ddd, DD MMM YYYY')
          : `${moment(startAt).format('ddd, DD MMM YYYY')} - ${moment(endAt).format(
              'ddd, DD MMM YYYY'
            )}`}
      </span>
    );
  };

  const renderHeaderTime = () => {
    const isNotCalendarTypeBanner = !isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    if (isNotCalendarTypeBanner) {
      const { startAt, endAt } = booking;
      return (
        <span>{`${moment(startAt).format('hh:mma')}-${moment(endAt).format(
          'hh:mma'
        )}`}</span>
      );
    }
    return undefined;
  };

  const renderDisplayCalendarType = (calendarType) => {
    switch (calendarType) {
      case 'rostered':
        return 'rostered off'.toUpperCase();
      default:
        return calendarType.toUpperCase();
    }
  };

  const renderRegistationAndType = (aircraftId) => {
    const aircraftRegistrationAbbreviated = get(aircraftQuickData, [
      aircraftId,
      'registrationAbbreviated',
    ]);
    const aircraftAircraftTypeName = get(aircraftQuickData, [
      aircraftId,
      'aircraftTypeName',
    ]);
    return [aircraftAircraftTypeName, aircraftRegistrationAbbreviated]
      .map((a) => a)
      .join(' ');
  };

  const renderHeaderTopLeft = () => {
    const { calendarType, jobNotes, bookingChargeables } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    const isCalendarTypeBanner = isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    if (isCalendarTypeBanner) {
      return (
        <span>
          <strong>{jobNotes.toUpperCase()}</strong>
        </span>
      );
    }
    if (isCalendarTypeFlight) {
      const chargeableFullNames = bookingChargeables
        .map((bca) => get(contactQuickData, [bca.chargeable_id, 'fullName']))
        .join(', ');
      return <span>{chargeableFullNames}</span>;
    }
    return (
      <span>
        <span style={{ paddingRight: '0.5em' }}>
          <strong>{renderDisplayCalendarType(calendarType)}</strong>
        </span>
      </span>
    );
  };

  const renderHeaderTopRight = () => {
    const { reference } = booking;
    const isCalendarTypeBanner = isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    if (isCalendarTypeBanner) {
      return <span>{renderBodyButtons()}</span>;
    }
    return `#${reference}`;
  };

  const renderHeaderBottomLeft = () => (
    <span>
      <span style={{ paddingRight: '0.5em' }}>{renderHeaderDate()}</span>
      <span style={{ paddingRight: '0.5em' }}>{renderHeaderTime()}</span>
    </span>
  );

  const renderHeaderBottomRight = () => {
    const { bookingEmployees, aircraftId, pilotId, copilotId } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );

    if (isCalendarTypeFlight) {
      const pilotFullName = get(contactQuickData, [pilotId, 'fullName']);
      const copilotFullName = get(contactQuickData, [copilotId, 'fullName']);
      return (
        <span>{`${compact([pilotFullName, copilotFullName]).join(
          '/'
        )} - ${renderRegistationAndType(aircraftId)}`}</span>
      );
    }
    if (aircraftId) {
      return <span>{renderRegistationAndType(aircraftId)}</span>;
    }
    if (bookingEmployees) {
      const employeeFullNames = bookingEmployees
        .map((bea) => get(contactQuickData, [bea.employee_id, 'fullName']))
        .join(', ');
      return <span className="small">{employeeFullNames}</span>;
    }
    return null;
  };

  const renderHeader = () => {
    const isNotCalendarTypeBanner = !isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    return (
      <>
        <Row>
          <Col>{renderHeaderTopLeft()}</Col>
          <Col xs="auto" className="text-end">
            {renderHeaderTopRight()}
          </Col>
        </Row>
        {isNotCalendarTypeBanner && (
          <Row>
            <Col>{renderHeaderBottomLeft()}</Col>
            <Col xs="auto" className="text-end">
              {renderHeaderBottomRight()}
            </Col>
          </Row>
        )}
      </>
    );
  };

  const handleToggleEmptyFlightSegments = () => {
    setShowEmptyFlightSegments(!showEmptyFlightSegments);
  };

  const renderToggleFlightSegmentsButton = () => {
    const { id } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    if (isCalendarTypeFlight) {
      const tip = (
        <Tooltip id={`tooltip-hide-empty-${id}`}>Toggle empty flight segments</Tooltip>
      );
      return (
        <OverlayTrigger placement="top" overlay={tip}>
          <Button
            variant="link"
            size="sm"
            onClick={handleToggleEmptyFlightSegments}
            className="p-0"
          >
            <Glyphicon glyph="user" />
          </Button>
        </OverlayTrigger>
      );
    }
    return undefined;
  };

  const handleShowNotes = () => {
    setShowNotes(!showNotes);
  };

  const renderNotesButton = () => {
    const { id, contactFlightNotes, jobNotes, pilotNotes, publicNotes } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    if (
      isCalendarTypeFlight &&
      (jobNotes || pilotNotes || publicNotes || contactFlightNotes)
    ) {
      const tip = <Tooltip id={`tooltip-show-notes-${id}`}>Display notes</Tooltip>;
      return (
        <OverlayTrigger placement="top" overlay={tip}>
          <Button variant="link" size="sm" onClick={handleShowNotes} className="p-0">
            <Glyphicon glyph="list" />
          </Button>
        </OverlayTrigger>
      );
    }
    return undefined;
  };

  const renderFlightSegmentSummary = (flightSegmentSummary) => {
    const {
      id,
      start_at: startAt,
      start_location: startLocation,
      end_location: endLocation,
      pax,
    } = flightSegmentSummary;
    const startCritical = startAt.includes('*');
    return (
      <Row key={id} className="g-0">
        <Col xs="1">
          <small {...(startCritical && { style: { backgroundColor: 'yellow' } })}>
            {startAt.replace('*', '')}
          </small>
        </Col>
        <Col xs="auto" className="me-2">
          <small>{`${startLocation} - ${endLocation}`}</small>
        </Col>
        <Col xs="auto">
          <small>{pax}</small>
        </Col>
      </Row>
    );
  };

  const renderFlightSegmentSummaries = (flightSegmentSummaries) => (
    <>
      {flightSegmentSummaries
        .filter((flightSegmentSummary) =>
          showEmptyFlightSegments ? true : flightSegmentSummary.pax
        )
        .map((flightSegmentSummary) => renderFlightSegmentSummary(flightSegmentSummary))}
    </>
  );

  const renderBody = () => {
    const {
      flightSegmentSummaries,
      contactFlightNotes,
      jobNotes,
      pilotNotes,
      publicNotes,
      bookingEmployees,
    } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    const isNotCalendarTypeBanner = !isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    if (isNotCalendarTypeBanner) {
      const employeeFullNames = bookingEmployees
        .map((bea) => get(contactQuickData, [bea.employee_id, 'fullName']))
        .join(', ');
      return (
        <>
          <Row>
            <Col>
              <ButtonGroup>
                {renderNotesButton()}
                {renderToggleFlightSegmentsButton()}
              </ButtonGroup>
            </Col>
            <Col className="text-end">{renderBodyButtons()}</Col>
          </Row>
          <Row>
            <Col>
              {flightSegmentSummaries
                ? renderFlightSegmentSummaries(flightSegmentSummaries)
                : ''}
              {isCalendarTypeFlight || <p>{employeeFullNames}</p>}
              {(!isCalendarTypeFlight || showNotes) && (
                <>
                  <p className="small">{renderMultlilineText(contactFlightNotes)}</p>
                  <p className="small">{renderMultlilineText(pilotNotes)}</p>
                  <p className="small">{renderMultlilineText(jobNotes)}</p>
                  <p className="small">{renderMultlilineText(publicNotes)}</p>
                </>
              )}
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  };

  const getCardClassName = () => {
    const { calendarType, status, endAt } = booking;
    const isCalendarTypeFlight = isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    const adminComplete = isAdminComplete(booking);
    const pilotComplete = isPilotComplete(booking);
    const classNames = [calendarType];
    if (adminComplete) {
      classNames.push('admin_complete');
    } else if (pilotComplete) {
      classNames.push('pilot_complete');
    } else if (!isCalendarTypeFlight && moment(endAt).isBefore(moment())) {
      classNames.push('time_complete');
    } else {
      classNames.push(status);
    }
    classNames.push('mb-2');
    return classNames.join(' ');
  };

  const renderContent = () => {
    const { id } = booking;
    const isStatusCancelled = isStatus(currentSettingsBookingStatusCancelled, booking);
    const isNotCalendarTypeFlight = !isCalendarType(
      currentSettingsBookingCalendarTypeFlight,
      booking
    );
    const isNotCalendarTypeBanner = !isCalendarType(
      currentSettingsBookingCalendarTypeBanner,
      booking
    );
    const collapsible =
      (isNotCalendarTypeFlight && isNotCalendarTypeBanner) || isStatusCancelled;
    if (collapsible) {
      return (
        <Accordion>
          <Card key={id} className={getCardClassName()}>
            <CardHeaderToggle className="px-3" eventKey={id}>
              {renderHeader()}
            </CardHeaderToggle>
            <Accordion.Collapse eventKey={id}>
              <Card.Body className="px-3 pt-1 pb-3">{renderBody()}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    }
    return (
      <Card key={id} className={getCardClassName()}>
        <Card.Header>{renderHeader()}</Card.Header>
        {isNotCalendarTypeBanner && (
          <Card.Body className="px-3 pt-1 pb-3">{renderBody()}</Card.Body>
        )}
      </Card>
    );
  };
  return booking.id ? renderContent() : null;
};

export default BookingManagerListItem;
