import { Card, Col, Form, Button, Row } from 'react-bootstrap';
import { Form as FinalForm, Field } from 'react-final-form';

import { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import qs from 'qs';

import get from 'lodash.get';

import {
  subscribeMutationObservable,
  unsubscribeMutationObservable,
} from '../lib/process_mutation_subscription';
import { authLoad } from '../actions/auth_actions';
// import InputField from '../components/form/rff_input_field';
import InputField from '../components/form/input_field';
import Glyphicon from '../components/glyphicon';
import LoginLogo from '../images/login_logo_255X67.jpg';
import { loginFormValidator } from '../validators';
import hydrateQuery from '../queries/hydrate_query';

const useStyles = createUseStyles({
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

export default function Login() {
  const classes = useStyles();
  const isAuthenticating = useSelector((state) => state.auth.isAuthenticating);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const location = useLocation();
  let redirectPath = null;
  if (location.search) {
    redirectPath = get(
      qs.parse(location.search, { ignoreQueryPrefix: true }),
      'redirect',
      null
    );
  }

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(redirectPath || '/bookings');
  //   } else {
  //     // apolloClient.closeWSConnection();
  //     unsubscribeMutationObservable();
  //   }
  // }, [isAuthenticated, navigate, redirectPath, apolloClient]);

  // const handleFormSubmit = (data) => {
  //   dispatch(
  //     authLoad(data.email, data.password, redirectPath || '/bookings', navigate)
  //     // authLoad(
  //     //   'gordon.king02@gmail.com',
  //     //   'xxxxxxxx',
  //     //   redirectPath || '/bookings',
  //     //   navigate
  //     // )
  //   ).then(() => {
  //     // apolloClient.openWSConnection();
  //     // console.log('login subscribe ');
  //     subscribeMutationObservable();
  //   });
  // };

  useEffect(() => {
    if (isAuthenticated) {
      navigate(redirectPath || '/checks');
    } else {
      unsubscribeMutationObservable();
    }
  }, [isAuthenticated, redirectPath, navigate]);

  const handleFormSubmit = (data) => {
    try {
      dispatch(authLoad(data.email, data.password)).then((success) => {
        if (success) {
          apolloClient.query({
            query: hydrateQuery,
            fetchPolicy: 'network-only',
            context: { noBatch: true },
          });
          subscribeMutationObservable();
          navigate(redirectPath || '/checks');
        }
      });
    } catch (err) {
      console.log(err);
      if (window.$NODE_ENV !== 'development') {
        window.Rollbar.info('Failed login', {
          err,
        });
      }
      return err;
    }
    return undefined;
  };

  return (
    <Card>
      <Card.Body>
        <input style={{ display: 'none' }} type="text" name="fakeemailremembered" />
        <input
          style={{ display: 'none' }}
          type="password"
          name="fakepasswordremembered"
        />
        <div className="mb-4">
          <img className={classes.img} src={LoginLogo} alt="advanced flight logo" />
        </div>
        <FinalForm onSubmit={handleFormSubmit} validate={loginFormValidator}>
          {({ handleSubmit, submitting, pristine, invalid }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Field type="text" name="email" component={InputField} helpText="&nbsp;">
                Email
              </Field>
              <Field
                type="password"
                name="password"
                component={InputField}
                helpText="&nbsp;"
              >
                Password
              </Field>
              <Row className="justify-content-end">
                <Col sm="auto">
                  <Button
                    type="submit"
                    variant="primary"
                    disabled={pristine || invalid || submitting}
                  >
                    {isAuthenticating || submitting ? (
                      <Glyphicon glyph="refresh" spin />
                    ) : (
                      ''
                    )}{' '}
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </FinalForm>
      </Card.Body>
    </Card>
  );
}
