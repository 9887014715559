import { useCallback } from 'react';

import { Col } from 'react-bootstrap';
import Toggle from 'react-bootstrap-toggle';

import trim from 'lodash.trim';

import Glyphicon from '../glyphicon';
import FilterBadge from '../filter_badge';
import InputField from '../form/input_field';

const BookingManagerFilters = ({
  bookingListLength,
  filteredBookingsLength,
  employee,
  contactOnly,
  setContactOnly,
  resourceTypeContact,
  setResourceTypeContact,
  searchStr,
  setSearchStr,
}) => {
  const handleSetContactOnly = useCallback(() => {
    if (contactOnly) {
      setContactOnly(false);
    } else {
      setSearchStr('');
      setContactOnly(true);
    }
  }, [contactOnly, setContactOnly, setSearchStr]);

  const handleSetResourceTypeContact = useCallback(() => {
    if (resourceTypeContact) {
      setResourceTypeContact(false);
    } else {
      setResourceTypeContact(true);
    }
  }, [resourceTypeContact, setResourceTypeContact]);

  return (
    <>
      <FilterBadge filtered={filteredBookingsLength !== bookingListLength}>
        {filteredBookingsLength}
      </FilterBadge>
      {employee && (
        <>
          <InputField
            controlOnly
            inputProps={{ className: 'my-0 me-2', style: { width: '115px' } }}
            size="sm"
            type="text"
            placeholder="filter..."
            readOnly={contactOnly}
            input={{
              value: trim(searchStr),
              onChange: (e) => setSearchStr(e.target.value),
            }}
          />
          <Col sm="auto" className="p-0 my-0 me-2 align-self-center">
            <Toggle
              active={contactOnly}
              onClick={handleSetContactOnly}
              on="self"
              onstyle="info"
              off="all"
              offstyle="info"
              handlestyle="primary"
              height={27}
            />
          </Col>
          <Col sm="auto" className="p-0 m-0 align-self-center">
            <Toggle
              active={resourceTypeContact}
              onClick={handleSetResourceTypeContact}
              on={<Glyphicon glyph="user" />}
              onstyle="info"
              off={<Glyphicon glyph="plane" />}
              offstyle="info"
              handlestyle="primary"
              height={27}
            />
          </Col>
        </>
      )}
    </>
  );
};

export default BookingManagerFilters;
