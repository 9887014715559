import { useEffect, useCallback, useRef, useMemo } from 'react';
import { Row, Col, ButtonToolbar, Button, ButtonGroup } from 'react-bootstrap';
import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';
import moment from 'moment';

import get from 'lodash.get';
import debounce from 'lodash.debounce';

import renderEventPopover from './duty_event_popover';
import Glyphicon from '../glyphicon';

function ScrollableFullCalendar(props) {
  const {
    ROSTER_CALENDAR_HTML_ID,
    ROSTER_POPOVERS_HTML_ID,
    calendarEvents,
    calendarResources,
    handleEventReceive,
    handleEventChange,
    handleEventClick,
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
    dispatchDutyEventCollectionVars,
  } = props;

  const SLOT_MIN_WIDTH = 80;
  const LATENCY_SCROLL = SLOT_MIN_WIDTH * 3; // pixel unit, latency to scroll to start or end
  let lastScrollLeft = 0;
  const calendarRef = useRef();

  const getCalendarApi = useCallback(() => {
    const { current } = calendarRef;
    return current?.getApi();
  }, [calendarRef]);

  const moveToToday = useCallback(() => {
    const calendarApi = getCalendarApi();
    if (calendarApi) {
      const newStart = moment().startOf('month').subtract(1, 'month').format();
      const newEnd = moment().startOf('month').add(2, 'month').format();
      const newScrollTimeDays = moment().startOf('isoWeek').diff(newStart, 'days');
      dispatchDutyEventCollectionVars(newStart, newEnd);
      calendarApi.batchRendering(() => {
        setTimeout(() => {
          calendarApi.scrollToTime({
            days: newScrollTimeDays,
          });
        }, 0);
      });
    }
  }, [getCalendarApi, dispatchDutyEventCollectionVars]);

  const onPrevCalendarClicked = useCallback(() => {
    const calendarApi = getCalendarApi();
    if (calendarApi) {
      const newStart = moment(currentSettingsDutyEventCollectionStartDate)
        .startOf('month')
        .subtract(1, 'month')
        .format();
      const newScrollTimeDays = moment(currentSettingsDutyEventCollectionStartDate).diff(
        newStart,
        'days'
      );
      dispatchDutyEventCollectionVars(
        newStart,
        currentSettingsDutyEventCollectionEndDate
      );
      calendarApi.batchRendering(() => {
        setTimeout(() => {
          calendarApi.scrollToTime({
            days: newScrollTimeDays,
          });
        }, 0);
      });
    }
  }, [
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
    getCalendarApi,
    dispatchDutyEventCollectionVars,
  ]);

  const onNextCalendarClicked = useCallback(() => {
    const calendarApi = getCalendarApi();
    if (calendarApi) {
      const newEnd = moment(currentSettingsDutyEventCollectionEndDate)
        .startOf('month')
        .add(1, 'month')
        .format();
      const newScrollTimeDays = moment(currentSettingsDutyEventCollectionEndDate).diff(
        currentSettingsDutyEventCollectionStartDate,
        'days'
      );
      dispatchDutyEventCollectionVars(
        currentSettingsDutyEventCollectionStartDate,
        newEnd
      );
      calendarApi.batchRendering(() => {
        setTimeout(() => {
          calendarApi.scrollToTime({
            days: newScrollTimeDays,
          });
        }, 0);
      });
    }
  }, [
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
    getCalendarApi,
    dispatchDutyEventCollectionVars,
  ]);

  const debounceCalendarMoveStart = useMemo(
    () =>
      debounce((currentStart, currentEnd) => {
        const calendarApi = getCalendarApi();
        if (calendarApi) {
          const newStart = moment(currentStart)
            .startOf('month')
            .subtract(1, 'month')
            .format();
          const newScrollTimeDays = moment(currentStart)
            .subtract(2, 'day')
            .diff(newStart, 'days');
          dispatchDutyEventCollectionVars(newStart, currentEnd);
          calendarApi.batchRendering(() => {
            setTimeout(() => {
              calendarApi.scrollToTime({
                days: newScrollTimeDays,
              });
            }, 0);
          });
        }
      }, 1000),
    [getCalendarApi, dispatchDutyEventCollectionVars]
  );

  useEffect(
    /* eslint-disable arrow-body-style, react-hooks/exhaustive-deps */
    () => {
      return () => {
        debounceCalendarMoveStart.cancel();
      };
    },
    []
  );

  const debounceCalendarMoveEnd = useMemo(
    () =>
      debounce((currentStart, currentEnd) => {
        const calendarApi = getCalendarApi();
        if (calendarApi) {
          const newEnd = moment(currentEnd).startOf('month').add(1, 'month').format();
          const newScrollTimeDays = moment(currentEnd)
            .subtract(1, 'week')
            .diff(currentStart, 'days');
          dispatchDutyEventCollectionVars(currentStart, newEnd);
          calendarApi.batchRendering(() => {
            setTimeout(() => {
              calendarApi.scrollToTime({
                days: newScrollTimeDays,
              });
            }, 0);
          });
        }
      }, 1000),
    [getCalendarApi, dispatchDutyEventCollectionVars]
  );

  useEffect(
    /* eslint-disable arrow-body-style, react-hooks/exhaustive-deps */
    () => {
      return () => {
        debounceCalendarMoveEnd.cancel();
      };
    },
    []
  );

  const navigateCalendar = useCallback(
    (event) => {
      const { scrollWidth, scrollLeft, clientWidth } = event.target;
      const scrollingLeft = lastScrollLeft !== 0 && scrollLeft < lastScrollLeft;
      const scrollingRight = lastScrollLeft !== 0 && scrollLeft > lastScrollLeft;
      lastScrollLeft = scrollLeft;
      const nearlyStart = scrollLeft - LATENCY_SCROLL < 0;
      if (scrollingLeft && nearlyStart) {
        debounceCalendarMoveStart(
          currentSettingsDutyEventCollectionStartDate,
          currentSettingsDutyEventCollectionEndDate
        );
      }
      const nearlyEnd = scrollWidth - scrollLeft < clientWidth + LATENCY_SCROLL;
      if (scrollingRight && nearlyEnd) {
        debounceCalendarMoveEnd(
          currentSettingsDutyEventCollectionStartDate,
          currentSettingsDutyEventCollectionEndDate
        );
      }
    },
    [
      LATENCY_SCROLL,
      currentSettingsDutyEventCollectionStartDate,
      currentSettingsDutyEventCollectionEndDate,
      debounceCalendarMoveStart,
      debounceCalendarMoveEnd,
    ]
  );

  useEffect(() => {
    let scroller;
    let handleScrollEvent;

    const calendarDom = document.querySelector(`#${ROSTER_CALENDAR_HTML_ID}`);

    if (calendarDom) {
      // note not all browsers get the scroller footer rendered
      scroller = calendarDom.querySelector('tfoot th:nth-child(3) .fc-scroller');
      handleScrollEvent = (e) => {
        if (!e.target) {
          return;
        }
        navigateCalendar(e);
      };
      if (scroller) {
        scroller.addEventListener('scroll', handleScrollEvent);
      }
    }

    return () => {
      if (scroller && handleScrollEvent) {
        scroller.removeEventListener('scroll', handleScrollEvent);
      }
    };
  }, [ROSTER_CALENDAR_HTML_ID, navigateCalendar]);

  const renderTitle = () =>
    `${moment(currentSettingsDutyEventCollectionStartDate).format('M/YY')} - ${moment(
      currentSettingsDutyEventCollectionEndDate
    )
      .subtract(1, 'd')
      .format('M/YY')}`;

  return (
    <div id={ROSTER_CALENDAR_HTML_ID}>
      <Row className="justify-content-between">
        <Col>
          <h3>{renderTitle()}</h3>
        </Col>
        <Col className="flex-grow-1">
          <ButtonToolbar className="d-flex justify-content-end">
            <ButtonGroup className="me-2">
              <Button
                type="info"
                size="sm"
                variant="secondary"
                onClick={onPrevCalendarClicked}
              >
                <Glyphicon glyph="chevron-left" />
              </Button>
              <Button
                type="button"
                size="sm"
                variant="secondary"
                onClick={onNextCalendarClicked}
              >
                <Glyphicon glyph="chevron-right" />
              </Button>
            </ButtonGroup>
            <ButtonGroup>
              <Button type="info" size="sm" variant="secondary" onClick={moveToToday}>
                Today
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Col>
      </Row>
      <FullCalendar
        ref={calendarRef}
        themeSystem="bootstrap5"
        timeZone="local"
        locale="en-nz"
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        height="auto"
        plugins={[resourceTimelinePlugin, interactionPlugin, momentPlugin]}
        headerToolbar={false}
        views={{
          resourceTimelineMonthCustom: {
            type: 'resourceTimeline',
          },
        }}
        initialView="resourceTimelineMonthCustom"
        editable // can be dragged and resized
        selectable={false} // timeline and resources can be selected
        droppable // external elements can be dropped
        events={calendarEvents}
        eventReceive={handleEventReceive}
        eventChange={handleEventChange}
        eventClick={handleEventClick}
        resources={calendarResources}
        resourceAreaWidth="150px"
        slotMinWidth={SLOT_MIN_WIDTH}
        // resourceAreaHeaderContent="Pilots"
        resourceOrder="title"
        slotLabelFormat="dd D/M"
        // will only be set via props first time otherwise use api
        scrollTimeReset={false}
        // scrollTimeReset={true}
        scrollTime={{
          days: moment()
            .startOf('isoWeek')
            .diff(currentSettingsDutyEventCollectionStartDate, 'days'),
        }}
        visibleRange={{
          start: currentSettingsDutyEventCollectionStartDate,
          end: currentSettingsDutyEventCollectionEndDate,
        }}
        eventDidMount={(info) => {
          // https://github.com/fullcalendar/fullcalendar/issues/5528
          if (info.event.textColor) {
            // eslint-disable-next-line no-param-reassign
            info.el.style.color = info.event.textColor;
          }
          const description = get(info, 'event.extendedProps.description');
          const dutyNotes = get(info, 'event.extendedProps.dutyNotes');
          if (description || dutyNotes) {
            renderEventPopover(info, ROSTER_POPOVERS_HTML_ID);
          }
        }}
      />
    </div>
  );
}

export default ScrollableFullCalendar;
