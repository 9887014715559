import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Row,
  Col,
  Card,
  Button,
  ButtonToolbar,
  ButtonGroup,
  Table,
} from 'react-bootstrap';

import last from 'lodash.last';

import Confirm from '../components/confirm';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';
import Loader from '../components/loader';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import checkQuery from '../queries/check_query';
import checkDeleteMutation from '../mutations/check_delete_mutation';

import { queriesReady, getExport } from '../lib/utils';

moment.updateLocale('en-nz');

class CheckShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  getStatus = (model) => {
    const {
      check_start_on: checkStartOn,
      check_end_on: checkEndOn,
      check_complete_on: checkCompleteOn,
    } = model;
    if (checkCompleteOn) {
      return 'Completed';
    } else if (moment(checkEndOn).isBefore(moment())) {
      return 'Overdue';
    } else if (moment(checkStartOn).isBefore(moment())) {
      return 'Due now';
    } else {
      return 'Not due';
    }
  };

  displayBoolean = (attribute) => {
    if (attribute) {
      return 'Yes';
    } else {
      return 'No';
    }
  };

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const exportType = e.target.getAttribute('data-export-type');
    const displayName = e.target.getAttribute('data-display-name');
    getExport(reportName, {}, null, exportType, displayName)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(testProps.checkQuery);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const checkId = last(e.currentTarget.id.split('-'));
    this.props
      .checkDeleteMutation({
        variables: {
          id: checkId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Check delete');
        this.props.navigate('/checks');
      })
      .catch((err) => this.props.mutationFailure(err));
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderDocumentsTable(documents, id) {
    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>Document</th>
            <th>Uploader</th>
            <th>Date</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {documents &&
            documents.map((document) => {
              const {
                id: documentId,
                document_file_name: documentFileName,
                uploaded_at: uploadedAt,
                uploader,
                description,
              } = document;
              const documentFileExtension = documentFileName
                ? documentFileName.split('.').pop()
                : 'unknown';
              return (
                <tr key={id}>
                  <td>{description}</td>
                  <td>{uploader ? uploader.display_name : '-'}</td>
                  <td>{uploadedAt ? moment(uploadedAt).format('lll') : '-'}</td>
                  <td>
                    <Button
                      variant="link"
                      size="xsmall"
                      data-report-name={`documents/${documentId}`}
                      data-display-name={documentFileName}
                      data-export-type={documentFileExtension}
                      onClick={this.getExport}
                    >
                      {documentFileExtension === 'pdf' ? 'view' : 'download'}
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    );
  }

  renderData() {
    if (this.isLoaded()) {
      const model = this.props.checkQuery.data;
      const {
        id,
        checkType: {
          name: checkTypeName,
          critical: checkTypeCritical,
          lead_period_days: checkTypeLeadPeriodDays,
          rollover_period_months: checkTypeRolloverPeriodMonths,
        },
        checkCategory: { name: checkCategoryName },
        checkable: { fullName: checkableFullName },
        manager: { fullName: managerFullName },
        check_start_on: checkStartOn,
        check_end_on: checkEndOn,
        check_complete_on: checkCompleteOn,
        documents,
      } = model;

      const status = this.getStatus(model);

      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show id={id}>
                Check
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    {this.props.currentContact['manager?'] && (
                      <ButtonGroup size="sm" className="mx-1">
                        <Confirm
                          confirmId={`check-delete-${id}`}
                          disabled={status === 'Completed'}
                          onConfirm={this.handleDeleteClick}
                          title="Delete Contact"
                          body="Are you sure you want to delete this Check?"
                          confirmText="Confirm"
                        >
                          <Button variant="danger">Delete</Button>
                        </Confirm>
                      </ButtonGroup>
                    )}
                    <ButtonGroup size="sm" className="mx-1">
                      {this.props.currentContact['manager?'] && (
                        <LinkContainer to={`/checks/${id}/edit`}>
                          <Button variant="primary">Edit</Button>
                        </LinkContainer>
                      )}
                      <LinkContainer to="/checks">
                        <Button variant="primary">All Checks</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Check Details</Card.Header>
                    <Card.Body>
                      <DlHorizontal dt="Category" dd={checkCategoryName} />
                      <DlHorizontal dt="Type" dd={checkTypeName} />
                      <DlHorizontal dt="Checkable" dd={checkableFullName} />
                      <DlHorizontal dt="Responsible" dd={managerFullName} />
                      <DlHorizontal
                        dt="Critical"
                        dd={this.displayBoolean(checkTypeCritical)}
                      />
                      <DlHorizontal
                        dt="Check Start On"
                        dd={moment(checkStartOn).format('DD/MM/YYYY')}
                      />
                      <DlHorizontal
                        dt="Check End On"
                        dd={moment(checkEndOn).format('DD/MM/YYYY')}
                      />
                      <DlHorizontal dt="Check Complete On" dd={checkCompleteOn} />
                      <DlHorizontal dt="Status" dd={status} />
                      <DlHorizontal
                        dt="Rollover Period (months)"
                        dd={checkTypeRolloverPeriodMonths}
                      />
                      <DlHorizontal
                        dt="Lead Period (days)"
                        dd={checkTypeLeadPeriodDays}
                      />
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col sm={6}>
              <Row>
                <Col xs={12}>
                  <Card>
                    <Card.Header>Documents</Card.Header>
                    <Card.Body>{this.renderDocumentsTable(documents, id)}</Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <div>
        {this.renderOverlay()}
        {this.renderData()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(checkDeleteMutation, {
    name: 'checkDeleteMutation',
  }),
  graphql(checkQuery, {
    name: 'checkQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet })
)(CheckShow);
