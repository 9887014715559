import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';

import { Row, Col, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import last from 'lodash.last';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import tankConfigurationQuery from '../queries/tank_configuration_query';
import tankConfigurationDeleteMutation from '../mutations/tank_configuration_delete_mutation';

import { queriesReady } from '../lib/utils';

class TankConfigurationShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const tankConfigurationId = last(e.currentTarget.id.split('-'));
    this.props
      .tankConfigurationDeleteMutation({
        variables: {
          id: tankConfigurationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Tank Configuration delete');
        this.props.navigate('/tank_configurations');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftTypeListQuery,
      testProps.tankConfigurationQuery
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const {
        id,
        name,
        aircraftType: { name: aircraftTypeName },
        aircraftTankTankConfigurations,
      } = this.props.tankConfigurationQuery.data;
      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show id={id}>
                Tank Configuration
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        confirmId={`tank-configuration-delete-${id}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Tank Configuration"
                        body="Are you sure you want to delete this Tank Configuration?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/tank_configurations/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/tank_configurations">
                        <Button variant="primary">All Tank Configurations</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row className="mb-4">
            <Col sm={6}>
              <Card>
                <Card.Header>Tank Configuration Details</Card.Header>
                <Card.Body>
                  <DlHorizontal dt="Name" dd={name} />
                  <DlHorizontal dt="Aircraft Type" dd={aircraftTypeName} />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Header>Tank Details</Card.Header>
                <Card.Body>
                  {aircraftTankTankConfigurations.map((tank) => {
                    const {
                      position,
                      aircraftTank: { name: tankName },
                    } = tank;
                    return <DlHorizontal dt={position} dd={tankName} key={position} />;
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(tankConfigurationDeleteMutation, {
    name: 'tankConfigurationDeleteMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(tankConfigurationQuery, {
    name: 'tankConfigurationQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(TankConfigurationShow);

// export default connect(mapStateToProps, {
//   mutationSuccess,
//   mutationFailure,
//   mutationSet,
// })(TankConfigurationShow);
