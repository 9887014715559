import {
  Row,
  Col,
  Table,
  Form,
  Button,
  ButtonToolbar,
  ButtonGroup,
} from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import moment from 'moment';

import debounce from 'lodash.debounce';

import { currentSettingsSet } from '../actions/current_setting_actions';

import {
  mutationSuccess,
  mutationFailure,
  mutationSet,
} from '../actions/mutation_actions';

import ReactDateTimeFilter from '../components/form/react_date_time_filter';
import Loader from '../components/loader';
import ReportHeader from '../components/report_header';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import { queriesReady, typeInput, getExport } from '../lib/utils';

import bookingUpdateMutation from '../mutations/booking_update_mutation';

import bookingDetailForMonthByChargeableQuery from '../queries/booking_detail_for_month_by_chargeable_query';
import contactListQuery from '../queries/contact_list_query';

moment.updateLocale('en-nz');

class ReportBookingFlightRecord extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterChargeableId: this.props.currentSettingsReportChargeableId,
      filterChargeableIds: [],
      invoice_default: '',
      invoiceReferences: {},
      submitting: false,
    };
    this.submitting = false;
    this._handleDisplayChargeableIdChange =
      this._handleDisplayChargeableIdChange.bind(this);
    this._handleInvoiceReferenceChange = this._handleInvoiceReferenceChange.bind(this);
    this._handleClearRefsClick = this._handleClearRefsClick.bind(this);
    this._handleCopyRefsClick = this._handleCopyRefsClick.bind(this);
    this._handleSaveRefsClick = this._handleSaveRefsClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    if (this.props.params.startAtDate) {
      const date = moment(this.props.params.startAtDate, 'MM-YYYY');
      this.props.currentSettingsSet({
        reportStart: date.clone().startOf('day').toISOString(),
        reportEnd: date.clone().endOf('month').toISOString(),
      });
    }
    if (this.props.params.chargeableId) {
      this._handleDisplayChargeableIdChange({
        target: { value: this.props.params.chargeableId },
      });
    }
  }

  componentDidMount() {
    this.props.currentSettingsSet({ returnRoute: this.props.location.pathname });
    this.delayedHandleRefetch = debounce((e) => {
      if (this.submitting) {
        this.submitting = false;
        this.setState({ submitting: false });
      }
      this.props.bookingDetailForMonthByChargeableQuery.refetch();
    }, 250);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let { filterChargeableIds } = this.state;
    let { invoiceReferences } = this.state;
    if (!this.submitting && this.isLoaded(nextProps)) {
      filterChargeableIds = nextProps.bookingDetailForMonthByChargeableQuery.data.map(
        (data) => data.chargeable_id
      );
      invoiceReferences = nextProps.bookingDetailForMonthByChargeableQuery.data.reduce(
        (chargeableResult, chargeable) => {
          chargeableResult[chargeable.chargeable_id] = chargeable.bookings.reduce(
            (bookingResult, booking) => {
              bookingResult[booking.booking_id] = {
                booking_chargeable_id: booking.booking_chargeable_id,
                value: booking.invoice_reference || '',
                original_value: booking.invoice_reference || '',
              };
              return bookingResult;
            },
            {}
          );
          return chargeableResult;
        },
        {}
      );
    }
    this.setState({
      filterChargeableIds,
      invoiceReferences,
    });
    let filterChargeableId = nextProps.currentSettingsReportChargeableId;
    if (filterChargeableId && this.isLoaded(nextProps)) {
      if (
        filterChargeableIds.length > 0 &&
        filterChargeableIds.indexOf(filterChargeableId) === -1
      ) {
        filterChargeableId = '';
      }
    }
    if (filterChargeableId !== this.props.currentSettingsReportChargeableId) {
      this._handleDisplayChargeableIdChange({ target: { value: filterChargeableId } });
    }
  }

  componentWillUnmount() {
    this.delayedHandleRefetch.cancel();
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(
      props.bookingDetailForMonthByChargeableQuery,
      props.chargeableListQuery
    );
  }

  getExport = (e) => {
    this.props.mutationSet(true);
    const reportName = e.target.getAttribute('data-report-name');
    const args = {
      startAt: this.props.currentSettingsReportStart,
      endAt: this.props.currentSettingsReportEnd,
      chargeableId: this.props.currentSettingsReportChargeableId,
    };
    getExport(reportName, args)
      .then(() => {
        this.props.mutationSet(false);
      })
      .catch((err) => this.props.mutationFailure(err));
  };

  _handleDisplayChargeableIdChange(e) {
    const value = Number.isNaN(parseInt(e.target.value)) ? '' : parseInt(e.target.value);
    this.setState({
      filterChargeableId: value,
    });
    this.props.currentSettingsSet({
      reportChargeableId: value,
    });
  }

  _handleInvoiceReferenceChange(e) {
    const { invoiceReferences } = this.state;
    const [, chargeableId, bookingId] = e.target.id.split('-');
    invoiceReferences[parseInt(chargeableId)][parseInt(bookingId)].value = e.target.value;
    this.setState({ invoiceReferences });
  }

  _handleClearRefsClick(e) {
    const { invoiceReferences } = this.state;
    const [, chargeableId] = e.target.id.split('-');
    const chargeableInvoiceReferences = invoiceReferences[parseInt(chargeableId)];
    const newChargeableInvoiceReferences = Object.keys(
      chargeableInvoiceReferences
    ).reduce((bookingResult, bookingId) => {
      bookingResult[bookingId] = Object.assign(chargeableInvoiceReferences[bookingId], {
        value: '',
      });
      return bookingResult;
    }, {});
    invoiceReferences[parseInt(chargeableId)] = newChargeableInvoiceReferences;
    this.setState({ invoiceReferences });
  }

  _handleCopyRefsClick(e) {
    const { invoiceReferences } = this.state;
    const [, chargeableId, firstBookingId] = e.target.id.split('-');
    const chargeableInvoiceReferences = invoiceReferences[parseInt(chargeableId)];
    const bookingInvoiceReference =
      chargeableInvoiceReferences[parseInt(firstBookingId)].value;
    const newChargeableInvoiceReferences = Object.keys(
      chargeableInvoiceReferences
    ).reduce((bookingResult, bookingId) => {
      bookingResult[bookingId] = Object.assign(chargeableInvoiceReferences[bookingId], {
        value: bookingInvoiceReference,
      });
      return bookingResult;
    }, {});
    invoiceReferences[parseInt(chargeableId)] = newChargeableInvoiceReferences;
    this.setState({ invoiceReferences });
  }

  _handleSaveRefsClick(e) {
    const [, chargeableId] = e.target.id.split('-');
    const bookings = this.state.invoiceReferences[parseInt(chargeableId)];
    Object.keys(bookings).forEach((bookingId) => {
      const { value, original_value, booking_chargeable_id } = bookings[bookingId];
      if (value !== original_value) {
        if (!this.submitting) {
          this.submitting = true;
          this.setState({ submitting: true });
        }
        this.props
          .bookingUpdateMutation({
            variables: {
              id: bookingId,
              input: typeInput({
                id: bookingId,
                booking_chargeables_attributes: {
                  id: booking_chargeable_id,
                  chargeable_id: chargeableId,
                  invoice_reference: value,
                },
              }),
            },
          })
          .then((res) => {
            this.props.mutationSuccess('Booking reference');
            this.delayedHandleRefetch();
          })
          .catch((err) => {
            if (this.submitting) {
              this.submitting = false;
              this.setState({ submitting: false });
            }
            this.props.mutationFailure(err);
          });
      }
    });
  }

  _renderFixed(value, symbol = '', precision = 2) {
    return value ? symbol + value.toFixed(precision) : '-';
  }

  _renderFlightSegmentSummary(flightSegmentSummary) {
    const { start_at, start_location, end_location, pax } = flightSegmentSummary;
    const summary = `${start_at} ${start_location} - ${end_location}`;
    return (
      <span>
        {summary} {pax ? ' | ' : ''} {pax}
      </span>
    );
  }

  _renderRow(booking) {
    const {
      id,
      booking_id,
      aircraft_registration_abbreviated,
      reference,
      start_at_s,
      pilot_display_name,
      copilot_display_name,
      flight_segment_summaries,
      chargeable_id,
      chargeable_percentage,
      chargeable_flight_time,
      chargeable_landing_fee,
      chargeable_pilot_flight_expense,
    } = booking;

    return (
      <tr key={id}>
        <td>
          <LinkContainer to={`/flights/${booking_id}/edit`} className="ps-0">
            <Button variant="link" size="sm" className="text-start p-0 m-0">
              {`#${reference}`}
            </Button>
          </LinkContainer>
        </td>
        <td>{start_at_s}</td>
        <td>
          {[pilot_display_name, copilot_display_name].filter((name) => name).join(', ')}
        </td>
        <td>
          {flight_segment_summaries.map((flightSegmentSummary, index) => (
            <span key={index} style={{ display: 'block' }}>
              {this._renderFlightSegmentSummary(flightSegmentSummary)}
            </span>
          ))}
        </td>
        <td>{aircraft_registration_abbreviated}</td>
        <td className="text-end">
          {chargeable_percentage === 1
            ? ''
            : this._renderFixed(chargeable_percentage * 100, '', 0)}
        </td>
        <td className="text-end">{this._renderFixed(chargeable_flight_time)}</td>
        <td className="text-end">{this._renderFixed(chargeable_landing_fee)}</td>
        <td className="text-end">{this._renderFixed(chargeable_pilot_flight_expense)}</td>
        <td>
          <input
            id={`setinvoice-${chargeable_id}-${booking_id}`}
            type="text"
            style={{ width: '88px' }}
            value={
              this.state.invoiceReferences[chargeable_id] !== undefined
                ? this.state.invoiceReferences[chargeable_id][booking_id].value
                : this.state.invoice_default
            }
            onChange={this._handleInvoiceReferenceChange}
          />
        </td>
      </tr>
    );
  }

  _renderRows(bookings) {
    return <tbody>{bookings.map((booking) => this._renderRow(booking))}</tbody>;
  }

  _renderHeaderRow() {
    return (
      <thead>
        <tr>
          <th className="border-top-0">Ref#</th>
          <th className="border-top-0">Date</th>
          <th className="border-top-0">Pilot</th>
          <th className="border-top-0" width="30%">
            Route Details
          </th>
          <th className="border-top-0">Registration</th>
          <th className="border-top-0">Exp %</th>
          <th width="7%" className="border-top-0 text-end">
            Time
          </th>
          <th width="7%" className="border-top-0 text-end">
            Landing Exp $
          </th>
          <th width="7%" className="border-top-0 text-end">
            Other Exp $
          </th>
          <th className="border-top-0" width="88px">
            Inv #
          </th>
        </tr>
      </thead>
    );
  }

  _renderFooterRow(totals) {
    const { flightTime, landingFee, pilotFlightExpense } = totals;
    return (
      <tfoot>
        <tr>
          <th colSpan={6}>&nbsp;</th>
          <th className="text-end">{this._renderFixed(flightTime)}</th>
          <th className="text-end">{this._renderFixed(landingFee)}</th>
          <th className="text-end">{this._renderFixed(pilotFlightExpense)}</th>
          <th>&nbsp;</th>
        </tr>
      </tfoot>
    );
  }

  _renderGrandTotalFooterRow(totals) {
    const { flightTime, landingFee, pilotFlightExpense } = totals;
    return (
      <tfoot>
        <tr>
          <th colSpan={6} className="text-end">
            Totals
          </th>
          <th width="7%" className="text-end">
            {this._renderFixed(flightTime)}
          </th>
          <th width="7%" className="text-end">
            {this._renderFixed(landingFee)}
          </th>
          <th width="7%" className="text-end">
            {this._renderFixed(pilotFlightExpense)}
          </th>
          <th width="102px">&nbsp;</th>
        </tr>
      </tfoot>
    );
  }

  _renderChargeables() {
    const grandTotals = {
      flightTime: 0,
      landingFee: 0,
      pilotFlightExpense: 0,
    };
    return (
      <>
        {this.props.bookingDetailForMonthByChargeableQuery.data
          .filter((data) => {
            if (
              this.state.filterChargeableId &&
              data.chargeable_id !== this.state.filterChargeableId
            ) {
              return false;
            }
            return true;
          })
          .map((data) => {
            const totals = {
              flightTime: 0,
              landingFee: 0,
              pilotFlightExpense: 0,
            };
            data.bookings.reduce((totals, booking) => {
              totals.flightTime += booking.chargeable_flight_time;
              totals.landingFee += booking.chargeable_landing_fee;
              totals.pilotFlightExpense += booking.chargeable_pilot_flight_expense;
              return totals;
            }, totals);
            grandTotals.flightTime += totals.flightTime;
            grandTotals.landingFee += totals.landingFee;
            grandTotals.pilotFlightExpense += totals.pilotFlightExpense;
            return (
              <>
                <Row key={data.chargeable_id}>
                  <Col>
                    <h4>{data.chargeable_full_name}</h4>
                  </Col>
                  <Col className="d-flex justify-content-end align-items-start">
                    <ButtonToolbar>
                      <ButtonGroup>
                        <Button
                          id={`invoiceclearref-${data.chargeable_id}`}
                          size="sm"
                          variant="primary"
                          onClick={this._handleClearRefsClick}
                        >
                          Clear
                        </Button>
                        <Button
                          id={`invoiceccopyref-${data.chargeable_id}-${data.bookings[0].booking_id}`}
                          size="sm"
                          variant="primary"
                          onClick={this._handleCopyRefsClick}
                        >
                          Copy
                        </Button>
                        <Button
                          id={`invoicesaveref-${data.chargeable_id}`}
                          size="sm"
                          variant="primary"
                          onClick={this._handleSaveRefsClick}
                        >
                          Save
                        </Button>
                      </ButtonGroup>
                    </ButtonToolbar>
                  </Col>
                </Row>
                <Row className="mb-4">
                  <Col>
                    <Table striped size="sm">
                      {this._renderHeaderRow()}
                      {this._renderRows(data.bookings)}
                      {this._renderFooterRow(totals)}
                    </Table>
                  </Col>
                </Row>
              </>
            );
          })}
        <Row>
          <Col>
            <Table striped size="sm">
              {this._renderGrandTotalFooterRow(grandTotals)}
            </Table>
          </Col>
        </Row>
      </>
    );
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }

  renderOverlay() {
    if (
      (this.submitting && this.state.submitting) ||
      this.props.currentSettingsMutating ||
      this.isLoading()
    ) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    // if ((this.submitting && this.state.submitting) || this.isLoading()) { return <Loader /> }
    if (this.isLoading()) {
      return undefined;
    }
    return (
      <>
        <Row className="my-3">
          <Col className="d-flex justify-content-between align-items-start">
            <ReportHeader
              title="Customer Flight Record Report"
              start={this.props.currentSettingsReportStart}
              end={this.props.currentSettingsReportEnd}
            />
            <Button
              variant="primary"
              data-report-name="booking_detail_for_month_by_chargeable_flight_record"
              onClick={this.getExport}
            >
              PDF
            </Button>
          </Col>
        </Row>
        <Row>
          <Col sm="auto" className="px-0">
            <Button variant="link" onClick={this.delayedHandleRefetch} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </Col>
          <ReactDateTimeFilter
            size="sm"
            labelWidth={0}
            inputWidth={0}
            currentSettingsReportStart={this.props.currentSettingsReportStart}
            currentSettingsReportEnd={this.props.currentSettingsReportEnd}
            onChange={this.props.currentSettingsSet}
            closeOnSelect
          />
          <InputField
            size="sm"
            labelWidth={0}
            inputWidth={0}
            input={{
              name: 'filterChargeableId',
              value: this.state.filterChargeableId,
              onChange: this._handleDisplayChargeableIdChange,
            }}
            asElement="select"
            selectOptions={this.props.chargeableListQuery.data.filter(
              (model) => this.state.filterChargeableIds.indexOf(model.id) > -1
            )}
            optionKey="fullName"
            defaultSelectOptionName="All"
          />
        </Row>
        {this._renderChargeables()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsReportStart: state.currentSettings.reportStart,
    currentSettingsReportEnd: state.currentSettings.reportEnd,
    currentSettingsReportChargeableId: state.currentSettings.reportChargeableId,
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  connect(mapStateToProps, {
    currentSettingsSet,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(contactListQuery, {
    name: 'chargeableListQuery',
    options: { variables: { role: 'chargeable' } },
  }),
  graphql(bookingDetailForMonthByChargeableQuery, {
    name: 'bookingDetailForMonthByChargeableQuery',
    options: (props) => ({
      variables: {
        startAt: props.currentSettingsReportStart,
        endAt: props.currentSettingsReportEnd,
      },
      fetchPolicy: 'cache-and-network',
    }),
  })
)(ReportBookingFlightRecord);
