import mirrorCreator from 'mirror-creator';

const actionTypes = mirrorCreator([
  // 'CLIENT_PUBLISH',
  'LOCAL_LOAD',
  'DATA_RESET',
  'DATA_FETCH',
  'DATA_UNFETCH',
  'DATA_LOAD_SUCCESS',
  'DATA_LOAD_FAILURE',
  'AUTH_FETCH',
  'AUTH_LOAD_SUCCESS',
  'AUTH_LOAD_FAILURE',
  'AUTH_RESET_SUCCESS',
  'AUTH_RESET_FAILURE',
  'CURRENT_SETTINGS_SET',
  'TAG_CREATE',
]);

export default actionTypes;
