/* eslint-disable import/no-cycle  */
import { Navigate, useRoutes } from 'react-router-dom';
import compact from 'lodash.compact';
import first from 'lodash.first';

import Page404 from './components/page_404';

import Logout from './containers/logout';
import Login from './containers/login';
import AuthorizationList from './containers/authorization_list';
import BookingManager from './containers/booking_manager';
import FlightForm from './containers/flight_form';
import FlightSignoff from './containers/flight_signoff';
import CheckList from './containers/check_list';
import CheckForm from './containers/check_form';
import CheckShow from './containers/check_show';
import ContactList from './containers/contact_list';
import ContactForm from './containers/contact_form';
import ContactShow from './containers/contact_show';
import DocumentList from './containers/document_list';
import DocumentForm from './containers/document_form';
import FuelTankerList from './containers/fuel_tanker_list';
import FuelTankerShow from './containers/fuel_tanker_show';
import FuelTankerForm from './containers/fuel_tanker_form';
import FuelBowserFillForm from './containers/fuel_bowser_fill_form';
import LocationList from './containers/location_list';
import LocationForm from './containers/location_form';
import LocationShow from './containers/location_show';
import LocationMerge from './containers/location_merge';
import NonFlightForm from './containers/non_flight_form';
import ReportDataIntegrity from './containers/report_data_integrity';
import ReportAllocation from './containers/report_allocation';
import ReportAircraftFlightRecord from './containers/report_aircraft_flight_record';
import ReportAircraftFlightTimeEvent from './containers/report_aircraft_flight_time_event';
import ReportAircraftFlightTypeCategory from './containers/report_aircraft_flight_type_category';
import ReportChargeableFlightRecord from './containers/report_chargeable_flight_record';
import ReportProviderFlightRecord from './containers/report_provider_flight_record';
import ReportProviderFlightHour from './containers/report_provider_flight_hour';
import ReportChargeableInvoice from './containers/report_chargeable_invoice';
import ReportPilotDutyRecord from './containers/report_pilot_duty_record';
import ReportPilotFlightExpense from './containers/report_pilot_flight_expense';
import ReportPilotFlightLog from './containers/report_pilot_flight_log';
import ReportPilotCumulativeHour from './containers/report_pilot_cumulative_hour';
import ReportProviderSwapDetail from './containers/report_provider_swap_detail';
import ReportProviderSwapSummary from './containers/report_provider_swap_summary';
import ReportLocationLandingFee from './containers/report_location_landing_fee';
import ReportFuelTankerFillForAircraft from './containers/report_fuel_tanker_fill_for_aircraft';
import ReportFuelBowserFillForAircraft from './containers/report_fuel_bowser_fill_for_aircraft';
import ReportFuelBowserFillForFuelTanker from './containers/report_fuel_bowser_fill_for_fuel_tanker';
import RosterList from './containers/roster_list';
import WbLimitList from './containers/wb_limit_list';
import WbLimitForm from './containers/wb_limit_form';
import WbLimitShow from './containers/wb_limit_show';
import HoldConfigurationList from './containers/hold_configuration_list';
import HoldConfigurationForm from './containers/hold_configuration_form';
import HoldConfigurationShow from './containers/hold_configuration_show';
import SeatConfigurationList from './containers/seat_configuration_list';
import SeatConfigurationForm from './containers/seat_configuration_form';
import SeatConfigurationShow from './containers/seat_configuration_show';
import TankConfigurationList from './containers/tank_configuration_list';
import TankConfigurationForm from './containers/tank_configuration_form';
import TankConfigurationShow from './containers/tank_configuration_show';
import AircraftConfigurationList from './containers/aircraft_configuration_list';
import AircraftConfigurationForm from './containers/aircraft_configuration_form';
import AircraftConfigurationShow from './containers/aircraft_configuration_show';

import AuthLayout from './layouts/auth_layout';
import AuthWrapper from './layouts/auth_wrapper';
import FixedAndScrollLayout from './layouts/fixed_and_scroll_layout';
import FluidAndScrollLayout from './layouts/fluid_and_scroll_layout';
import FluidAndNoScrollLayout from './layouts/fluid_and_no_scroll_layout';

const auth = (Component, skipEmployee = false) => (
  <AuthWrapper skipEmployee={skipEmployee}>
    <Component />
  </AuthWrapper>
);

const restfulAuthRoutes = (
  path,
  listElement,
  showElement,
  formElement,
  skipEmployee = false
) => ({
  path,
  children: compact([
    formElement && {
      path: 'new',
      element: auth(formElement, skipEmployee),
    },
    showElement && {
      path: ':id',
      element: auth(showElement, skipEmployee),
    },
    formElement && {
      path: ':id/edit',
      element: auth(formElement, skipEmployee),
    },
    listElement && {
      path: '',
      element: auth(listElement, skipEmployee),
    },
  ]),
});

const reportAuthRoutes = (path, element) => {
  const segments = path.split('/');
  const firstSegment = first(segments);
  return {
    path: firstSegment,
    children: [
      {
        path: '',
        element: auth(element),
      },
      ...(segments.length > 1
        ? segments.slice(1).map((segment, index) => ({
            path: segments.slice(1, index + 2).join('/'),
            element: auth(element),
          }))
        : []),
    ],
  };
};

const AppRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <Navigate to="/checks" />,
    },
    {
      path: '/login',
      element: <Navigate to="/auth/login" />,
    },
    {
      path: '/logout',
      element: <Navigate to="/auth/logout" />,
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/auth',
      element: <AuthLayout />,
      children: [
        {
          path: 'login',
          element: <Login />,
        },
        {
          path: 'logout',
          element: <Logout />,
        },
      ],
    },
    {
      element: <FluidAndNoScrollLayout />,
      children: [
        {
          path: '/bookings',
          element: auth(BookingManager, true),
        },
      ],
    },
    {
      element: <FluidAndScrollLayout />,
      children: [
        {
          path: '/rosters',
          element: auth(RosterList),
        },
        {
          path: '/authorizations',
          element: auth(AuthorizationList),
        },
        {
          path: '/reports',
          children: [
            reportAuthRoutes(
              'pilot_flight_log/:startAtDate/:pilotId',
              ReportPilotFlightLog
            ),
          ],
        },
      ],
    },
    {
      element: <FixedAndScrollLayout />,
      children: [
        restfulAuthRoutes('/checks', CheckList, CheckShow, CheckForm),
        restfulAuthRoutes('/contacts', ContactList, ContactShow, ContactForm),
        restfulAuthRoutes('/documents', DocumentList, undefined, DocumentForm),
        restfulAuthRoutes('/flights', undefined, undefined, FlightForm, true),
        {
          path: '/flights/signoff',
          element: auth(FlightSignoff),
          children: [
            {
              path: ':startAtDate',
              element: auth(FlightSignoff),
              children: [
                {
                  path: ':aircraftId',
                  element: auth(FlightSignoff),
                },
              ],
            },
          ],
        },
        restfulAuthRoutes('/nonflights', undefined, undefined, NonFlightForm, true),
        restfulAuthRoutes(
          '/fuel_tankers/:fuelTankerId/fuel_bowser_fill',
          undefined,
          undefined,
          FuelBowserFillForm,
          true
        ),
        restfulAuthRoutes(
          '/fuel_tankers',
          FuelTankerList,
          FuelTankerShow,
          FuelTankerForm
        ),
        restfulAuthRoutes('/locations', LocationList, LocationShow, LocationForm),
        {
          path: '/locations/merge',
          element: auth(LocationMerge),
        },
        restfulAuthRoutes('/wb_limits', WbLimitList, WbLimitShow, WbLimitForm),
        restfulAuthRoutes(
          '/hold_configurations',
          HoldConfigurationList,
          HoldConfigurationShow,
          HoldConfigurationForm
        ),
        restfulAuthRoutes(
          '/seat_configurations',
          SeatConfigurationList,
          SeatConfigurationShow,
          SeatConfigurationForm
        ),
        restfulAuthRoutes(
          '/tank_configurations',
          TankConfigurationList,
          TankConfigurationShow,
          TankConfigurationForm
        ),
        restfulAuthRoutes(
          '/aircraft_configurations',
          AircraftConfigurationList,
          AircraftConfigurationShow,
          AircraftConfigurationForm
        ),
        {
          path: '/reports',
          children: [
            {
              path: 'integrity',
              element: auth(ReportDataIntegrity),
            },
            reportAuthRoutes(
              'aircraft_flight_record/:startAtDate/:aircraftId',
              ReportAircraftFlightRecord
            ),
            reportAuthRoutes('allocation/:startAtDate/:aircraftId', ReportAllocation),
            reportAuthRoutes(
              'aircraft_flight_time_event/:startAtDate/:aircraftId',
              ReportAircraftFlightTimeEvent
            ),
            reportAuthRoutes(
              'aircraft_flight_type_category/:startAtDate/:aircraftId',
              ReportAircraftFlightTypeCategory
            ),
            reportAuthRoutes(
              'fuel_bowser_fill_for_aircraft/:startAtDate/:aircraftId',
              ReportFuelBowserFillForAircraft
            ),
            reportAuthRoutes(
              'chargeable_flight_record/:startAtDate/:chargeableId',
              ReportChargeableFlightRecord
            ),
            reportAuthRoutes(
              'chargeable_invoice/:startAtDate/:chargeableId',
              ReportChargeableInvoice
            ),
            reportAuthRoutes(
              'provider_flight_record/:startAtDate/:providerId',
              ReportProviderFlightRecord
            ),
            reportAuthRoutes(
              'provider_flight_hour/:startAtDate/:providerId',
              ReportProviderFlightHour
            ),
            reportAuthRoutes(
              'provider_swap_detail/:startAtDate/:providerId',
              ReportProviderSwapDetail
            ),
            reportAuthRoutes(
              'provider_swap_summary/:startAtDate',
              ReportProviderSwapSummary
            ),
            reportAuthRoutes(
              'pilot_duty_record/:startAtDate/:pilotId',
              ReportPilotDutyRecord
            ),
            reportAuthRoutes(
              'pilot_flight_expense/:startAtDate/:pilotId',
              ReportPilotFlightExpense
            ),
            reportAuthRoutes(
              'pilot_cumulative_hour/:startAtDate/:pilotId',
              ReportPilotCumulativeHour
            ),
            reportAuthRoutes(
              'location_landing_fee/:startAtDate/:locationId',
              ReportLocationLandingFee
            ),
            reportAuthRoutes(
              'fuel_tanker_fill_for_aircraft/:startAtDate/:fuelTankerId',
              ReportFuelTankerFillForAircraft
            ),
            reportAuthRoutes(
              'fuel_bowser_fill_for_fuel_tanker/:startAtDate/:fuelTankerId',
              ReportFuelBowserFillForFuelTanker
            ),
          ],
        },
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);

export default AppRoutes;
