import { Form } from 'react-bootstrap';

const LabelBlock = ({ size, children }) => (
  <Form.Label className={`col-form-label col-form-label-${size}`}>{children}</Form.Label>
);

LabelBlock.defaultProps = {
  size: 'sm',
};

export default LabelBlock;
