import { toast } from 'react-toastify';
import get from 'lodash.get';
import omit from 'lodash.omit';
import forOwn from 'lodash.forown';
import isEmpty from 'lodash.isempty';
import { SubmissionError } from 'redux-form';

function toastArgs() {
  const args = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 6000,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
  };
  return args;
}

export const toastSuccessDev = (message) => {
  if (window.$NODE_ENV === 'development') {
    toast.success(message, toastArgs());
  }
};

export const toastSuccess = (message) => {
  toast.success(message, toastArgs());
};

export const toastError = (message) => {
  toast.error(message, toastArgs());
};

export const manageCatch = (dispatch, res, failure, args = null) => {
  const err = { ...res }.response;
  if (err) {
    if (err.status === 401) {
      // Only place I think this happens in is the login action when password/email incorrect
      toastError(
        `${err.statusText}. ${get(
          err,
          'data.errors.message',
          get(err, 'data.error', 'Something went wrong')
        )}`
      );
      if (window.Rollbar) {
        window.Rollbar.info('UNAUTHENTICATED_IN_MANAGE_CATCH', err);
      }
      if (args) {
        dispatch(failure(args));
      } else {
        dispatch(failure());
      }
    } else {
      toastError(
        `${err.statusText}. ${get(err, 'data.errors.message', 'Something went wrong')}`
      );
      if (args) {
        dispatch(failure(args));
      } else {
        dispatch(failure());
      }
      return omit(get(err, 'data.errors', {}), ['message']);
    }
  } else {
    // todo these uncaught errors should be being logged
    if (window.Rollbar) {
      window.Rollbar.info('UNPARSED_ERROR_IN_MANAGE_CATCH', res);
    }
    toastError('Something went wrong');
    if (args) {
      dispatch(failure(args));
    } else {
      dispatch(failure());
    }
    return {};
  }
  return undefined;
};

export const manageFormErrors = (errors, isFormV6, compactErrorArrays = false) =>
  new Promise((resolve, reject) => {
    setTimeout(() => {
      if (Object.keys(errors).length > 0) {
        const formErrors = {};
        forOwn(errors, (value, key) => {
          if (key === 'base') {
            // eslint-disable-next-line no-param-reassign
            key = '_error';
          }
          if (Array.isArray(value) && value.length > 0) {
            if (typeof value[0] === 'string') {
              formErrors[key] = value.join(', ');
            } else {
              let newValue = value;
              if (compactErrorArrays) {
                newValue = value.filter((val) => !isEmpty(val));
              }
              formErrors[key] = newValue;
            }
          } else {
            formErrors[key] = value;
          }
        });
        if (isFormV6) {
          reject(new SubmissionError(formErrors));
        } else {
          reject(formErrors);
        }
      } else {
        resolve();
      }
    }, 1);
  });
