import { useEffect, useState, useCallback } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Draggable } from '@fullcalendar/interaction';

import { kebabCase } from 'change-case';

import get from 'lodash.get';
import groupBy from 'lodash.groupby';
import set from 'lodash.set';

import moment from 'moment';

import DutyEventModal from './duty_event_modal';
import ScrollableFullCalendar from './scrollable_full_calendar';

import { getContrastYIQ } from '../../lib/utils';

moment.updateLocale('en-nz');

function Roster(props) {
  const {
    backgroundEvents = [],
    weekendEvents = [],
    calendarEvents = [],
    roster: { id: rosterId, description, contacts, dutyTemplates },
    dutyEvents,
    setCalendarEvents,
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
    dispatchDutyEventCollectionVars,
    handleDutyEventCreate,
    handleDutyEventUpdate,
    handleDutyEventDelete,
  } = props;

  const ROSTER_HTML_ID = `roster-${rosterId}`;
  const ROSTER_CALENDAR_HTML_ID = `${ROSTER_HTML_ID}-calendar`;
  const ROSTER_POPOVERS_HTML_ID = `${ROSTER_HTML_ID}-popovers`;
  const ROSTER_DRAGGABLES_HTML_ID = `${ROSTER_HTML_ID}-duty-templates`;

  const [calendarResources, setCalendarResources] = useState([]);
  const [groupedDutyTemplates, setGroupedDutyTemplates] = useState({});
  const [dutyEventModalState, setDutyEventModalState] = useState({
    show: false,
    rosterId,
  });

  const hidePopovers = useCallback(() => {
    // during drag and drops tooltips can get stuck
    const calendarEl = document.getElementById(ROSTER_POPOVERS_HTML_ID);
    const tooltips = calendarEl.querySelectorAll('[role="tooltip"]');
    tooltips.forEach((tooltip) => {
      tooltip.remove();
    });
  }, [ROSTER_POPOVERS_HTML_ID]);

  // set calendarResources
  useEffect(() => {
    let newCalendarResources = [];
    if (contacts) {
      newCalendarResources = contacts.map((contact) => {
        const { id, fullName } = contact;
        return {
          id: kebabCase(fullName),
          title: fullName,
          extendedProps: {
            contactId: id,
          },
        };
      });
    }
    setCalendarResources(newCalendarResources);
  }, [contacts]);

  // set dutyTemplates
  useEffect(() => {
    let newGroupedDutyTemplates = [];
    if (dutyTemplates) {
      newGroupedDutyTemplates = dutyTemplates.map((dutyTemplate) => {
        const {
          id,
          position,
          name,
          short_name: shortName,
          duty_color: dutyColor,
        } = dutyTemplate;
        return {
          id,
          name,
          shortName,
          dutyColor,
          position,
        };
      });
    }
    setGroupedDutyTemplates(groupBy(newGroupedDutyTemplates, 'position'));
  }, [dutyTemplates]);

  // make dutyTemplates draggable
  useEffect(() => {
    let draggable;
    const draggableEl = document.getElementById(ROSTER_DRAGGABLES_HTML_ID);
    if (draggableEl && dutyTemplates) {
      /* eslint-disable no-new */
      draggable = new Draggable(draggableEl, {
        itemSelector: '.fc-event',
        eventData: (event) => {
          const id = parseInt(event.getAttribute('data-id'), 10);
          const title = event.getAttribute('title');
          const color = event.getAttribute('data-color');
          return {
            id,
            title,
            color,
            create: true, // this is the default, add to a dragged external event will fire the relevent callbacks
          };
        },
      });
    }
    return () => {
      if (draggable) {
        draggable.destroy();
      }
    };
  }, [ROSTER_DRAGGABLES_HTML_ID, dutyTemplates]);

  const handleDutyEventModalCancel = useCallback(() => {
    setDutyEventModalState({
      show: false,
      rosterId,
    });
  }, [rosterId]);

  const handleDutyEventModalDelete = useCallback(() => {
    const dutyEventId = get(dutyEventModalState, 'dutyEvent.id');
    const newCalendarEvents = calendarEvents.filter(
      (calendarEvent) => calendarEvent.id !== dutyEventId
    );
    setCalendarEvents(newCalendarEvents);
    setDutyEventModalState({
      show: false,
      rosterId,
    });
    handleDutyEventDelete(dutyEventId);
  }, [
    rosterId,
    calendarEvents,
    setCalendarEvents,
    dutyEventModalState,
    handleDutyEventDelete,
  ]);

  const handleDutyEventModalUpdate = useCallback(
    ({ startTime, endTime, dutyNotes }) => {
      const dutyEventId = get(dutyEventModalState, 'dutyEvent.id');
      const newCalendarEventIndex = calendarEvents.findIndex(
        (calendarEvent) => calendarEvent.id === dutyEventId
      );
      // small hack to force dismount/remount of event in the calendar to recreate the popover
      set(calendarEvents, [newCalendarEventIndex, 'id'], `${dutyEventId}-updating`);
      set(
        calendarEvents,
        [newCalendarEventIndex, 'extendedProp', 'dutyNotes'],
        dutyNotes
      );
      setCalendarEvents(calendarEvents);
      setDutyEventModalState({
        show: false,
        rosterId,
      });
      const updatedDutyEvent = {
        id: dutyEventId,
        start_time: startTime,
        end_time: endTime,
        duty_notes: dutyNotes,
      };
      handleDutyEventUpdate(updatedDutyEvent);
    },
    [
      rosterId,
      calendarEvents,
      dutyEventModalState,
      setCalendarEvents,
      handleDutyEventUpdate,
    ]
  );

  const handleEventReceive = useCallback(
    (eventInfo) => {
      const { draggedEl, event } = eventInfo;
      let { start, end } = event;
      start = start
        ? moment(start).startOf('day').utc().format()
        : moment().startOf('day').utc().format();
      // fullcalendar end datetimes are exclusive
      end = end
        ? moment(end).startOf('day').utc().format()
        : moment(start).add(1, 'day').startOf('day').utc().format();
      const dutyTemplateId = parseInt(draggedEl.getAttribute('data-id'), 10);
      const dutyTemplate = dutyTemplates.find((dt) => dt.id === dutyTemplateId);
      const {
        name: dutyTemplateName,
        short_name: shortName,
        description: dutyTemplateDescription,
        duty_color: dutyColor,
        fixed_time: fixedTime,
        contact_available: contactAvailable,
        start_time: startTime,
        end_time: endTime,
      } = dutyTemplate;
      const resourceId = get(event.getResources(), '0.id');
      const newContactId = get(
        calendarResources.find((r) => r.id === resourceId),
        'extendedProps.contactId'
      );
      const newEvent = {
        id: 0,
        allDay: true,
        resourceId,
        title: shortName,
        backgroundColor: dutyColor,
        textColor: getContrastYIQ(dutyColor),
        start,
        end,
        extendedProps: {
          description: dutyTemplateDescription,
          dutyTemplateId,
          contactId: newContactId,
          fixedTime,
          contactAvailable,
        },
      };
      setCalendarEvents((state) => [...state, newEvent]);

      const newDutyEvent = {
        duty_template_id: dutyTemplateId,
        roster_id: rosterId,
        contact_id: newContactId,
        name: dutyTemplateName,
        short_name: shortName,
        description: dutyTemplateDescription,
        duty_color: dutyColor,
        fixed_time: fixedTime,
        contact_available: contactAvailable,
        start_time: startTime,
        end_time: endTime,
        start_at: start,
        end_at: end,
      };
      handleDutyEventCreate(newDutyEvent);
    },
    [rosterId, calendarResources, setCalendarEvents, dutyTemplates, handleDutyEventCreate]
  );

  const handleEventChange = useCallback(
    (eventInfo) => {
      hidePopovers();
      const { event } = eventInfo;
      const { id, extendedProps } = event;
      const dutyEventId = parseInt(id, 10);
      let { start, end } = event;
      start = moment(start).startOf('day').utc().format();
      // fullcalendar end datetimes are exclusive
      end = moment(end).startOf('day').utc().format();
      const resourceId = get(event.getResources(), '0.id');
      const newContactId = get(
        calendarResources.find((r) => r.id === resourceId),
        'extendedProps.contactId'
      );
      const newCalendarEvents = calendarEvents.map((calendarEvent) => {
        if (calendarEvent.id === dutyEventId) {
          const updatedCalendarEvent = {
            ...calendarEvent,
            extendedProps: {
              ...extendedProps,
              contactId: newContactId,
            },
            resourceId,
            start,
            end,
          };
          return updatedCalendarEvent;
        }
        return calendarEvent;
      });
      const updatedDutyEvent = {
        id: dutyEventId,
        contact_id: newContactId,
        start_at: start,
        end_at: end,
      };
      setCalendarEvents(newCalendarEvents);
      handleDutyEventUpdate(updatedDutyEvent);
    },
    [
      calendarEvents,
      setCalendarEvents,
      calendarResources,
      handleDutyEventUpdate,
      hidePopovers,
    ]
  );

  const handleEventClick = useCallback(
    (eventInfo) => {
      hidePopovers();
      const { event } = eventInfo;
      const { id, extendedProps = {} } = event;
      if (extendedProps.skipEventClick) {
        return;
      }
      const dutyEventId = parseInt(id, 10);
      const dutyEvent = dutyEvents.find((de) => de.id === dutyEventId);
      setDutyEventModalState({
        ...dutyEventModalState,
        show: true,
        dutyEvent,
      });
    },
    [dutyEvents, dutyEventModalState, hidePopovers]
  );

  return (
    <Row id={ROSTER_HTML_ID}>
      <div id={ROSTER_POPOVERS_HTML_ID} />
      <DutyEventModal
        {...dutyEventModalState}
        handleDutyEventModalCancel={handleDutyEventModalCancel}
        handleDutyEventModalUpdate={handleDutyEventModalUpdate}
        handleDutyEventModalDelete={handleDutyEventModalDelete}
      />

      <Col>
        <p>{description}</p>
        <Row id={ROSTER_DRAGGABLES_HTML_ID}>
          <Col>
            {Object.keys(groupedDutyTemplates).map((position) => (
              <Row key={`${rosterId}-${position}`} className="justify-content-start">
                {groupedDutyTemplates[position].map((dutyTemplate) => {
                  const { id: dutyTemplateId, shortName, dutyColor, name } = dutyTemplate;
                  return (
                    <Col
                      key={`${rosterId}-${position}-${dutyTemplateId}`}
                      sm="auto"
                      style={{ width: '12.5%' }}
                    >
                      <div
                        className="p-2 mb-2 fc-event fc-h-event fc-daygrid-event"
                        title={name}
                        data-id={dutyTemplateId}
                        data-color={dutyColor}
                        style={{
                          backgroundColor: dutyColor,
                          borderColor: dutyColor,
                          cursor: 'pointer',
                        }}
                      >
                        <div className="fc-event-main">
                          <div>
                            <strong style={{ color: getContrastYIQ(dutyColor) }}>
                              {shortName}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </Col>
                  );
                })}
              </Row>
            ))}
          </Col>
        </Row>
        <hr />
        <ScrollableFullCalendar
          ROSTER_CALENDAR_HTML_ID={ROSTER_CALENDAR_HTML_ID}
          ROSTER_POPOVERS_HTML_ID={ROSTER_POPOVERS_HTML_ID}
          calendarEvents={[...calendarEvents, ...backgroundEvents, ...weekendEvents]}
          calendarResources={calendarResources}
          handleEventReceive={handleEventReceive}
          handleEventChange={handleEventChange}
          handleEventClick={handleEventClick}
          currentSettingsDutyEventCollectionStartDate={
            currentSettingsDutyEventCollectionStartDate
          }
          currentSettingsDutyEventCollectionEndDate={
            currentSettingsDutyEventCollectionEndDate
          }
          dispatchDutyEventCollectionVars={dispatchDutyEventCollectionVars}
        />
      </Col>
    </Row>
  );
}

export default Roster;
