import { Row, Form } from 'react-bootstrap';
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, change as changeFieldValue, getFormValues } from 'redux-form';
import moment from 'moment';

import clone from 'lodash.clone';
import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import omit from 'lodash.omit';
import omitBy from 'lodash.omitby';
import isNil from 'lodash.isnil';
import snakeCase from 'lodash.snakecase';
import { tagsCreate } from '../actions/tag_actions';

import DocumentField from '../components/form/document_field';
import InputField from '../components/form/input_field';
import TagField from '../components/form/tag_field';
import Title from '../components/title';
import Loader from '../components/loader';
import FormButtons from '../components/form/form_buttons';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import documentQuery from '../queries/document_query';
import documentCategoryListQuery from '../queries/document_category_list_query';
import documentUpdateMutation from '../mutations/document_update_mutation';
import documentCreateMutation from '../mutations/document_create_mutation';

import { queryReady, queriesReady, typeInput, getTaggingsNameArray } from '../lib/utils';

moment.updateLocale('en-nz');
let isInitialisedDocumentForm = false;

class DocumentForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
      document_file_extension: 'unknown',
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTagCreate = this.handleTagCreate.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.documentQuery && this.isLoaded(nextProps) && nextProps.pristine) {
      this.setState({
        document_file_extension: nextProps.documentQuery.data.document_file_name
          .split('.')
          .pop(),
      });
    }
  }

  componentWillUnmount() {
    isInitialisedDocumentForm = false;
  }

  getDocumentCategoryAncestryName(documentCategory) {
    const { rgt, lft } = documentCategory;
    return this.props.documentCategoryListQuery.data
      .filter((dc) => dc.lft <= lft && dc.rgt >= rgt)
      .sort((a, b) => a.lft - b.lft)
      .map((dc) => dc.name)
      .join(' | ');
  }

  getDocumentCategoryOptions() {
    return this.props.documentCategoryListQuery.data
      .map((documentCategory) => ({
        id: documentCategory.id,
        name: this.getDocumentCategoryAncestryName(documentCategory),
      }))
      .sort((a, b) => a.name.localeCompare(b.name));
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.documentCategoryListQuery,
      [testProps.documentQuery, true] // ignore if undefined
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    submitData.tag_list = submitData.tag_collection.map((tag) => tag.text).join(',');
    if (submitData.document) {
      submitData.uploader_id = this.props.currentContact.id;
      // eslint-disable-next-line no-param-reassign
      submitData.uploaded_at = moment().format();
    }
    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(omit(submitData, ['tag_collection'])) },
      context: { hasUpload: true },
    };
    // return
    if (this.state.updating) {
      mutation = this.props.documentUpdateMutation;
      mutationMessage = 'Document update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.documentCreateMutation;
      mutationMessage = 'Document create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/documents');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  handleTagCreate(tag) {
    this.props.tagsCreate({ value: snakeCase(tag), label: tag, type: 'Document' });
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    let fileExtension = 'unknown';
    const fileParts = file.name.split('.');
    if (fileParts.length > 1) {
      fileExtension = fileParts[fileParts.length - 1];
    }
    this.setState({
      document_file_extension: fileExtension.toLowerCase(),
    });
    this.props.changeFieldValue('DocumentForm', 'document', file);
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedDocumentForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, pristine, submitting } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <Title form updating={this.state.updating}>
            Document
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">Details</legend>
            {this.state.updating && (
              <InputField
                type="text"
                labelWidth={3}
                inputWidth={9}
                plainText
                input={{
                  name: 'current_file_name',
                  value: this.props.documentQuery.data.document_file_name,
                }}
              >
                Current File Name
              </InputField>
            )}
            <Field
              type="text"
              name="description"
              component={InputField}
              asElement="textarea"
              rows={3}
            >
              Description
            </Field>
            <Field
              type="text"
              name="tag_collection"
              component={TagField}
              suggestions={this.props.tagsCollection.filter(
                (t) => t && t.type === 'Document'
              )}
              handleTagCreate={this.handleTagCreate}
            >
              Tags
            </Field>
            <Field
              type="text"
              name="document_category_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getDocumentCategoryOptions()}
            >
              Category
            </Field>
            <Field
              name="document"
              component={DocumentField}
              formValues={this.props.formValues}
              labelWidth={3}
              inputWidth={7}
            >
              {this.state.updating ? 'Update Document' : 'Add Document'}
            </Field>
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/documents"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

DocumentForm.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  tagsCollection: PropTypes.array.isRequired,
};

const attributeBlackList = [
  '__typename',
  'documentCategory',
  'uploader',
  'document_file_name',
  'document_content_type',
  'taggings',
];

function validate() {
  return {};
}

function pickInitialValues(documentQueryInitial, updating) {
  if (!isInitialisedDocumentForm) {
    if (!updating) {
      isInitialisedDocumentForm = true;
      return {
        tag_collection: [],
      };
    } else if (queryReady(documentQueryInitial)) {
      isInitialisedDocumentForm = true;
      const editDocument = omit(
        omitBy(cloneDeep(documentQueryInitial.data), isNil),
        attributeBlackList
      );
      editDocument.tag_collection = getTaggingsNameArray(
        clone(get(documentQueryInitial, 'data.taggings', []))
      ).map((name) => ({ id: snakeCase(name), text: name }));
      return editDocument;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(props.documentQuery, !!props.params.id);
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsMutating: state.currentSettings.mutating,
    tagsCollection: state.tags.collection,
    formValues: getFormValues('DocumentForm')(state),
  };
}

export default compose(
  graphql(documentCreateMutation, {
    name: 'documentCreateMutation',
  }),
  graphql(documentUpdateMutation, {
    name: 'documentUpdateMutation',
  }),
  graphql(documentCategoryListQuery, {
    name: 'documentCategoryListQuery',
  }),
  graphql(documentQuery, {
    name: 'documentQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  connect(mapStateToProps, {
    tagsCreate,
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'DocumentForm',
    // enableReinitialize: true,
    // keepDirtyOnReinitialize: true,
    validate,
  })
)(DocumentForm);
