import { useCallback, useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import { Card, Col, Row, Button } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';

import get from 'lodash.get';
import last from 'lodash.last';

import { currentSettingsSet } from '../actions/current_setting_actions';
import { renderOverlay, renderError } from '../components/render_helpers';
import Title from '../components/title';
import ReactTable from '../components/react_table';

import { toastSuccess, toastError } from '../lib/action_helpers';
import seatConfigurationDeleteMutation from '../mutations/seat_configuration_delete_mutation';
import pageSeatConfigurationListQuery from '../queries/page_seat_configuration_list_query';

const SeatConfigurationList = () => {
  const dispatch = useDispatch();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentContact = useSelector((state) => state.currentContact);
  const [seatConfigurationDelete] = useMutation(seatConfigurationDeleteMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageSeatConfigurationListQuery, { notifyOnNetworkStatusChange: true });

  const parentColumns = [
    {
      header: 'Id',
      accessorKey: 'id',
      enableColumnFilter: false,
    },
    {
      header: 'Name',
      accessorKey: 'name',
      filterFn: 'includesString',
      headerClassName: 'w-25',
    },
    {
      header: 'Type',
      id: 'aircraftType',
      accessorFn: (row) => get(row, 'aircraftType.name', 'Unset aircraft type'),
      filterFn: 'equalsString',
      filterType: 'dropdown',
    },
  ];

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading || (pageLoading && pageNetworkStatus === NetworkStatus.refetch)
    );
  }, [pageLoading, pageNetworkStatus]);

  const handleRefetch = () => pageRefetch();

  const handleDelete = useCallback(
    async (e) => {
      const seatConfigurationId = last(e.currentTarget.id.split('-'));
      const mutationData = {
        variables: { id: seatConfigurationId },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await seatConfigurationDelete(mutationData);
        toastSuccess('SeatConfiguration delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('SeatConfiguration delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [seatConfigurationDelete, dispatch]
  );

  const getTableTools = () => {
    if (currentContact['manager?'] || currentContact['office_admin?']) {
      return {
        handleDelete,
      };
    }
    return {};
  };

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col sm="auto">
          <Title list>Seat Configurations</Title>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
            <Col sm="auto">
              <LinkContainer to="/seat_configurations/new">
                <Button size="sm" variant="primary">
                  Add a Seat Configuration
                </Button>
              </LinkContainer>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <hr />
      </Row>
      {pageData?.seatConfigurationList.length ? (
        <ReactTable
          rootName="seatConfiguration"
          parentColumns={parentColumns}
          data={pageData.seatConfigurationList}
          initialStateSorting={[{ id: 'aircraftType', desc: false }]}
          {...getTableTools()}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>No seat configurations</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default SeatConfigurationList;
