import { useEffect, useRef, useState, useCallback } from 'react';
import moment from 'moment';

import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import momentPlugin from '@fullcalendar/moment';

import renderEventContent from './booking_manager_calendar_render';
import renderEventPopover from './booking_manager_calendar_popover';
import getContactResources from './booking_manager_calendar_contact_resources';
import getAircraftResources from './booking_manager_calendar_aircraft_resources';
import getContactEvents from './booking_manager_calendar_contact_events';
import getAircraftEvents from './booking_manager_calendar_aircraft_events';
import getSunEvents from './booking_manager_calendar_sun_events';

moment.updateLocale('en-nz');

const BookingManagerCalendar = ({
  AIRCRAFT_GROUP_CALENDAR_HTML_ID,
  AIRCRAFT_GROUP_POPOVERS_HTML_ID,
  aircraftGroup,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCalendarTypeFlight,
  contactBookings,
  aircraftBookings,
  dutyEvents,
  onEditClicked,
  onDisplayDateChanged,
  resourceTypeContact,
}) => {
  const calendarRef = useRef();
  const [contactEvents, setContactEvents] = useState([]);
  const [contactResources, setContactResources] = useState([]);
  const [aircraftEvents, setAircraftEvents] = useState([]);
  const [aircraftResources, setAircraftResources] = useState([]);

  const handleDateClicked = useCallback(
    (info) => {
      if (onDisplayDateChanged) {
        const {
          dateStr,
          view: { type: viewType },
        } = info;
        if (viewType !== 'resourceTimelineDay') {
          const startCalendar = moment(dateStr).startOf('day').format();
          const endCalendar = moment(dateStr).endOf('day').format();
          onDisplayDateChanged(startCalendar, endCalendar, 'resourceTimelineDay');
        }
      }
    },
    [onDisplayDateChanged]
  );

  const handleEventClicked = useCallback(
    (info) => {
      if (onEditClicked) {
        const {
          event: {
            extendedProps: { bookingId, adminComplete, isFlight },
          },
        } = info;
        if (bookingId && !adminComplete) {
          onEditClicked(bookingId, isFlight);
        }
      }
    },
    [onEditClicked]
  );

  useEffect(() => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      calendarApi.batchRendering(() => {
        calendarApi.changeView(
          currentSettingsBookingCollectionView,
          currentSettingsBookingCollectionStartDate
        );
      });
    }
  }, [currentSettingsBookingCollectionStartDate, currentSettingsBookingCollectionView]);

  useEffect(() => {
    setContactResources(getContactResources(contactBookings, dutyEvents, aircraftGroup));
    setAircraftResources(getAircraftResources(aircraftBookings, aircraftGroup));
    const sunEvents = getSunEvents(
      currentSettingsBookingCollectionStartDate,
      currentSettingsBookingCollectionEndDate,
      currentSettingsBookingCollectionView
    );
    const newContactEvents = getContactEvents(
      contactBookings,
      dutyEvents,
      aircraftGroup,
      currentSettingsBookingCollectionView,
      currentSettingsBookingCollectionStartDate,
      currentSettingsBookingCollectionEndDate,
      currentSettingsBookingCalendarTypeFlight
    );
    setContactEvents([...newContactEvents, ...sunEvents]);

    const newAircraftEvents = getAircraftEvents(
      aircraftBookings,
      currentSettingsBookingCollectionView,
      currentSettingsBookingCalendarTypeFlight
    );
    setAircraftEvents([...newAircraftEvents, ...sunEvents]);
  }, [
    contactBookings,
    aircraftBookings,
    dutyEvents,
    aircraftGroup,
    currentSettingsBookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate,
    currentSettingsBookingCollectionView,
    currentSettingsBookingCalendarTypeFlight,
  ]);

  return (
    <div id={AIRCRAFT_GROUP_CALENDAR_HTML_ID}>
      <FullCalendar
        schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
        ref={calendarRef}
        timeZone="local"
        locale="en-nz"
        firstDay={1}
        height="auto"
        plugins={[
          interactionPlugin,
          momentPlugin,
          dayGridPlugin,
          timeGridPlugin,
          resourceTimelinePlugin,
        ]}
        initialView={currentSettingsBookingCollectionView}
        initialDate={currentSettingsBookingCollectionStartDate}
        headerToolbar={false}
        views={{
          resourceTimelineDay: {
            eventMinWidth: 15,
          },
          timeGridWeek: {
            allDaySlot: true,
            scrollTime: '06:00:00',
          },
        }}
        events={resourceTypeContact ? contactEvents : aircraftEvents}
        resources={resourceTypeContact ? contactResources : aircraftResources}
        resourceGroupField={resourceTypeContact ? 'groupId' : ''}
        scrollTime="06:00:00"
        resourceOrder="extendedProps.position"
        resourceAreaWidth="200px"
        eventContent={(info) => renderEventContent(info)}
        eventDidMount={(info) =>
          renderEventPopover(info, AIRCRAFT_GROUP_POPOVERS_HTML_ID)
        }
        eventClick={(info) => handleEventClicked(info)}
        dateClick={(info) => handleDateClicked(info)}
      />
    </div>
  );
};

export default BookingManagerCalendar;
