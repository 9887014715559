import { jwtDecode } from 'jwt-decode';
import Api from '../lib/api';
import actionTypes from '../constants';

import { storeLoad, storeReset } from './store_actions';

import { manageCatch, toastSuccess, toastError } from '../lib/action_helpers';

import { setIdToken, clearIdToken } from '../lib/local_storage';

export function authFetch() {
  return {
    type: actionTypes.AUTH_FETCH,
  };
}

export function authResetSuccess() {
  return {
    type: actionTypes.AUTH_RESET_SUCCESS,
  };
}

export function authResetFailure() {
  return {
    type: actionTypes.AUTH_RESET_FAILURE,
  };
}

export function authReset(navigate) {
  return function authResetDispatch(dispatch) {
    dispatch(authFetch());
    return Api.get('/contacts/sign_out')
      .then(() => {
        clearIdToken();
        dispatch(storeReset());
        dispatch(authResetSuccess());
        navigate('/auth/login');
        toastSuccess('Logout successful');
      })
      .catch((err) => {
        clearIdToken();
        dispatch(storeReset());
        navigate('/auth/login');
        toastError('Logout unsuccessful');
        manageCatch(dispatch, err, authResetFailure);
      });
  };
}

export function authLoadSuccess(token, decoded) {
  return {
    type: actionTypes.AUTH_LOAD_SUCCESS,
    payload: {
      token,
      contact_id: decoded.contact_id,
      tenant: decoded.tenant,
      isEmployee: decoded.isEmployee,
    },
  };
}

export function authLoadFailure() {
  return {
    type: actionTypes.AUTH_LOAD_FAILURE,
  };
}

export function authLoad(email, password) {
  return function authLoadDispatch(dispatch) {
    dispatch(authFetch());
    return Api.post('/contacts/sign_in', { contact: { email, password } })
      .then((res) => {
        try {
          const decoded = jwtDecode(res.data.token);
          setIdToken(res.data.token);
          dispatch(authLoadSuccess(res.data.token, decoded));
          dispatch(storeLoad());
          toastSuccess('Login successful');
          return true;
        } catch (e) {
          clearIdToken();
          dispatch(storeReset());
          dispatch(authLoadFailure());
          toastError('Login unsuccessful');
          return false;
        }
      })
      .catch((res) => {
        manageCatch(dispatch, res, authLoadFailure);
      });
  };
}

// export function authLoad(email, password, redirect = '/', navigate) {
//   return function authLoadDispatch(dispatch) {
//     dispatch(authFetch());
//     return Api.post('/contacts/sign_in', { contact: { email, password } })
//       .then((res) => {
//         try {
//           const decoded = jwtDecode(res.data.token);
//           setIdToken(res.data.token);
//           dispatch(authLoadSuccess(res.data.token, decoded));
//           dispatch(storeLoad());
//           navigate(redirect);
//           toastSuccess('Login successful');
//         } catch (e) {
//           clearIdToken();
//           dispatch(storeReset());
//           dispatch(authLoadFailure());
//           navigate('/auth/login');
//           toastError('Login unsuccessful');
//         }
//       })
//       .catch((res) => {
//         manageCatch(dispatch, res, authLoadFailure);
//       });
//   };
// }
