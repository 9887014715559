import sortBy from 'lodash.sortby';
// import uniqBy from 'lodash.uniqby';

import { kebabCase } from 'change-case';

// const getBookingAircraft = (bookings) => {
//   const aircraft = bookings
//     .filter((a) => a.aircraft_id)
//     .map((booking) => {
//       const {
//         aircraft: { id, registration_abbreviated: registrationAbbreviated },
//       } = booking;
//       return {
//         id,
//         registrationAbbreviated,
//       };
//     });
//   return uniqBy(aircraft, 'id');
// };

const getAircraftGroupAircraft = (aircraftGroup) => {
  const aircrafts = aircraftGroup.aircrafts
    .filter((aircraft) => aircraft.activeActivation)
    .map((aircraft) => {
      const {
        id,
        registration_abbreviated: registrationAbbreviated,
        aircraft_type_id: aircraftTypeId,
      } = aircraft;
      return {
        id,
        registrationAbbreviated,
        aircraftTypeId,
      };
    });
  return sortBy(aircrafts, ['aircraftTypeId', 'registrationAbbreviated']);
};

const getAircraftResource = ({ registrationAbbreviated }, index) => ({
  id: kebabCase(registrationAbbreviated),
  title: registrationAbbreviated,
  groupId: 'Aircraft',
  extendedProps: {
    position: index,
  },
});

const getAircraftResources = (bookings, aircraftGroup) => {
  // const aircraftResources = getBookingAircraft(bookings);
  const aircraftResources = getAircraftGroupAircraft(aircraftGroup);
  return aircraftResources.map((aircraft, index) => getAircraftResource(aircraft, index));
};

export default getAircraftResources;
