import compact from 'lodash.compact';
import difference from 'lodash.difference';
import flatten from 'lodash.flatten';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';
import uniq from 'lodash.uniq';

import { kebabCase } from 'change-case';

const withRosteredBookings = !!parseInt(process.env.WITH_ROSTERED_BOOKINGS, 10);

const getAvailableDutyEventContacts = (dutyEvents, bookings) => {
  if (!withRosteredBookings) {
    return [[], [], []];
  }
  const pilotIds = uniq(
    flatten(bookings.map((b) => compact([b.pilot_id, b.copilot_id])))
  );
  const availableDutyEvents = dutyEvents.filter((de) => de.contact_available);

  const availableContacts = sortBy(
    uniqBy(
      availableDutyEvents.map(({ contact }) => contact),
      'id'
    ),
    ['position', 'fullName']
  );

  const availableContactIds = availableContacts.map((c) => c.id);

  const unrosteredContactIds = difference(pilotIds, availableContactIds);

  const unrosteredContacts = bookings
    .filter(
      (b) =>
        unrosteredContactIds.includes(b.pilot_id) ||
        unrosteredContactIds.includes(b.copilot_id)
    )
    .map((booking) => {
      const { pilot, copilot } = booking;
      let result = [];
      if (pilot && unrosteredContactIds.includes(pilot.id)) {
        result = [...result, pilot];
      }
      if (copilot && unrosteredContactIds.includes(copilot.id)) {
        result = [...result, copilot];
      }
      return result;
    });

  const uniqUnrosteredContacts = sortBy(uniqBy(flatten(unrosteredContacts), 'id'), [
    'position',
    'fullName',
  ]);
  return [
    availableContacts.filter((c) => pilotIds.includes(c.id)),
    availableContacts.filter((c) => !pilotIds.includes(c.id)),
    uniqUnrosteredContacts,
  ];
};

const getUnavailableDutyEventContacts = (dutyEvents) => {
  if (!withRosteredBookings) {
    return [];
  }
  const unavailableDutyEvents = dutyEvents.filter((de) => !de.contact_available);
  return sortBy(
    uniqBy(
      unavailableDutyEvents.map(({ contact }) => contact),
      'id'
    ),
    ['position', 'fullName']
  );
};

const getBookingContacts = (bookings, aircraftGroup) => {
  const groupedBookings = bookings.reduce(
    (result, booking) => {
      const { pilot, copilot, employees } = booking;
      const contacts = [...employees, pilot, copilot].filter((c) => c);
      contacts.forEach((contact) => {
        if (
          (booking.calendar_type !== 'flight' &&
            aircraftGroup.name === 'Advanced Flight') ||
          // some bookings come through with copilots etc, or shared meetings
          aircraftGroup.groupPilots.find((gp) => gp.id === contact.id)
        ) {
          if (contact.roles.some((r) => r.employee)) {
            result.employees.push(contact);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (contact.roles.some((r) => r.pilot)) {
              result.otherPilots.push(contact);
            } else {
              result.nonEmployees.push(contact);
            }
          }
        } else {
          result.nonEmployees.push(contact);
        }
      });
      return result;
    },
    { employees: [], otherPilots: [], nonEmployees: [] }
  );
  return sortBy(
    [
      ...uniqBy(groupedBookings.employees, 'id'),
      ...uniqBy(groupedBookings.otherPilots, 'id'),
    ],
    ['position', 'fullName']
  );
};

const getContactResource = ({ fullName }, groupId, index) => ({
  // id: kebabCase(fullName),
  id: `${kebabCase(fullName)}-orig`,
  title: fullName,
  groupId,
  extendedProps: {
    position: index,
  },
});

const getNewContactResource = ({ fullName }, groupId, index) => ({
  id: `${kebabCase(fullName)}-test`,
  title: fullName,
  groupId,
  extendedProps: {
    position: index,
  },
});

const getContactResources = (bookings, dutyEvents, aircraftGroup) => {
  const bookingContacts = getBookingContacts(bookings, aircraftGroup);
  const bookingContactResources = bookingContacts.map((contact, index) =>
    getContactResource(contact, 'Contact', index)
  );

  const [
    availableWithFlightContacts,
    availableWithoutFlightContacts,
    unrosteredWithFlightContacts,
  ] = getAvailableDutyEventContacts(dutyEvents, bookings);
  const availableWithFlightResources = availableWithFlightContacts.map((contact, index) =>
    getNewContactResource(contact, 'Available Flying', index)
  );
  const availableWithoutFlightResources = availableWithoutFlightContacts.map(
    (contact, index) => getNewContactResource(contact, 'Available Free', index)
  );
  const unrosteredWithFlightResources = unrosteredWithFlightContacts.map(
    (contact, index) => getNewContactResource(contact, 'Unrostered Flying', index)
  );

  const unavailableDutyEventContacts = getUnavailableDutyEventContacts(dutyEvents);
  const unavailableResources = unavailableDutyEventContacts.map((contact, index) =>
    getNewContactResource(contact, 'Unavailable', index)
  );

  return [
    ...bookingContactResources,
    ...availableWithFlightResources,
    ...unrosteredWithFlightResources,
    ...availableWithoutFlightResources,
    ...unavailableResources,
  ];
};

export default getContactResources;
