// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* class needed to set time in calendar popover yellow */
.bg-yellow-important {
  background-color: yellow !important;
}

/* scroll bars show on calendar week/month view */
/* Hide scrollbar for Chrome, Safari and Opera */
.fc-custom-event-noscrollbars::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.fc-custom-event-noscrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Authoization table rotate header */
.table-header-rotated td {
  width: 40px;
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  vertical-align: middle;
  text-align: center;
}

.table-header-rotated th.row-header {
  width: auto;
}

.rotated-th {
	height: 160px ;
	position: relative ;
}
.rotated-th-label {
	bottom: 0px ;
	left: 50% ;
	position: absolute ;
	transform: rotate( -45deg ) ;
	transform-origin: center left ;
	white-space: nowrap ;
  border-bottom: 1px solid #ccc;
}
`, "",{"version":3,"sources":["webpack://./src/css/local.css"],"names":[],"mappings":"AAAA,wDAAwD;AACxD;EACE,mCAAmC;AACrC;;AAEA,iDAAiD;AACjD,gDAAgD;AAChD;EACE,aAAa;AACf;AACA,4CAA4C;AAC5C;EACE,wBAAwB,EAAE,gBAAgB;EAC1C,qBAAqB,EAAE,YAAY;AACrC;;AAEA,qCAAqC;AACrC;EACE,WAAW;EACX,6BAA6B;EAC7B,8BAA8B;EAC9B,+BAA+B;EAC/B,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;CACC,cAAc;CACd,mBAAmB;AACpB;AACA;CACC,YAAY;CACZ,UAAU;CACV,mBAAmB;CACnB,4BAA4B;CAC5B,8BAA8B;CAC9B,oBAAoB;EACnB,6BAA6B;AAC/B","sourcesContent":["/* class needed to set time in calendar popover yellow */\n.bg-yellow-important {\n  background-color: yellow !important;\n}\n\n/* scroll bars show on calendar week/month view */\n/* Hide scrollbar for Chrome, Safari and Opera */\n.fc-custom-event-noscrollbars::-webkit-scrollbar {\n  display: none;\n}\n/* Hide scrollbar for IE, Edge and Firefox */\n.fc-custom-event-noscrollbars {\n  -ms-overflow-style: none; /* IE and Edge */\n  scrollbar-width: none; /* Firefox */\n}\n\n/* Authoization table rotate header */\n.table-header-rotated td {\n  width: 40px;\n  border-top: 1px solid #dddddd;\n  border-left: 1px solid #dddddd;\n  border-right: 1px solid #dddddd;\n  vertical-align: middle;\n  text-align: center;\n}\n\n.table-header-rotated th.row-header {\n  width: auto;\n}\n\n.rotated-th {\n\theight: 160px ;\n\tposition: relative ;\n}\n.rotated-th-label {\n\tbottom: 0px ;\n\tleft: 50% ;\n\tposition: absolute ;\n\ttransform: rotate( -45deg ) ;\n\ttransform-origin: center left ;\n\twhite-space: nowrap ;\n  border-bottom: 1px solid #ccc;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
