import { useEffect, useState, useCallback } from 'react';

import { Card, Col, Row, Button, Form } from 'react-bootstrap';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useSelector, useDispatch } from 'react-redux';
import { kebabCase } from 'change-case';
import moment from 'moment';

import compact from 'lodash.compact';
import get from 'lodash.get';

import { currentSettingsSet } from '../actions/current_setting_actions';

import { renderOverlay, renderError } from '../components/render_helpers';
import Roster from '../components/roster_list/roster';
import { toastSuccess, toastError } from '../lib/action_helpers';
import { getContrastYIQ } from '../lib/utils';

import pageRosterListQuery from '../queries/page_roster_list_query';
import dutyEventCreateMutation from '../mutations/duty_event_create_mutation';
import dutyEventUpdateMutation from '../mutations/duty_event_update_mutation';
import dutyEventDeleteMutation from '../mutations/duty_event_delete_mutation';

const RosterList = () => {
  const dispatch = useDispatch();
  const [dutyEventCreate] = useMutation(dutyEventCreateMutation);
  const [dutyEventUpdate] = useMutation(dutyEventUpdateMutation);
  const [dutyEventDelete] = useMutation(dutyEventDeleteMutation);
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const currentSettingsMutating = useSelector((state) => state.currentSettings.mutating);
  const currentSettingsDutyEventCollectionStartDate = useSelector(
    ({ currentSettings }) => currentSettings.dutyEventCollectionStartDate
  );
  const currentSettingsDutyEventCollectionEndDate = useSelector(
    ({ currentSettings }) => currentSettings.dutyEventCollectionEndDate
  );

  const [activeRosterId, setActiveRosterId] = useState(0);
  const [activeRoster, setActiveRoster] = useState({});

  const [dutyEvents, setDutyEvents] = useState([]);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [backgroundEvents, setBackgroundEvents] = useState({});
  const [weekendEvents, setWeekendEvents] = useState([]);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageRosterListQuery, {
    variables: {
      startAt: currentSettingsDutyEventCollectionStartDate,
      endAt: currentSettingsDutyEventCollectionEndDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleRefetch = () => pageRefetch();

  const handleActiveRosterChange = useCallback(
    (e) => {
      const newActiveRosterId = parseInt(e.target.value, 10);
      setActiveRosterId(newActiveRosterId);
      const newActiveRoster = pageData.rosterList.find((r) => r.id === newActiveRosterId);
      setActiveRoster(newActiveRoster);
    },
    [pageData]
  );

  const dispatchDutyEventCollectionVars = useCallback(
    (calendarStart, calendarEnd) => {
      dispatch(
        currentSettingsSet({
          dutyEventCollectionStartDate: calendarStart,
          dutyEventCollectionEndDate: calendarEnd,
        })
      );
    },
    [dispatch]
  );

  const handleDutyEventCreate = useCallback(
    async (input) => {
      const mutationData = {
        variables: { input },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventCreate(mutationData);
        toastSuccess('Duty event create ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty event create failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventCreate, dispatch, pageRefetch]
  );

  const handleDutyEventUpdate = useCallback(
    async (input) => {
      const { id } = input;
      const mutationData = {
        variables: { id, input },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventUpdate(mutationData);
        toastSuccess('Duty event update ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty event update failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventUpdate, dispatch, pageRefetch]
  );

  const handleDutyEventDelete = useCallback(
    async (id) => {
      const mutationData = {
        variables: { id },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await dutyEventDelete(mutationData);
        toastSuccess('Duty Event delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Duty Event delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
        pageRefetch();
      }
    },
    [dutyEventDelete, dispatch, pageRefetch]
  );

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading ||
        (pageLoading &&
          (pageNetworkStatus === NetworkStatus.refetch ||
            pageNetworkStatus === NetworkStatus.setVariables))
    );
  }, [pageLoading, pageNetworkStatus]);

  useEffect(() => {
    if (pageData && pageData.rosterList && !activeRosterId) {
      setActiveRosterId(get(pageData, 'rosterList.0.id'));
      setActiveRoster(get(pageData, 'rosterList.0'));
    }
  }, [pageData, activeRosterId]);

  useEffect(() => {
    let newDutyEvents = [];
    if (activeRosterId && pageData?.dutyEventList) {
      newDutyEvents = pageData.dutyEventList.filter(
        (de) => de.roster_id === activeRosterId
      );
    }
    setDutyEvents(newDutyEvents);
  }, [activeRosterId, pageData]);

  useEffect(() => {
    let newCalendarEvents = [];
    if (activeRoster?.id && dutyEvents) {
      const { contacts } = activeRoster;
      newCalendarEvents = dutyEvents.map((dutyEvent) => {
        const {
          id: dutyEventId,
          short_name: shortName,
          description,
          duty_notes: dutyNotes,
          duty_color: dutyColor,
          contact_id: contactId,
          duty_template_id: dutyTemplateId,
          start_at: startAt,
          end_at: endAt,
          fixed_time: fixedTime,
          contact_available: contactAvailable,
        } = dutyEvent;
        const contact = contacts.find((c) => c.id === contactId);
        if (contact) {
          const { fullName } = contact;
          const resourceId = kebabCase(fullName);
          return {
            id: dutyEventId,
            allDay: true,
            resourceId,
            title: shortName,
            backgroundColor: dutyColor,
            textColor: getContrastYIQ(dutyColor),
            start: startAt,
            end: endAt,
            extendedProps: {
              contactId,
              dutyEventId,
              dutyTemplateId,
              fixedTime,
              contactAvailable,
              description,
              dutyNotes,
            },
          };
        }
        return undefined;
      });
    }
    setCalendarEvents(compact(newCalendarEvents));
  }, [activeRoster, dutyEvents]);

  useEffect(() => {
    let newBackgroundEvents = [];
    if (activeRoster?.id && pageData?.rosterList) {
      const rosterNames = pageData.rosterList.reduce(
        (accum, r) => ({ ...accum, [r.id]: r.name }),
        {}
      );
      const { id: rosterId, contacts } = activeRoster;
      const contactIds = contacts.map((c) => c.id);
      const filteredDutyEvents = pageData.dutyEventList.filter(
        (de) => de.roster_id !== rosterId && contactIds.includes(de.contact_id)
      );
      newBackgroundEvents = filteredDutyEvents.map((de) => {
        const {
          id: dutyEventId,
          contact_id: contactId,
          start_at: start,
          end_at: end,
          roster_id: deRosterId,
        } = de;
        const contact = contacts.find((c) => c.id === contactId);
        const { fullName } = contact;
        const resourceId = kebabCase(fullName);
        return {
          id: `background-${dutyEventId}`,
          title: rosterNames[deRosterId],
          start,
          end,
          allDay: true,
          resourceId,
          display: 'background',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          extendedProps: {
            eventType: 'RosterBlock',
            skipEventClick: true,
          },
        };
      });
    }
    setBackgroundEvents(newBackgroundEvents);
  }, [activeRoster, pageData]);

  useEffect(() => {
    const newWeekendEvents = [];
    const start = moment(currentSettingsDutyEventCollectionStartDate);
    const end = moment(currentSettingsDutyEventCollectionEndDate);
    const startOfDay = start.clone();
    while (startOfDay.isSameOrBefore(end)) {
      if (startOfDay.isoWeekday() === 6 || startOfDay.isoWeekday() === 7) {
        newWeekendEvents.push({
          backgroundColor: '#DCDCDC',
          textColor: '#000000',
          allDay: true,
          start: startOfDay.toISOString(),
          display: 'background',
          extendedProps: {
            eventType: 'RosterWeekend',
            skipEventClick: true,
          },
        });
      }
      startOfDay.add(1, 'day');
    }
    setWeekendEvents(newWeekendEvents);
  }, [
    currentSettingsDutyEventCollectionStartDate,
    currentSettingsDutyEventCollectionEndDate,
  ]);

  const renderContent = () => (
    <>
      <Row className="mt-4 mb-3">
        <Col>
          <Row className="justify-content-start g-0">
            <Col className="flex-grow-0 me-4 align-self-center">
              <h3>Rosters</h3>
            </Col>
            <Col>
              {pageData?.rosterList.length && (
                <Form.Select
                  size="lg"
                  className="d-inline-block w-25"
                  value={activeRosterId}
                  onChange={handleActiveRosterChange}
                >
                  <option key={0} value={0} aria-label="blank">
                    &nbsp;
                  </option>
                  {pageData?.rosterList.map(({ id, name }) => (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  ))}
                </Form.Select>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="justify-content-end g-0">
            <Col sm="auto">
              <Button
                className="me-2"
                size="sm"
                variant="primary"
                onClick={handleRefetch}
                disabled={pageLoading}
              >
                Refresh
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <hr />
      {activeRosterId ? (
        <Roster
          roster={activeRoster}
          dutyEvents={dutyEvents}
          backgroundEvents={backgroundEvents}
          weekendEvents={weekendEvents}
          calendarEvents={calendarEvents}
          setCalendarEvents={setCalendarEvents}
          currentSettingsDutyEventCollectionStartDate={
            currentSettingsDutyEventCollectionStartDate
          }
          currentSettingsDutyEventCollectionEndDate={
            currentSettingsDutyEventCollectionEndDate
          }
          dispatchDutyEventCollectionVars={dispatchDutyEventCollectionVars}
          handleDutyEventCreate={handleDutyEventCreate}
          handleDutyEventUpdate={handleDutyEventUpdate}
          handleDutyEventDelete={handleDutyEventDelete}
        />
      ) : (
        <Row>
          <Col>
            <Card>
              <Card.Body>Select a Roster</Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default RosterList;
