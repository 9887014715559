import { useState, useEffect } from 'react';
import moment from 'moment';

import {
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  SplitButton,
  Dropdown,
} from 'react-bootstrap';
import DateTime from 'react-datetime';

import Glyphicon from '../glyphicon';

const BookingManagerTools = ({
  currentSettingsBookingCollectionStartDate: start,
  currentSettingsBookingCollectionEndDate: end,
  currentSettingsBookingCollectionView: view,
  currentSettingsBookingCalendarTypeFlight,
  pageRefetch,
  dispatchBookingCollectionVars,
  dispatchBookingRequestedCalendarType,
  onNewClicked,
}) => {
  const [startAtDate, setStartAtDate] = useState('');

  useEffect(() => {
    if (start) {
      setStartAtDate(moment(start).format('MM/YYYY'));
    }
  }, [start]);

  const onPrevCalendarClicked = () => {
    switch (view) {
      case 'resourceTimelineDay':
        dispatchBookingCollectionVars(
          moment(start).subtract(1, 'day').format(),
          moment(end).subtract(1, 'day').format(),
          view
        );
        break;
      case 'timeGridWeek':
        dispatchBookingCollectionVars(
          moment(start).subtract(1, 'week').format(),
          moment(end).subtract(1, 'week').format(),
          view
        );
        break;
      case 'dayGridMonth':
        dispatchBookingCollectionVars(
          moment(start).subtract(1, 'month').format(),
          moment(end).subtract(1, 'month').format(),
          view
        );
        break;
      default:
        break;
    }
  };

  const onNextCalendarClicked = () => {
    switch (view) {
      case 'resourceTimelineDay':
        dispatchBookingCollectionVars(
          moment(start).add(1, 'day').format(),
          moment(end).add(1, 'day').format(),
          view
        );
        break;
      case 'timeGridWeek':
        dispatchBookingCollectionVars(
          moment(start).add(1, 'week').format(),
          moment(end).add(1, 'week').format(),
          view
        );
        break;
      case 'dayGridMonth':
        dispatchBookingCollectionVars(
          moment(start).add(1, 'month').format(),
          moment(end).add(1, 'month').format(),
          view
        );
        break;
      default:
        break;
    }
  };

  const onTodayCalendarClicked = () => {
    dispatchBookingCollectionVars(
      moment().startOf('day').format(),
      moment().endOf('day').format(),
      'resourceTimelineDay'
    );
  };

  const onDayCalendarClicked = () => {
    switch (view) {
      case 'timeGridWeek':
      case 'dayGridMonth':
        dispatchBookingCollectionVars(
          moment(start).startOf('day').format(),
          moment(start).endOf('day').format(),
          'resourceTimelineDay'
        );
        break;
      default:
        break;
    }
  };

  const onWeekCalendarClicked = () => {
    switch (view) {
      case 'resourceTimelineDay':
      case 'dayGridMonth':
        dispatchBookingCollectionVars(
          moment(start).startOf('isoWeek').format(),
          moment(start).endOf('isoWeek').format(),
          'timeGridWeek'
        );
        break;
      default:
        break;
    }
  };

  const onMonthCalendarClicked = () => {
    switch (view) {
      case 'resourceTimelineDay':
      case 'timeGridWeek':
        dispatchBookingCollectionVars(
          moment(start).startOf('month').format(),
          moment(start).endOf('month').format(),
          'dayGridMonth'
        );
        break;
      default:
        break;
    }
  };

  const onMonthPickerChanged = (date) => {
    if (moment.isMoment(date)) {
      setStartAtDate(date.format('MM/YYYY'));
      dispatchBookingCollectionVars(
        date.startOf('month').format(),
        date.endOf('month').format(),
        'dayGridMonth'
      );
    }
  };

  const handleDropdownNewSelect = (key) => {
    dispatchBookingRequestedCalendarType(key);
    onNewClicked(key === currentSettingsBookingCalendarTypeFlight);
  };

  const handleDropdownNewClick = () => {
    handleDropdownNewSelect(currentSettingsBookingCalendarTypeFlight);
  };

  return (
    <>
      <Col sm="auto">
        <ButtonToolbar aria-label="Toolbar with button groups">
          <ButtonGroup size="sm" className="me-1">
            <Button variant="info" onClick={() => pageRefetch()} className="p-0">
              <Glyphicon glyph="repeat" />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="sm" className="me-1">
            <Button variant="info" onClick={onPrevCalendarClicked} className="p-0">
              <Glyphicon glyph="chevron-left" />
            </Button>
            <Button variant="info" onClick={onNextCalendarClicked} className="p-0">
              <Glyphicon glyph="chevron-right" />
            </Button>
          </ButtonGroup>
          <ButtonGroup size="sm" className="me-1">
            <Button variant="info" onClick={onTodayCalendarClicked}>
              today
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Col>
      <Col sm="auto" className="me-1 align-self-center">
        <DateTime
          value={startAtDate}
          dateFormat="MM/YYYY"
          timeFormat={false}
          closeOnSelect
          initialViewMode="months"
          onChange={onMonthPickerChanged}
          inputProps={{ style: { width: '100px', height: '27px' } }}
        />
      </Col>
      <Col>
        <Row className="justify-content-end flex-nowrap g-0">
          <Col sm="auto">
            <ButtonToolbar aria-label="Toolbar with button groups">
              <ButtonGroup size="sm" className="me-1">
                <Button variant="info" onClick={onDayCalendarClicked}>
                  day
                </Button>
                <Button variant="info" onClick={onWeekCalendarClicked}>
                  week
                </Button>
                <Button variant="info" onClick={onMonthCalendarClicked}>
                  month
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
          <Col sm="auto" className="align-self-center">
            <SplitButton
              align="end"
              size="sm"
              id="booking-manager-add-new-booking"
              variant="primary"
              title="New"
              onClick={handleDropdownNewClick}
              onSelect={handleDropdownNewSelect}
            >
              <Dropdown.Item eventKey="flight">Flight</Dropdown.Item>
              <Dropdown.Item eventKey="meeting">Meeting</Dropdown.Item>
              <Dropdown.Item eventKey="leave">Leave</Dropdown.Item>
              <Dropdown.Item eventKey="rostered">Rostered Off</Dropdown.Item>
              <Dropdown.Item eventKey="maintenance">Maintenance</Dropdown.Item>
              <Dropdown.Item eventKey="banner">Banner</Dropdown.Item>
            </SplitButton>
          </Col>
        </Row>
      </Col>
    </>
  );
};

export default BookingManagerTools;
