import { Row, Form } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';

import { reduxForm, Field, formValueSelector } from 'redux-form';

import InputField from '../components/form/input_field';
import CheckboxInputField from '../components/form/checkbox_input_field';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';
import Loader from '../components/loader';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import locationMergeMutation from '../mutations/location_merge_mutation';
import locationListQuery from '../queries/location_min_list_query';

import { queriesReady, typeInput } from '../lib/utils';

const formSelector = formValueSelector('LocationMerge');

class LocationMerge extends Component {
  constructor(props) {
    super(props);
    this._handleSubmit = this._handleSubmit.bind(this);
  }

  _handleSubmit(data) {
    this.props.mutationSet(true);
    const mutationMessage = 'Location merge';
    const mutationData = { variables: { input: typeInput(data) } };
    return this.props
      .locationMergeMutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(`/locations/${data.to_location_id}/edit`);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  _renderDescription() {
    return (
      <div>
        <p>To merge locations, select the FROM location and the TO location</p>
        <p>The following will happen when you click merge</p>
        <ul>
          <li>
            <strong>Fuel Bowsers: </strong>
            From and To Location fuel bowsers (if any) will be compared. If an identical
            bowser is found in the To Location, all From Location fuel bowser fills will
            be transferred to that fuel bowser. If no identical fuel bowser is found, the
            From Location fuel bowser will be transferred to the To Location
          </li>
          <li>
            <strong>Names Pilots: </strong>
            From and To Location names pilots (if any) will be compared. If an identical
            names pilot is not found in the To Location the pilot will be moved across to
            the To Location
          </li>
          <li>
            <strong>Location Services: </strong>
            From and To Location services (if any) will be compared. If an identical
            service is not found in the To Location the service will be moved across to
            the To Location
          </li>
          <li>
            <strong>Location Images: </strong>
            All From Location images will be moved across to the To Location
          </li>
          <li>
            <strong>Start and End Flight Segments: </strong>
            All From Location start and end flight segments will be moved across to the To
            Location. Each flight segment and associated booking will be resaved to update
            descriptions and summaries
          </li>
        </ul>
        <p>
          The following items will not be merged. They will retain their current To
          Location values
        </p>
        <p>
          If you wish to retain any of the From Location values for the fields below, you
          should make a note of them now.
        </p>
        <p>
          You should edit the To Location record after this merge to ensure it is accurate
        </p>
        <ul>
          <li>Type</li>
          <li>Lighting</li>
          <li>Surface</li>
          <li>Status</li>
          <li>Availability</li>
          <li>Manager</li>
          <li>Short, Long, Shorthand and GPS names</li>
          <li>Landing Fee</li>
          <li>PAL Frequency</li>
          <li>Sensitive Location & Nature</li>
          <li>Approach & Departure Procedures, Hazards & Notes, Fuel Notes</li>
          <li>Named Pilots Only</li>
          <li>Latitude and Longitude</li>
        </ul>
        <p>&nbsp;</p>
      </div>
    );
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    props = props || this.props;
    return !queriesReady(props.locationListQuery);
  }

  _renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
  }

  _renderData() {
    if (this.isLoaded()) {
      const { handleSubmit, pristine, submitting } = this.props;

      return (
        <div className="pt-4 pe-4">
          <Form onSubmit={handleSubmit(this._handleSubmit)}>
            <Title form noNew>
              Merge Locations
            </Title>
            {this._renderDescription()}
            <fieldset className="border rounded-3 p-3">
              <legend className="float-none w-auto px-3 fs-6">Merge Details</legend>
              <Field
                type="text"
                name="from_location_id"
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.props.locationListQuery.data.map((model) => ({
                  id: model.id,
                  name: `${model.fullName} (${model.id})`,
                }))}
              >
                From Location
              </Field>
              <Field
                type="text"
                name="to_location_id"
                inputWidth={3}
                component={InputField}
                asElement="select"
                selectOptions={this.props.locationListQuery.data.map((model) => ({
                  id: model.id,
                  name: `${model.fullName} (${model.id})`,
                }))}
              >
                To Location
              </Field>
              <Field
                name="delete"
                component={CheckboxInputField}
                inputOffset={3}
                inputWidth={3}
              >
                Delete From Location after Merge
              </Field>
            </fieldset>
            <Row>
              <FormButtons
                submitting={submitting}
                pristine={pristine}
                cancelLink="/locations"
              />
            </Row>
          </Form>
        </div>
      );
    }
  }

  render() {
    return (
      <>
        {this._renderOverlay()}
        {this._renderData()}
      </>
    );
  }
}

function validate(values) {
  return {};
}

function mapStateToProps(state, props) {
  return {
    initialValues: { delete: false },
    currentSettingsMutating: state.currentSettings.mutating,
    formValues: formSelector(state, 'from_location_id', 'to_location_id', 'delete'),
  };
}

export default compose(
  graphql(locationMergeMutation, {
    name: 'locationMergeMutation',
  }),
  graphql(locationListQuery, {
    name: 'locationListQuery',
  }),
  connect(mapStateToProps, { mutationSuccess, mutationFailure, mutationSet }),
  reduxForm({
    form: 'LocationMerge',
    validate,
  })
)(LocationMerge);
