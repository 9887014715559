import { Modal, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';

class FlightSegmentLocationCriticalModal extends Component {
  constructor(props) {
    super(props);

    this.handleCriticalLocationCancel = this.handleCriticalLocationCancel.bind(this);
    this.handleCriticalStartLocationSubmit =
      this.handleCriticalStartLocationSubmit.bind(this);
    this.handleCriticalEndLocationSubmit =
      this.handleCriticalEndLocationSubmit.bind(this);
  }

  handleCriticalLocationCancel() {
    if (this.props.handleCriticalLocationCancel) {
      this.props.handleCriticalLocationCancel();
    }
  }

  handleCriticalStartLocationSubmit() {
    if (this.props.handleCriticalLocationSubmit) {
      this.props.handleCriticalLocationSubmit(true);
    }
  }

  handleCriticalEndLocationSubmit() {
    if (this.props.handleCriticalLocationSubmit) {
      this.props.handleCriticalLocationSubmit(false);
    }
  }

  render() {
    const { show, index } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${index}-critical-location-modal`}
        show={show}
        onHide={this.handleCriticalLocationCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>Toggle Critical Start & End Times</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                type="button"
                variant="danger"
                onClick={this.handleCriticalLocationCancel}
              >
                Cancel
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
          <ButtonToolbar>
            <ButtonGroup>
              <Button
                type="button"
                variant="primary"
                onClick={this.handleCriticalStartLocationSubmit}
              >
                Toggle Start
              </Button>
              <Button
                type="button"
                variant="primary"
                onClick={this.handleCriticalEndLocationSubmit}
              >
                Toggle End
              </Button>
            </ButtonGroup>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default FlightSegmentLocationCriticalModal;
