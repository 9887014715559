/* eslint-disable import/prefer-default-export */
import { getMainDefinition } from '@apollo/client/utilities';

import moment from 'moment';

import get from 'lodash.get';
import intersectionWith from 'lodash.intersectionwith';
import isEqual from 'lodash.isequal';
import isNil from 'lodash.isnil';
import omitBy from 'lodash.omitby';
import omit from 'lodash.omit';
import uniqWith from 'lodash.uniqwith';
import values from 'lodash.values';

import mutationSubscription from '../subscriptions/mutation_subscription';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftQuery from '../queries/aircraft_query';
import aircraftCandidateHobbListQuery from '../queries/aircraft_candidate_hobb_list_query';
import aircraftConfigurationListQuery from '../queries/aircraft_configuration_list_query';
import aircraftConfigurationQuery from '../queries/aircraft_configuration_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import aircraftEngineQuery from '../queries/aircraft_engine_query';
import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import aircraftTypeQuery from '../queries/aircraft_type_query';
import bookingQuery from '../queries/booking_query';
import bookingListQuery from '../queries/booking_list_query';
import bookingAdminListQuery from '../queries/booking_admin_list_query';
import checkListQuery from '../queries/check_list_query';
import checkQuery from '../queries/check_query';
import checkTypeListQuery from '../queries/check_type_list_query';
import checkTypeQuery from '../queries/check_type_query';
import checkCategoryListQuery from '../queries/check_category_list_query';
import checkCategoryQuery from '../queries/check_category_query';
import contactListQuery from '../queries/contact_list_query';
import contactQuery from '../queries/contact_query';
import documentListQuery from '../queries/document_list_query';
import documentQuery from '../queries/document_query';
import dutyEventListQuery from '../queries/duty_event_list_query';
import dutyEventQuery from '../queries/duty_event_query';
import holdConfigurationListQuery from '../queries/hold_configuration_list_query';
import holdConfigurationQuery from '../queries/hold_configuration_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import flightTypeQuery from '../queries/flight_type_query';
import flightTypeCategoryListQuery from '../queries/flight_type_category_list_query';
import flightTypeCategoryQuery from '../queries/flight_type_category_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import fuelTankerQuery from '../queries/fuel_tanker_query';
import fuelTypeListQuery from '../queries/fuel_type_list_query';
import fuelTypeQuery from '../queries/fuel_type_query';
import locationListQuery from '../queries/location_min_list_query';
import locationQuery from '../queries/location_query';
import otherAssetListQuery from '../queries/other_asset_list_query';
import otherAssetQuery from '../queries/other_asset_query';
import roleListQuery from '../queries/role_list_query';
import roleQuery from '../queries/role_query';
import rosterListQuery from '../queries/roster_list_query';
import rosterQuery from '../queries/roster_query';
import wbLimitListQuery from '../queries/wb_limit_list_query';
import wbLimitQuery from '../queries/wb_limit_query';
import seatConfigurationListQuery from '../queries/seat_configuration_list_query';
import seatConfigurationQuery from '../queries/seat_configuration_query';
import tankConfigurationListQuery from '../queries/tank_configuration_list_query';
import tankConfigurationQuery from '../queries/tank_configuration_query';

import bookingMinimumFragment from '../fragments/booking_minimum_type_base_fragment';
import dutyEventFragment from '../fragments/duty_event_type_base_fragment';

import { getDateRanges } from './utils';

import { store } from '../store';
import apolloClient from './apollo_client';

const getQueries = (recordType) => {
  switch (recordType) {
    case 'aircraft':
      return [aircraftQuery, aircraftListQuery];
    case 'aircraftConfiguration':
      return [aircraftConfigurationQuery, aircraftConfigurationListQuery];
    case 'aircraftEngine':
      return [aircraftEngineQuery, aircraftEngineListQuery];
    case 'aircraftType':
      return [aircraftTypeQuery, aircraftTypeListQuery];
    case 'check':
      return [checkQuery, checkListQuery];
    case 'checkType':
      return [checkTypeQuery, checkTypeListQuery];
    case 'checkCategory':
      return [checkCategoryQuery, checkCategoryListQuery];
    case 'contact':
      return [contactQuery, contactListQuery];
    case 'document':
      return [documentQuery, documentListQuery];
    case 'dutyEvent':
      return [dutyEventQuery, dutyEventListQuery];
    case 'holdConfiguration':
      return [holdConfigurationQuery, holdConfigurationListQuery];
    case 'flightType':
      return [flightTypeQuery, flightTypeListQuery];
    case 'flightTypeCategory':
      return [flightTypeCategoryQuery, flightTypeCategoryListQuery];
    case 'fuelTanker':
      return [fuelTankerQuery, fuelTankerListQuery];
    case 'fuelType':
      return [fuelTypeQuery, fuelTypeListQuery];
    case 'location':
      return [locationQuery, locationListQuery];
    case 'otherAsset':
      return [otherAssetQuery, otherAssetListQuery];
    case 'role':
      return [roleQuery, roleListQuery];
    case 'roster':
      return [rosterQuery, rosterListQuery];
    case 'wbLimit':
      return [wbLimitQuery, wbLimitListQuery];
    case 'seatConfiguration':
      return [seatConfigurationQuery, seatConfigurationListQuery];
    case 'tankConfiguration':
      return [tankConfigurationQuery, tankConfigurationListQuery];
    default:
      return [undefined, undefined];
  }
};

const processQueries = ({
  recordId,
  recordType,
  mutationType,
  argGetQuery,
  argListQuery,
  listVariables = [{}],
  forceFetch = false,
}) => {
  const [defaultGetQuery, defaultListQuery] = getQueries(recordType);
  const getQuery = argGetQuery || defaultGetQuery;
  const listQuery = argListQuery || defaultListQuery;
  let recordQuery;
  if (getQuery && mutationType !== 'DELETE') {
    recordQuery = apolloClient.query({
      query: getQuery,
      variables: { id: recordId },
      fetchPolicy: 'network-only',
    });
  } else {
    recordQuery = Promise.resolve({});
  }
  recordQuery.then(() => {
    let collectionQuery = Promise.resolve([]);
    if (listQuery) {
      const {
        name: { value: listQueryName },
      } = getMainDefinition(listQuery);
      if (forceFetch || mutationType !== 'UPDATE') {
        const collectionPromises = listVariables.map((listVariable) => {
          const queryVariables = {
            query: listQuery,
            variables: omitBy(listVariable, isNil),
            fetchPolicy: 'network-only',
          };
          let collection;
          try {
            collection = apolloClient.readQuery(omit(queryVariables, ['fetchPolicy']));
          } catch (error) {
            collection = undefined;
          }
          if (collection) {
            if (forceFetch || mutationType === 'CREATE') {
              return apolloClient.query(queryVariables);
            }
            if (mutationType === 'DELETE') {
              const newCollection = collection.data // must stop renaming query results
                ? [...collection.data]
                : [...collection[listQueryName]];
              const index = newCollection.findIndex((record) => record.id === recordId);
              if (index > -1) {
                return apolloClient.query(queryVariables);
              }
              return Promise.resolve([]);
            }
          }
          return Promise.resolve([]);
        });
        collectionQuery = Promise.all(collectionPromises);
      }
    }
    collectionQuery.then(() => {
      if (window.$NODE_ENV === 'development') {
        console.log(
          'Subscription result',
          JSON.stringify({ mutationType, recordType, listVariables }, undefined, 2)
        );
      }
    });
  });
};

const processBookingQueries = ({
  recordId,
  recordType,
  mutationType,
  payload: {
    start_at: startAt,
    end_at: endAt,
    prev_start_at: prevStartAt,
    prev_end_at: prevEndAt,
    audit_created_at: auditCreatedAt,
    admin_completed_at: adminCompletedAt,
    hobb_record_id: hobbRecordId,
  },
}) => {
  const oldFragment = apolloClient.readFragment({
    id: `BookingType:${recordId}`,
    fragment: bookingMinimumFragment,
    fragmentName: 'BookingMinimumTypeBase',
  });
  if (oldFragment || mutationType === 'CREATE') {
    const {
      start_at: oldStartAt,
      end_at: oldEndAt,
      admin_completed_at: oldAdminCompletedAt,
    } = oldFragment || {};
    let listVariables = [{}];
    const oldChanged = !!(
      oldStartAt &&
      oldEndAt &&
      !(moment(oldStartAt).isSame(startAt) && moment(oldEndAt).isSame(endAt))
    );
    const prevChanged = !!(
      prevStartAt &&
      prevEndAt &&
      !(moment(prevStartAt).isSame(startAt) && moment(prevEndAt).isSame(endAt))
    );
    const dateChanged = oldChanged || prevChanged;
    if (dateChanged || mutationType !== 'UPDATE') {
      const candidateVariables = [
        ...getDateRanges(startAt, endAt),
        ...(oldChanged ? getDateRanges(oldStartAt, oldEndAt) : []),
        ...(prevChanged ? getDateRanges(prevStartAt, prevEndAt) : []),
      ];
      const candidateListVariables = uniqWith(candidateVariables, isEqual);
      const listQuery = bookingListQuery;
      const {
        name: { value: listQueryName },
      } = getMainDefinition(listQuery);
      const queries = get(apolloClient, 'cache.data.data.ROOT_QUERY', {});
      const currentListVariables = Object.keys(queries)
        // eslint-disable-next-line arrow-body-style
        .filter((query) => {
          return query.substring(0, listQueryName.length + 1) === `${listQueryName}(`;
        })
        .map((query) => {
          const variablesMatch = query.match(/\((.*)\)/);
          return JSON.parse(variablesMatch[1]);
        });
      listVariables = intersectionWith(
        currentListVariables,
        candidateListVariables,
        isEqual
      );
    }
    processQueries({
      recordId,
      recordType,
      mutationType,
      listVariables,
      forceFetch: dateChanged,
      argGetQuery: bookingQuery,
      argListQuery: bookingListQuery,
    });
    if (auditCreatedAt && hobbRecordId) {
      processQueries({
        recordId,
        recordType: 'aircraftCandidateHobbs',
        mutationType,
        forceFetch: true,
        argListQuery: aircraftCandidateHobbListQuery,
      });
    }
    // check are these two queries actually running
    if (auditCreatedAt && !(adminCompletedAt && oldAdminCompletedAt)) {
      const state = store.getState();
      const {
        currentSettings: { reportStart, reportEnd },
      } = state;
      if (reportStart && reportEnd) {
        processQueries({
          recordId,
          recordType: 'bookingAdmin',
          mutationType,
          forceFetch: true,
          argListQuery: bookingAdminListQuery,
          listVariables: [{ start_at: reportStart, end_at: reportEnd }],
        });
      }
    }
  }
};

const processContactQueries = ({ recordId, recordType, mutationType }) => {
  const [, listQuery] = getQueries(recordType);
  const {
    name: { value: listQueryName },
  } = getMainDefinition(listQuery);
  const queries = get(apolloClient, 'cache.data.data.ROOT_QUERY', {});
  const listVariables = Object.keys(queries)
    // eslint-disable-next-line arrow-body-style
    .filter((query) => {
      return query.substring(0, listQueryName.length + 1) === `${listQueryName}(`;
    })
    .map((query) => {
      const variablesMatch = query.match(/\((.*)\)/);
      return JSON.parse(variablesMatch[1]);
    });
  processQueries({
    recordId,
    recordType,
    mutationType,
    listVariables,
  });
};

const processDutyEventQueries = ({
  recordId,
  recordType,
  mutationType,
  payload: {
    start_at: startAt,
    end_at: endAt,
    prev_start_at: prevStartAt,
    prev_end_at: prevEndAt,
  },
}) => {
  const currentFragment = apolloClient.readFragment({
    id: `DutyEventType:${recordId}`,
    fragment: dutyEventFragment,
    fragmentName: 'DutyEventTypeBase',
  });
  const { start_at: currentStartAt, end_at: currentEndAt } = currentFragment || {};
  let listVariables = [{}];
  let currentDatesChanged = false;
  let prevDatesChanged = false;
  if (startAt && endAt) {
    if (currentStartAt && currentEndAt) {
      currentDatesChanged = !(
        moment(currentStartAt).isSame(startAt) && moment(currentEndAt).isSame(endAt)
      );
    }
    if (prevStartAt && prevEndAt) {
      prevDatesChanged = !(
        moment(prevStartAt).isSame(startAt) && moment(prevEndAt).isSame(endAt)
      );
    }
  }
  const recordChanged = currentDatesChanged || prevDatesChanged;
  if (recordChanged || mutationType !== 'UPDATE') {
    const candidateVariables = [
      ...(startAt && endAt
        ? getDateRanges(startAt, moment(endAt).subtract(1, 'day').endOf('day').format())
        : []),
      ...(currentDatesChanged
        ? getDateRanges(
            currentStartAt,
            moment(currentEndAt).subtract(1, 'day').endOf('day').format()
          )
        : []),
      ...(prevDatesChanged
        ? getDateRanges(
            prevStartAt,
            moment(prevEndAt).subtract(1, 'day').endOf('day').format()
          )
        : []),
    ];
    const candidateListVariables = uniqWith(candidateVariables, isEqual);
    const listQuery = dutyEventListQuery;
    const {
      name: { value: listQueryName },
    } = getMainDefinition(listQuery);
    const queries = get(apolloClient, 'cache.data.data.ROOT_QUERY', {});
    const currentListVariables = Object.keys(queries)
      // eslint-disable-next-line arrow-body-style
      .filter((query) => {
        return query.substring(0, listQueryName.length + 1) === `${listQueryName}(`;
      })
      .map((query) => {
        const variablesMatch = query.match(/\((.*)\)/);
        return JSON.parse(variablesMatch[1]);
      });
    listVariables = intersectionWith(
      currentListVariables,
      candidateListVariables,
      // variables are currently different cases (start_at/startAt) use values for comparison
      (a, b) => isEqual(values(a).sort(), values(b).sort())
    );
  }
  processQueries({
    recordId,
    recordType,
    mutationType,
    listVariables,
    forceFetch: recordChanged,
    argGetQuery: dutyEventQuery,
    argListQuery: dutyEventListQuery,
  });
  // }
};

const processMutationSubscription = (mutation) => {
  if (window.$NODE_ENV === 'development') {
    console.log('Subscription payload', JSON.stringify(mutation, undefined, 2));
  }
  const {
    data: {
      mutation: { recordId, recordType, mutationType, payload },
    },
  } = mutation;
  if (recordType === 'initialBooking' && payload) {
    processBookingQueries({
      recordId,
      recordType,
      mutationType,
      payload,
    });
  } else if (recordType === 'contact') {
    processContactQueries({
      recordId,
      recordType,
      mutationType,
    });
  } else if (recordType === 'dutyEvent') {
    processDutyEventQueries({
      recordId,
      recordType,
      mutationType,
      payload,
    });
  } else {
    processQueries({
      recordId,
      recordType,
      mutationType,
    });
  }
};

let subscribedMutationObservable;

const subscribeMutationObservable = () => {
  if (!subscribedMutationObservable) {
    const mutationObservable = apolloClient.subscribe({
      query: mutationSubscription,
      variables: {},
      fetchPolicy: 'no-cache',
    });
    subscribedMutationObservable = mutationObservable.subscribe({
      next(mutation) {
        processMutationSubscription(mutation);
      },
      error(error) {
        console.log('mutationSubscriptionError: ', error);
      },
      complete() {
        console.log('mutationSubscriptionFinished');
      },
    });
  }
};

const unsubscribeMutationObservable = () => {
  if (subscribedMutationObservable) {
    subscribedMutationObservable.unsubscribe();
  }
  subscribedMutationObservable = null;
};

export { subscribeMutationObservable, unsubscribeMutationObservable };

// export default processMutationSubscription;
