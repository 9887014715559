import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { reduxForm, Field, change as changeFieldValue, getFormValues } from 'redux-form';

import { Row, Form } from 'react-bootstrap';

import cloneDeep from 'lodash.clonedeep';
import isNil from 'lodash.isnil';
import get from 'lodash.get';
import has from 'lodash.has';
import omit from 'lodash.omit';
import omitBy from 'lodash.omitby';

import Loader from '../components/loader';
import InputField from '../components/form/input_field';
import Title from '../components/title';
import FormButtons from '../components/form/form_buttons';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import seatConfigurationListQuery from '../queries/seat_configuration_list_query';
import holdConfigurationListQuery from '../queries/hold_configuration_list_query';
import tankConfigurationListQuery from '../queries/tank_configuration_list_query';
import externalLoadPointListQuery from '../queries/external_load_point_list_query';
import wbLimitListQuery from '../queries/wb_limit_list_query';
import aircraftConfigurationQuery from '../queries/aircraft_configuration_query';
import aircraftConfigurationUpdateMutation from '../mutations/aircraft_configuration_update_mutation';
import aircraftConfigurationCreateMutation from '../mutations/aircraft_configuration_create_mutation';

import { queriesReady, queryReady, typeInput } from '../lib/utils';

let isInitialisedAircraftConfigurationForm = false;

class AircraftConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      has(this.props, 'formValues.aircraft_type_id') &&
      get(nextProps, 'formValues.aircraft_type_id') !==
        get(this.props, 'formValues.aircraft_type_id')
    ) {
      this.props.changeFieldValue(this.props.form, 'external_load_point_id', '');
      this.props.changeFieldValue(this.props.form, 'wb_lateral_limit_id', '');
      this.props.changeFieldValue(this.props.form, 'wb_longitudinal_limit_id', '');
      this.props.changeFieldValue(this.props.form, 'wb_external_lateral_limit_id', '');
      this.props.changeFieldValue(
        this.props.form,
        'wb_external_longitudinal_limit_id',
        ''
      );
      this.props.changeFieldValue(this.props.form, 'seat_configuration_id', '');
      this.props.changeFieldValue(this.props.form, 'hold_configuration_id', '');
      this.props.changeFieldValue(this.props.form, 'tank_configuration_id', '');
    }
  }

  componentWillUnmount() {
    isInitialisedAircraftConfigurationForm = false;
  }

  onSubmit(data) {
    this.props.mutationSet(true);
    const submitData = cloneDeep(data);
    let mutation;
    let mutationMessage;
    const mutationData = {
      variables: { input: typeInput(submitData) },
    };
    if (this.state.updating) {
      mutation = this.props.aircraftConfigurationUpdateMutation;
      mutationMessage = 'Aircraft Configuration update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.aircraftConfigurationCreateMutation;
      mutationMessage = 'Aircraft Configuration create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate('/aircraft_configurations');
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftTypeListQuery,
      testProps.wbLimitListQuery,
      testProps.seatConfigurationListQuery,
      testProps.holdConfigurationListQuery,
      testProps.tankConfigurationListQuery,
      testProps.externalLoadPointListQuery,
      [testProps.aircraftConfigurationQuery, true]
    );
  }

  getSelectableWbLimits(limitType) {
    if (this.props.formValues.aircraft_type_id) {
      return this.props.wbLimitListQuery.data
        .filter(
          (m) =>
            m.aircraft_type_id === parseInt(this.props.formValues.aircraft_type_id, 10) &&
            m.limit_type === limitType
        )
        .map((m) => ({
          id: m.id,
          name: m.name,
        }));
    }
    return [];
  }

  getSelectableSeatConfigurations() {
    if (this.props.formValues.aircraft_type_id) {
      return this.props.seatConfigurationListQuery.data
        .filter(
          (m) =>
            m.aircraft_type_id === parseInt(this.props.formValues.aircraft_type_id, 10)
        )
        .map((m) => ({
          id: m.id,
          name: m.name,
        }));
    }
    return [];
  }

  getSelectableHoldConfigurations() {
    if (this.props.formValues.aircraft_type_id) {
      return this.props.holdConfigurationListQuery.data
        .filter(
          (m) =>
            m.aircraft_type_id === parseInt(this.props.formValues.aircraft_type_id, 10)
        )
        .map((m) => ({
          id: m.id,
          name: m.name,
        }));
    }
    return [];
  }

  getSelectableTankConfigurations() {
    if (this.props.formValues.aircraft_type_id) {
      return this.props.tankConfigurationListQuery.data
        .filter(
          (m) =>
            m.aircraft_type_id === parseInt(this.props.formValues.aircraft_type_id, 10)
        )
        .map((m) => ({
          id: m.id,
          name: m.name,
        }));
    }
    return [];
  }

  getSelectableExternalLoadPoints() {
    if (this.props.formValues.aircraft_type_id) {
      return this.props.externalLoadPointListQuery.data
        .filter(
          (m) =>
            m.aircraft_type_id === parseInt(this.props.formValues.aircraft_type_id, 10)
        )
        .map((m) => ({
          id: m.id,
          name: m.name,
        }));
    }
    return [];
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (
      isInitialisedAircraftConfigurationForm &&
      this.isLoaded() &&
      get(this.props, 'formValues') &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const {
        handleSubmit,
        pristine,
        submitting,
        currentSettingsWbLimitLimitTypeLateral,
        currentSettingsWbLimitLimitTypeLongitudinal,
      } = this.props;
      return (
        <Form onSubmit={handleSubmit(this.onSubmit)}>
          <Title form updating={this.state.updating}>
            Aircraft Configuration
          </Title>
          <fieldset className="border rounded-3 p-3">
            <legend className="float-none w-auto px-3 fs-6">
              Aircraft Configuration Details
            </legend>
            <Field
              type="text"
              name="name"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
            >
              Name
            </Field>
            <Field
              type="text"
              name="description"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="textarea"
              rows={3}
            >
              Description
            </Field>
            <Field
              type="text"
              name="aircraft_type_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.props.aircraftTypeListQuery.data.map((model) => ({
                id: model.id,
                name: model.name,
              }))}
            >
              Aircraft Type
            </Field>
            <Field
              type="text"
              name="wb_longitudinal_limit_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableWbLimits(
                currentSettingsWbLimitLimitTypeLongitudinal
              )}
            >
              WB Longitudinal Limit
            </Field>
            <Field
              type="text"
              name="wb_lateral_limit_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableWbLimits(
                currentSettingsWbLimitLimitTypeLateral
              )}
            >
              WB Lateral Limit
            </Field>
            <Field
              type="text"
              name="wb_external_longitudinal_limit_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableWbLimits(
                currentSettingsWbLimitLimitTypeLongitudinal
              )}
            >
              WB Longitudinal Limit with External Load
            </Field>
            <Field
              type="text"
              name="wb_external_lateral_limit_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableWbLimits(
                currentSettingsWbLimitLimitTypeLateral
              )}
            >
              WB Lateral Limit with External Load
            </Field>
            <Field
              type="text"
              name="seat_configuration_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableSeatConfigurations()}
            >
              Seat Configurations
            </Field>
            <Field
              type="text"
              name="hold_configuration_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableHoldConfigurations()}
            >
              Hold Configurations
            </Field>
            <Field
              type="text"
              name="tank_configuration_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableTankConfigurations()}
            >
              Tank Configurations
            </Field>
            <Field
              type="text"
              name="external_load_point_id"
              labelWidth={3}
              inputWidth={3}
              component={InputField}
              asElement="select"
              selectOptions={this.getSelectableExternalLoadPoints()}
            >
              External Load Point
            </Field>
          </fieldset>
          <Row>
            <FormButtons
              submitting={submitting}
              pristine={pristine}
              updating={this.state.updating}
              cancelLink="/aircraft_configurations"
            />
          </Row>
        </Form>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function validate() {
  return {};
}

const attributeBlackList = [
  '__typename',
  'wbLongitudinalLimit',
  'wbLateralLimit',
  'wbExternalLongitudinalLimit',
  'wbExternalLateralLimit',
  'seatConfiguration',
  'holdConfiguration',
  'tankConfiguration',
  'aircraftType',
  'aircraftAssetAircraftConfigurations',
  'externalLoadPoint',
];

function pickInitialValues(aircraftConfigurationQueryInitial, updating) {
  if (!isInitialisedAircraftConfigurationForm) {
    if (!updating) {
      isInitialisedAircraftConfigurationForm = true;
      return {};
    }
    if (queryReady(aircraftConfigurationQueryInitial)) {
      isInitialisedAircraftConfigurationForm = true;
      const editData = omit(
        omitBy(cloneDeep(aircraftConfigurationQueryInitial.data), isNil),
        attributeBlackList
      );
      return editData;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.aircraftConfigurationQuery,
    !!props.params.id
  );
  return {
    initialValues,
    currentSettingsMutating: state.currentSettings.mutating,
    currentSettingsWbLimitLimitTypeLateral:
      state.currentSettings.wb_limit_limit_type_lateral,
    currentSettingsWbLimitLimitTypeLongitudinal:
      state.currentSettings.wb_limit_limit_type_longitudinal,
    formValues: getFormValues('AircraftConfigurationForm')(state),
  };
}

export default compose(
  graphql(aircraftConfigurationCreateMutation, {
    name: 'aircraftConfigurationCreateMutation',
  }),
  graphql(aircraftConfigurationUpdateMutation, {
    name: 'aircraftConfigurationUpdateMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(wbLimitListQuery, {
    name: 'wbLimitListQuery',
  }),
  graphql(seatConfigurationListQuery, {
    name: 'seatConfigurationListQuery',
  }),
  graphql(holdConfigurationListQuery, {
    name: 'holdConfigurationListQuery',
  }),
  graphql(tankConfigurationListQuery, {
    name: 'tankConfigurationListQuery',
  }),
  graphql(externalLoadPointListQuery, {
    name: 'externalLoadPointListQuery',
  }),
  graphql(aircraftConfigurationQuery, {
    name: 'aircraftConfigurationQuery',
    skip: (props) => !props.params.id,
    // todo convert this bank once newstyle form initialization id done.
    // options: (props) => { return { variables: { id: props.params.id }, fetchPolicy: 'cache-and-network' } }
    options: (props) => ({ variables: { id: props.params.id } }),
  }),
  connect(mapStateToProps, {
    changeFieldValue,
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'AircraftConfigurationForm',
    validate,
  })
)(AircraftConfigurationForm);
