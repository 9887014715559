import { Row, Col, Card, Accordion } from 'react-bootstrap';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import moment from 'moment';
import SunCalc from 'suncalc';

import get from 'lodash.get';
import debounce from 'lodash.debounce';
import intersection from 'lodash.intersection';
import compact from 'lodash.compact';
import includes from 'lodash.includes';
import uniq from 'lodash.uniq';

import { currentSettingsSet } from '../actions/current_setting_actions';

import Loader from '../components/loader';
import CardHeaderToggle from '../components/card_header_toggle';
import { renderOverlay, renderError } from '../components/render_helpers';
import BookingManagerFilters from '../components/booking_manager/booking_manager_filters';
import BookingManagerTools from '../components/booking_manager/booking_manager_tools';
import BookingManagerListItems from '../components/booking_manager/booking_manager_list_items';
import BookingManagerCalendar from '../components/booking_manager/booking_manager_calendar';

import Api from '../lib/api';
import { typeInput } from '../lib/utils';
import { toastSuccess, toastError } from '../lib/action_helpers';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingDeleteMutation from '../mutations/booking_delete_mutation';
import bookingCloneMutation from '../mutations/booking_clone_mutation';

import pageBookingManagerQuery from '../queries/page_booking_manager_query';

const BookingManager = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);
  const [titleStr, setTitleStr] = useState('');
  const [searchStr, setSearchStr] = useState('');
  const [daylightStr, setDaylightStr] = useState('');
  const [searchBookingIds, setSearchBookingIds] = useState([]);
  const [contactOnly, setContactOnly] = useState(false);
  const [resourceTypeContact, setResourceTypeContact] = useState(true);
  const [listHeight, setListHeight] = useState(0);
  const [calendarHeight, setCalendarHeight] = useState(0);
  const [aircraftGroups, setAircraftGroups] = useState([]);
  const [aircraftQuickData, setAircraftQuickData] = useState({});
  const [contactQuickData, setContactQuickData] = useState({});
  const [filteredBookings, setFilteredBookings] = useState({}); // keyed by aircraftgroupid
  const [filteredBookingsLength, setFilteredBookingsLength] = useState(0);
  const [bookingListLength, setBookingListLength] = useState(0);

  const currentContact = useSelector((state) => state.currentContact);
  const employee = useSelector((state) => state.currentContact['employee?']);
  const currentSettingsMutating = useSelector(
    ({ currentSettings }) => currentSettings.mutating
  );
  const currentSettingsBookingCollectionStartDate = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionStartDate
  );
  const currentSettingsBookingCollectionEndDate = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionEndDate
  );
  const currentSettingsBookingCollectionView = useSelector(
    ({ currentSettings }) => currentSettings.bookingCollectionView
  );
  const currentSettingsBookingStatusCancelled = useSelector(
    ({ currentSettings }) => currentSettings.booking_status_cancelled
  );
  const currentSettingsBookingCalendarTypeFlight = useSelector(
    ({ currentSettings }) => currentSettings.booking_calendar_type_flight
  );
  const currentSettingsBookingCalendarTypeBanner = useSelector(
    ({ currentSettings }) => currentSettings.booking_calendar_type_banner
  );
  const currentSettingsEnteredAircraftGroupIds = useSelector(
    ({ currentSettings }) => currentSettings.enteredAircraftGroupIds
  );
  const currentSettingsContainerHeight = useSelector(
    ({ currentSettings }) => currentSettings.containerHeight
  );

  const [bookingUpdate] = useMutation(bookingUpdateMutation);
  const [bookingDelete] = useMutation(bookingDeleteMutation);
  const [bookingClone] = useMutation(bookingCloneMutation);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageBookingManagerQuery, {
    variables: {
      startAt: currentSettingsBookingCollectionStartDate,
      endAt: currentSettingsBookingCollectionEndDate,
    },
    notifyOnNetworkStatusChange: true,
  });

  const handleAircraftGroupEntered = useCallback(
    (e) => {
      const aircraftGroupId = parseInt(e.getAttribute('data-id'), 10);
      dispatch(
        currentSettingsSet({
          enteredAircraftGroupIds: uniq([
            ...currentSettingsEnteredAircraftGroupIds,
            aircraftGroupId,
          ]),
        })
      );
    },
    [currentSettingsEnteredAircraftGroupIds, dispatch]
  );

  const handleAircraftGroupExited = useCallback(
    (e) => {
      const aircraftGroupId = parseInt(e.getAttribute('data-id'), 10);
      dispatch(
        currentSettingsSet({
          enteredAircraftGroupIds: currentSettingsEnteredAircraftGroupIds.filter(
            (id) => id !== aircraftGroupId
          ),
        })
      );
    },
    [currentSettingsEnteredAircraftGroupIds, dispatch]
  );

  // onCalendarDisplayDateChanged
  const dispatchBookingCollectionVars = useCallback(
    (calendarStart, calendarEnd, viewName) => {
      dispatch(
        currentSettingsSet({
          bookingCollectionStartDate: calendarStart,
          bookingCollectionEndDate: calendarEnd,
          bookingCollectionView: viewName,
        })
      );
    },
    [dispatch]
  );

  const dispatchBookingRequestedCalendarType = useCallback(
    (bookingRequestedCalendarType) => {
      dispatch(
        currentSettingsSet({
          bookingRequestedCalendarType,
        })
      );
    },
    [dispatch]
  );

  const onNewClicked = useCallback(
    (isFlight) => {
      if (isFlight) {
        navigate('/flights/new');
      } else {
        navigate('/nonflights/new');
      }
    },
    [navigate]
  );

  const onEditClicked = useCallback(
    (id, isFlight) => {
      if (isFlight) {
        navigate(`/flights/${id}/edit`);
      } else {
        navigate(`/nonflights/${id}/edit`);
      }
    },
    [navigate]
  );

  const onDeleteClicked = useCallback(
    async (id) => {
      const mutationData = {
        variables: { id },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingDelete(mutationData);
        toastSuccess('Booking delete ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Booking delete failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingDelete, dispatch]
  );

  const onCloneClicked = useCallback(
    async (id, cloneType) => {
      const mutationData = {
        variables: {
          input: {
            id,
            cloneType,
          },
        },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingClone(mutationData);
        toastSuccess('Booking cloned ok');
      } catch (err) {
        console.log(err.toString());
        toastError('Booking clone failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingClone, dispatch]
  );

  const onFlightCompleteClicked = useCallback(
    async (id) => {
      const mutationData = {
        variables: {
          id,
          input: typeInput({
            id,
            audit_created: true,
            audit_created_at: moment().format(),
            audit_created_by_admin_id: currentContact.id,
            updated_by_admin_id: currentContact.id,
          }),
        },
      };
      try {
        dispatch(
          currentSettingsSet({
            mutating: true,
          })
        );
        await bookingUpdate(mutationData);
        toastSuccess('Booking update ok');
        navigate(`/flights/${id}/edit`);
      } catch (err) {
        console.log(err.toString());
        toastError('Booking update failed');
      } finally {
        dispatch(
          currentSettingsSet({
            mutating: false,
          })
        );
      }
    },
    [bookingUpdate, currentContact, dispatch, navigate]
  );

  const onListItemCalendarClicked = (id) => {
    const startAt = pageData.bookingList.find((model) => model.id === id).start_at;
    dispatchBookingCollectionVars(
      moment(startAt).startOf('day').format(),
      moment(startAt).endOf('day').format(),
      'resourceTimelineDay'
    );
  };

  const setDebouncedSearchBookingIds = useMemo(
    () =>
      debounce(async (str) => {
        try {
          const resp = await Api.get(`/api/search/bookings/${str}`, {
            start_at: currentSettingsBookingCollectionStartDate,
            end_at: currentSettingsBookingCollectionEndDate,
          });
          setSearchBookingIds(get(resp, 'data', []));
        } catch (err) {
          console.log(err.toString());
        }
      }, 500),
    [currentSettingsBookingCollectionStartDate, currentSettingsBookingCollectionEndDate]
  );

  useEffect(
    /* eslint-disable arrow-body-style, react-hooks/exhaustive-deps */
    () => {
      return () => {
        setDebouncedSearchBookingIds.cancel();
      };
    },
    []
  );

  useEffect(() => {
    dispatch(currentSettingsSet({ returnRoute: location.pathname }));
  }, [dispatch, location]);

  useEffect(() => {
    const getTitleString = (view, start, end) => {
      switch (view) {
        case 'resourceTimelineDay':
          return moment(start).format('ddd, D MMM YYYY');
        case 'timeGridWeek':
          switch (moment(start).format('MMM')) {
            case moment(end).format('MMM'):
              return `${moment(start).format('D')} \u2013 ${moment(end).format(
                'D MMM YYYY'
              )}`;
            default:
              return `${moment(start).format('D MMM')} \u2013 ${moment(end).format(
                'D MMM YYYY'
              )}`;
          }
        case 'dayGridMonth':
          return moment(start).format('MMM YYYY');
        default:
          return 'Unknown';
      }
    };
    setTitleStr(
      getTitleString(
        currentSettingsBookingCollectionView,
        currentSettingsBookingCollectionStartDate,
        currentSettingsBookingCollectionEndDate
      )
    );
  }, [
    currentSettingsBookingCollectionView,
    currentSettingsBookingCollectionStartDate,
    currentSettingsBookingCollectionEndDate,
  ]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading ||
        (pageLoading &&
          (pageNetworkStatus === NetworkStatus.refetch ||
            pageNetworkStatus === NetworkStatus.setVariables))
    );
  }, [pageLoading, pageNetworkStatus]);

  useEffect(() => {
    let newDaylightStr = '';
    if (
      currentSettingsBookingCollectionView === 'resourceTimelineDay' &&
      currentSettingsBookingCollectionStartDate
    ) {
      const times = SunCalc.getTimes(
        moment(currentSettingsBookingCollectionStartDate).add(12, 'hours').toDate(),
        -36.848461,
        174.763336
      );
      newDaylightStr = `${moment(times.dawn).format('HH:mm')} / ${moment(
        times.dusk
      ).format('HH:mm')}`;
    }
    setDaylightStr(newDaylightStr);
  }, [currentSettingsBookingCollectionView, currentSettingsBookingCollectionStartDate]);

  useEffect(() => {
    if (searchStr) {
      setDebouncedSearchBookingIds(searchStr);
    } else {
      setSearchBookingIds([]);
    }
  }, [searchStr, setDebouncedSearchBookingIds]);

  useEffect(() => {
    let newListHeight = currentSettingsContainerHeight - 105 - 15 - 88;
    if (currentSettingsContainerHeight < 480) {
      newListHeight = currentSettingsContainerHeight - 165 - 15 - 88;
    }
    setListHeight(newListHeight);
    let newCalendarHeight = currentSettingsContainerHeight - 15 - 124;
    if (currentSettingsContainerHeight < 480) {
      newCalendarHeight = currentSettingsContainerHeight - 175 - 15 - 124;
    } else if (currentSettingsContainerHeight < 992) {
      newCalendarHeight = currentSettingsContainerHeight - 115 - 15 - 124;
    }
    setCalendarHeight(newCalendarHeight);
  }, [currentSettingsContainerHeight]);

  useEffect(() => {
    let newBookingListLength = 0;
    let newFilteredBookings = {};
    let newFilteredBookingsLength = 0;
    if (pageData && pageData.bookingList?.length > 0) {
      newBookingListLength = pageData.bookingList.length;
      const {
        id: currentContactId,
        related_aircraft_ids: relatedAircraftIds,
        related_chargeable_ids: relatedChargeableIds,
      } = currentContact;
      newFilteredBookings = pageData.aircraftGroupList.reduce((accum, aircraftGroup) => {
        const { name: aircraftGroupName, aircrafts, groupPilots } = aircraftGroup;
        const sharedBookings = pageData.bookingList.filter((booking) => {
          const {
            id: bookingId,
            aircraft_id: aircraftId,
            pilot_id: pilotId,
            copilot_id: copilotId,
            bookingEmployees,
            bookingChargeables,
          } = booking;
          const pilotIds = compact([pilotId, copilotId]);
          if (employee) {
            if (contactOnly) {
              // if not pilot, copilot or employee, remove
              const employeeIds = bookingEmployees.map((be) => be.employee_id);
              if (
                !(
                  includes(pilotIds, currentContactId) ||
                  employeeIds.includes(currentContactId)
                )
              ) {
                return false;
              }
            }
            if (searchStr) {
              // if not in the search list of ids, remove
              if (!searchBookingIds.includes(bookingId)) {
                return false;
              }
            }
          } else {
            // if not an employee, and not the pilot, owner, or chargeable, remove
            const chargeableIds = bookingChargeables.map((bca) => bca.chargeable_id);
            if (
              !(
                includes(pilotIds, currentContactId) ||
                (relatedAircraftIds && includes(relatedAircraftIds, aircraftId)) ||
                (relatedChargeableIds &&
                  intersection(relatedChargeableIds, chargeableIds).length > 0)
              )
            ) {
              return false;
            }
          }
          return true;
        });

        // list bookings only include bookings for the aircraft in the aircraftGroup
        const groupAircraftIds = aircrafts.map((a) => a.id);
        const listBookings = sharedBookings.filter((booking) => {
          const { aircraft_id: aircraftId, calendar_type: calendarType } = booking;
          if (calendarType === currentSettingsBookingCalendarTypeFlight) {
            // only if booking aircraft belongs to the group
            if (includes(groupAircraftIds, aircraftId)) {
              return true;
            }
          } else if (aircraftGroupName === 'Advanced Flight') {
            // non flights only go into advanced flight group
            // todo add a 'default' boolean to the aircraft group
            // todo rename the aircraftgroup model to bookinggroup
            return true;
          }
          return false;
        });
        newFilteredBookingsLength += listBookings.length;

        // calendar aircraft bookings don't include cancelled bookings of non aircraft bookings (maintenance)
        const calendarAircraftBookings = listBookings.filter((booking) => {
          const { aircraft_id: aircraftId, status } = booking;
          if (!aircraftId) {
            return false;
          }
          if (status === currentSettingsBookingStatusCancelled) {
            return false;
          }
          return true;
        });

        // calendar contact bookings include bookings for any pilot in the aircraftGroup, but nothing cancelled
        const groupPilotIds = groupPilots.map((gp) => gp.id);
        const calendarContactBookings = sharedBookings.filter((booking) => {
          const {
            status,
            calendar_type: calendarType,
            pilot_id: pilotId,
            copilot_id: copilotId,
            bookingEmployees,
          } = booking;
          if (status === currentSettingsBookingStatusCancelled) {
            return false;
          }
          const bookingEmployeeIds = bookingEmployees.map((be) => be.employee_id);
          if (
            includes(groupPilotIds, pilotId) ||
            includes(groupPilotIds, copilotId) ||
            intersection(groupPilotIds, bookingEmployeeIds).length > 0 ||
            (calendarType !== currentSettingsBookingCalendarTypeFlight &&
              aircraftGroupName === 'Advanced Flight')
          ) {
            return true;
          }
          return false;
        });

        const sharedDutyEvents = pageData.dutyEventList.filter((dutyEvent) => {
          const { contact_id: contactId } = dutyEvent;
          if (employee) {
            if (contactOnly) {
              // if not self, remove
              if (contactId !== currentContactId) {
                return false;
              }
            }
            // if (searchStr) {
            //   // if not in the search list of ids, remove
            //   if (!searchBookingIds.includes(bookingId)) {
            //     return false;
            //   }
            // }
          } else if (contactId !== currentContactId) {
            // if not an employee, and not self remove
            return false;
          }
          if (
            includes(groupPilotIds, contactId) ||
            aircraftGroupName === 'Advanced Flight'
          ) {
            return true;
          }
          return false;
        });

        return {
          ...accum,
          [aircraftGroup.id]: {
            list: listBookings,
            calendarContact: calendarContactBookings,
            calendarAircraft: calendarAircraftBookings,
            calendarDutyEvents: sharedDutyEvents,
          },
        };
      }, {});
    }
    setFilteredBookings(newFilteredBookings);
    setFilteredBookingsLength(newFilteredBookingsLength);
    setBookingListLength(newBookingListLength);
  }, [
    pageData,
    currentContact,
    searchStr,
    searchBookingIds,
    contactOnly,
    employee,
    currentSettingsBookingCalendarTypeFlight,
    currentSettingsBookingStatusCancelled,
  ]);

  useEffect(() => {
    if (pageData?.aircraftGroupList?.length > 0) {
      setAircraftGroups(pageData.aircraftGroupList);
    }
  }, [pageData]);

  useEffect(() => {
    let newAircraftQuickData = {};
    if (pageData?.aircraftList?.length > 0) {
      newAircraftQuickData = pageData.aircraftList.reduce((accum, aircraft) => {
        const {
          id: aircraftId,
          registration_abbreviated: registrationAbbreviated,
          aircraftType: { name: aircraftTypeName },
        } = aircraft;
        return {
          ...accum,
          [aircraftId]: {
            registrationAbbreviated,
            aircraftTypeName,
          },
        };
      }, {});
    }
    setAircraftQuickData(newAircraftQuickData);
  }, [pageData]);

  useEffect(() => {
    let newContactQuickData = {};
    const contacts = [
      ...get(pageData, 'pilotList', []),
      ...get(pageData, 'employeeList', []),
      ...get(pageData, 'chargeableList', []),
    ];
    if (contacts.length > 0) {
      newContactQuickData = contacts.reduce((accum, contact) => {
        const { id: contactId, fullName } = contact;
        return {
          ...accum,
          [contactId]: {
            fullName,
          },
        };
      }, {});
    }
    setContactQuickData(newContactQuickData);
  }, [pageData]);

  const renderContent = () => (
    <Accordion
      alwaysOpen
      defaultActiveKey={currentSettingsEnteredAircraftGroupIds}
      as={Row}
      className="mt-3"
    >
      <Col sm={5}>
        <Card>
          <Card.Body>
            <Row className="g-0">
              <Col className="flex-grow-0 text-nowrap">
                <h3>{titleStr}</h3>
              </Col>
              <Col>
                <Row className="justify-content-end g-0">
                  <BookingManagerFilters
                    pageRefetch={pageRefetch}
                    bookingListLength={bookingListLength}
                    filteredBookingsLength={filteredBookingsLength}
                    employee={employee}
                    contactOnly={contactOnly}
                    setContactOnly={setContactOnly}
                    resourceTypeContact={resourceTypeContact}
                    setResourceTypeContact={setResourceTypeContact}
                    searchStr={searchStr}
                    setSearchStr={setSearchStr}
                  />
                </Row>
              </Col>
            </Row>
            <Row className="mb-2 g-0">
              <BookingManagerTools
                pageRefetch={pageRefetch}
                dispatchBookingCollectionVars={dispatchBookingCollectionVars}
                dispatchBookingRequestedCalendarType={
                  dispatchBookingRequestedCalendarType
                }
                onNewClicked={onNewClicked}
                currentSettingsBookingCollectionStartDate={
                  currentSettingsBookingCollectionStartDate
                }
                currentSettingsBookingCollectionEndDate={
                  currentSettingsBookingCollectionEndDate
                }
                currentSettingsBookingCollectionView={
                  currentSettingsBookingCollectionView
                }
                currentSettingsBookingCalendarTypeFlight={
                  currentSettingsBookingCalendarTypeFlight
                }
              />
            </Row>
            <Row className="g-0">
              <Col
                className="overflow-auto"
                style={{
                  height: listHeight,
                }}
              >
                {aircraftGroups.map((aircraftGroup) => {
                  const { id: aircraftGroupId, name: aircraftGroupName } = aircraftGroup;
                  return (
                    <Card
                      key={aircraftGroupId}
                      className="mb-2 border-start-0 border-end-0 rounded-0"
                    >
                      <CardHeaderToggle className="px-4" eventKey={aircraftGroupId}>
                        <Row className="justify-content-between">
                          <Col className="align-self-center">{aircraftGroupName}</Col>
                        </Row>
                      </CardHeaderToggle>
                      <Accordion.Collapse
                        data-id={aircraftGroupId}
                        eventKey={aircraftGroupId}
                        onEntered={handleAircraftGroupEntered}
                        onExited={handleAircraftGroupExited}
                      >
                        <Card.Body className="p-0">
                          {includes(
                            currentSettingsEnteredAircraftGroupIds,
                            aircraftGroupId
                          ) ? (
                            <BookingManagerListItems
                              bookings={get(
                                filteredBookings,
                                [aircraftGroupId, 'list'],
                                []
                              )}
                              currentContact={currentContact}
                              onListItemCalendarClicked={onListItemCalendarClicked}
                              onFlightCompleteClicked={onFlightCompleteClicked}
                              onDeleteClicked={onDeleteClicked}
                              onCloneClicked={onCloneClicked}
                              currentSettingsBookingCalendarTypeBanner={
                                currentSettingsBookingCalendarTypeBanner
                              }
                              currentSettingsBookingCalendarTypeFlight={
                                currentSettingsBookingCalendarTypeFlight
                              }
                              currentSettingsBookingStatusCancelled={
                                currentSettingsBookingStatusCancelled
                              }
                              aircraftQuickData={aircraftQuickData}
                              contactQuickData={contactQuickData}
                            />
                          ) : (
                            <Loader
                              fadeIn="none"
                              wrapperStyle={{
                                zIndex: 1000,
                                height: '100%',
                                marginTop: '20px',
                              }}
                            />
                          )}
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  );
                })}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col sm={7}>
        <Card>
          <Card.Body>
            <Row className="g-0">
              <Col
                id="aircraft-groups"
                className="overflow-auto"
                style={{
                  height: calendarHeight,
                }}
              >
                {aircraftGroups.map((aircraftGroup) => {
                  const { id: aircraftGroupId, name: aircraftGroupName } = aircraftGroup;
                  const AIRCRAFT_GROUP_HTML_ID = `aircraft-group-${aircraftGroupId}`;
                  const AIRCRAFT_GROUP_CALENDAR_HTML_ID = `${AIRCRAFT_GROUP_HTML_ID}-calendar`;
                  const AIRCRAFT_GROUP_POPOVERS_HTML_ID = `${AIRCRAFT_GROUP_HTML_ID}-popovers`;
                  return (
                    <div key={aircraftGroupId} id={AIRCRAFT_GROUP_HTML_ID}>
                      <div id={AIRCRAFT_GROUP_POPOVERS_HTML_ID} />
                      <Card className="mb-2 border-start-0 border-end-0 rounded-0">
                        <CardHeaderToggle className="px-4" eventKey={aircraftGroupId}>
                          <Row className="justify-content-between">
                            <Col className="align-self-center">{aircraftGroupName}</Col>
                            <Col className="text-center">{titleStr}</Col>
                            <Col className="text-end">
                              {aircraftGroupName === 'Advanced Flight' && daylightStr}
                            </Col>
                          </Row>
                        </CardHeaderToggle>
                        <Accordion.Collapse
                          data-id={aircraftGroupId}
                          eventKey={aircraftGroupId}
                          onEntered={handleAircraftGroupEntered}
                          onExited={handleAircraftGroupExited}
                        >
                          <Card.Body className="p-0 m-0">
                            <Card className="mb-2">
                              <Card.Body className="p-0">
                                {includes(
                                  currentSettingsEnteredAircraftGroupIds,
                                  aircraftGroupId
                                ) ? (
                                  <BookingManagerCalendar
                                    AIRCRAFT_GROUP_CALENDAR_HTML_ID={
                                      AIRCRAFT_GROUP_CALENDAR_HTML_ID
                                    }
                                    AIRCRAFT_GROUP_POPOVERS_HTML_ID={
                                      AIRCRAFT_GROUP_POPOVERS_HTML_ID
                                    }
                                    aircraftGroup={aircraftGroup}
                                    contactBookings={get(
                                      filteredBookings,
                                      [aircraftGroupId, 'calendarContact'],
                                      []
                                    )}
                                    aircraftBookings={get(
                                      filteredBookings,
                                      [aircraftGroupId, 'calendarAircraft'],
                                      []
                                    )}
                                    dutyEvents={get(
                                      filteredBookings,
                                      [aircraftGroupId, 'calendarDutyEvents'],
                                      []
                                    )}
                                    resourceTypeContact={resourceTypeContact}
                                    currentSettingsBookingCollectionStartDate={
                                      currentSettingsBookingCollectionStartDate
                                    }
                                    currentSettingsBookingCollectionEndDate={
                                      currentSettingsBookingCollectionEndDate
                                    }
                                    currentSettingsBookingCollectionView={
                                      currentSettingsBookingCollectionView
                                    }
                                    currentSettingsBookingCalendarTypeFlight={
                                      currentSettingsBookingCalendarTypeFlight
                                    }
                                    onEditClicked={onEditClicked}
                                    onDisplayDateChanged={dispatchBookingCollectionVars}
                                  />
                                ) : (
                                  <Loader
                                    fadeIn="none"
                                    wrapperStyle={{
                                      zIndex: 1000,
                                      height: '100%',
                                      marginTop: '20px',
                                    }}
                                  />
                                )}
                              </Card.Body>
                            </Card>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Accordion>
  );

  return (
    <div>
      {renderOverlay(pageLoading, currentSettingsMutating)}
      {renderError(pageError)}
      {!pageError && pageLoadedOrRefetching && renderContent()}
    </div>
  );
};

export default BookingManager;
