import pickBy from 'lodash.pickby';
import cloneDeep from 'lodash.clonedeep';
import identity from 'lodash.identity';
import compact from 'lodash.compact';

import Ajv from 'ajv';
import extendAjvErrors from 'ajv-errors';
import addFormats from 'ajv-formats';

const makeFormValidator = (schema) => {
  const ajv = new Ajv({
    allErrors: true,
    coerceTypes: true,
    $data: true,
  });
  addFormats(ajv);
  extendAjvErrors(ajv);
  const validator = ajv.compile(schema);
  return (values) => {
    const errors = {};
    const testValues = pickBy(cloneDeep(values), identity);
    validator(testValues);
    if (validator.errors) {
      validator.errors.forEach((obj) => {
        let objKey;
        let dataPath = obj.instancePath.replace(/\//g, '.');
        dataPath = dataPath.replace(/^\./, '');
        if (obj.keyword === 'required') {
          objKey = compact([dataPath, obj.params.missingProperty]).join('.');
        } else {
          objKey = dataPath;
        }
        if (objKey) {
          if (
            obj.message !== 'must match "then" schema' &&
            obj.message !== 'must match "else" schema'
          ) {
            if (objKey && errors[objKey]) {
              errors[objKey] = [...errors[objKey], obj.message];
            } else {
              errors[objKey] = [obj.message];
            }
          }
        }
      });
    }
    // console.log(errors);
    return errors;
  };
};

export default makeFormValidator;
