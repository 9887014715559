import moment from 'moment';
import objectHash from 'object-hash';

import compact from 'lodash.compact';
import clone from 'lodash.clone';
import flatten from 'lodash.flatten';
import includes from 'lodash.includes';

import { kebabCase, sentenceCase } from 'change-case';
import { isCalendarType, isAdminComplete, isPilotComplete } from '../lib/bookingHelpers';
import { getDateRanges } from '../../lib/utils';

const withRosteredBookings = !!parseInt(process.env.WITH_ROSTERED_BOOKINGS, 10);

const getBookingEventClasses = (booking, isFlight, isUnavailable) => {
  const {
    calendar_type: calendarType,
    status,
    end_at: endAt,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  const classNames = [calendarType];
  if (tiaTogProfile && tiaTogProfile.length > 0) {
    classNames.push('profiled');
  }
  const adminComplete = isAdminComplete(booking);
  const pilotComplete = isPilotComplete(booking);
  if (isUnavailable) {
    classNames.push('unavailable');
  } else if (adminComplete) {
    classNames.push('admin_complete');
  } else if (pilotComplete) {
    classNames.push('pilot_complete');
  } else if (!isFlight && moment(endAt).isBefore(moment())) {
    classNames.push('time_complete');
  } else {
    classNames.push(status);
  }
  return classNames;
};

const getUnavailableDutyEventCalendarEvent = (dutyEvent) => {
  const {
    id,
    contact,
    start_at: startAt,
    end_at: endAt,
    name,
    duty_notes: dutyNotes,
  } = dutyEvent;
  const { id: employeeId, fullName, employee_color: employeeColor } = contact;

  return {
    id: `${id}-unavailable-dutyemployee-${employeeId}`,
    title: sentenceCase(name),
    resourceId: `${kebabCase(fullName)}-test`,
    start: startAt,
    end: endAt,
    allDay: true,
    display: 'background',
    borderColor: '#A9A9A9',
    backgroundColor: '#A9A9A9',
    textColor: '#000000',
    extendedProps: {
      eventType: 'DutyEvent',
      dutyEventId: id,
      jobNotes: dutyNotes,
      contactFullName: fullName,
      contactColor: employeeColor || '#3a87ad',
    },
  };
};

const getAvailableDutyEventCalendarEvent = (
  dutyEvent,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate
) => {
  const {
    id,
    contact,
    start_at: startAtStr,
    end_at: endAtStr,
    start_time: startTimeStr,
    end_time: endTimeStr,
    name,
    duty_notes: dutyNotes,
  } = dutyEvent;
  const { id: employeeId, fullName, employee_color: employeeColor } = contact;
  const startTime = moment(startTimeStr, 'HH:mm:ss');
  const endTime = moment(endTimeStr, 'HH:mm:ss');
  const endTimeBeforeStartTime = endTime.isBefore(startTime, 'second');
  const hasStartBefore = moment(startAtStr).isBefore(
    moment(currentSettingsBookingCollectionStartDate),
    'second'
  );
  // const hasEndAfter = moment(currentSettingsBookingCollectionEndDate).isBefore(
  //   moment(endAtStr).subtract(1, 'day').endOf('day'),
  //   'second'
  // );
  const maxStartAt = moment.max(
    moment(startAtStr),
    moment(currentSettingsBookingCollectionStartDate)
  );
  const minEndAt = moment.min(
    moment(endAtStr).subtract(1, 'day').endOf('day'),
    moment(currentSettingsBookingCollectionEndDate)
  );
  const dayRanges = getDateRanges(
    maxStartAt.format(),
    minEndAt.format(),
    true,
    false,
    false
  );
  const flightEvents = dayRanges.map((dayRange) => {
    // available events wrap the available event with unavailable events
    const { start_at: rangeStartAt, end_at: rangeEndAt } = dayRange;
    if (!endTimeBeforeStartTime) {
      const dutyStartAt = moment(rangeStartAt)
        .hour(startTime.hour())
        .minute(startTime.minute())
        .format();
      const dutyEndAt = moment(rangeEndAt)
        .hour(endTime.hour())
        .minute(endTime.minute())
        .second(0)
        .format();
      return [
        {
          id: `${id}-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: dutyStartAt,
          end: dutyEndAt,
          borderColor: '#FFFFFF',
          backgroundColor: '#FFFFFF',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
        {
          id: `${id}-pre-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: rangeStartAt,
          end: dutyStartAt,
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            skipPopover: true,
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
        {
          id: `${id}-post-available-dutyemployee-${employeeId}`,
          title: sentenceCase(name),
          resourceId: `${kebabCase(fullName)}-test`,
          start: dutyEndAt,
          end: rangeEndAt,
          borderColor: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          textColor: '#000000',
          display: 'background',
          extendedProps: {
            eventType: 'DutyEvent',
            skipPopover: true,
            dutyEventId: id,
            jobNotes: dutyNotes,
            contactFullName: fullName,
            contactColor: employeeColor || '#3a87ad',
          },
        },
      ];
    }
    const spanFlightEvents = [];
    const eveningDutyStartAt = moment(rangeStartAt)
      .hour(startTime.hour())
      .minute(startTime.minute())
      .format();
    const eveningDutyEndAt = moment(rangeEndAt).format();

    const morningDutyStartAt = moment(rangeStartAt).format();
    const morningDutyEndAt = moment(rangeStartAt)
      .hour(endTime.hour())
      .minute(endTime.minute())
      .second(0)
      .format();
    if (hasStartBefore) {
      // this is the day after a shift spanning both days
      spanFlightEvents.push({
        id: `${id}-morning-available-dutyemployee-${employeeId}`,
        title: sentenceCase(name),
        resourceId: `${kebabCase(fullName)}-test`,
        start: morningDutyStartAt,
        end: morningDutyEndAt,
        display: 'background',
        borderColor: '#FFFFFF',
        backgroundColor: '#FFFFFF',
        textColor: '#000000',
        extendedProps: {
          eventType: 'DutyEvent',
          dutyEventId: id,
          jobNotes: dutyNotes,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
        },
      });
      spanFlightEvents.push({
        id: `${id}-pre-evening-available-dutyemployee-${employeeId}`,
        title: sentenceCase(name),
        resourceId: `${kebabCase(fullName)}-test`,
        start: morningDutyEndAt,
        end: eveningDutyStartAt,
        display: 'background',
        borderColor: '#A9A9A9',
        backgroundColor: '#A9A9A9',
        textColor: '#000000',
        extendedProps: {
          skipPopover: true,
          eventType: 'DutyEvent',
          dutyEventId: id,
          jobNotes: dutyNotes,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
        },
      });
    }
    spanFlightEvents.push({
      id: `${id}-evening-available-dutyemployee-${employeeId}`,
      title: sentenceCase(name),
      resourceId: `${kebabCase(fullName)}-test`,
      start: eveningDutyStartAt,
      end: eveningDutyEndAt,
      display: 'background',
      borderColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      textColor: '#000000',
      extendedProps: {
        eventType: 'DutyEvent',
        dutyEventId: id,
        jobNotes: dutyNotes,
        contactFullName: fullName,
        contactColor: employeeColor || '#3a87ad',
      },
    });
    return spanFlightEvents;
  });
  return compact(flatten(flightEvents));
};

const getBookingEmployeeEvent = (booking, isFlight, calendarView) => {
  const isUnavailable = false;
  const {
    id,
    start_at: startAt,
    end_at: endAt,
    calendar_type: calendarType,
    job_notes: jobNotes,
    employees,
  } = booking;
  const employeeFullNames = employees.map((e) => e.fullName).join(', ');
  const employeeEvents = employees.map((employee) => {
    const { id: employeeId, fullName, employee_color: employeeColor } = employee;
    if (fullName) {
      return {
        id: `${id}-employee-${employeeId}-orig`,
        title: sentenceCase(calendarType),
        resourceId: `${kebabCase(fullName)}-orig`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isUnavailable),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          jobNotes,
          employeeFullNames,
          contactFullName: fullName,
          contactColor: employeeColor || '#3a87ad',
          isFlight,
          isUnavailable,
        },
      };
    }
    return undefined;
  });
  if (calendarView !== 'resourceTimelineDay') {
    return compact(employeeEvents).map((event) => {
      if (moment(event.end).dayOfYear() !== moment(event.start).dayOfYear()) {
        const allDayEvent = clone(event);
        allDayEvent.allDay = true;
        allDayEvent.extendedProps.start = moment(event.start).format();
        allDayEvent.extendedProps.end = moment(event.end).format();
        allDayEvent.start = moment(event.start).startOf('day').format();
        allDayEvent.end = moment(event.end).add(1, 'days').startOf('day').format();
        return allDayEvent;
      }
      return event;
    });
  }
  return compact(employeeEvents);
};

const getBookingFlightEvent = (booking, isFlight, aircraftIds) => {
  const {
    id,
    aircraft,
    start_at: startAt,
    end_at: endAt,
    pilot,
    copilot,
    flightSegmentSummaries,
    chargeables,
    tia_tog_profile: tiaTogProfile,
  } = booking;
  let flightEvents = [];

  const { id: aircraftId, registration_abbreviated: registrationAbbreviated } = aircraft;
  const chargeableFullNames = chargeables.map((c) => c.fullName).join(', ');
  const isUnavailable = !includes(aircraftIds, aircraftId);
  const adminComplete = isAdminComplete(booking);

  if (pilot) {
    const {
      id: pilotId,
      fullName: pilotFullName,
      employee_color: pilotEmployeeColor,
    } = pilot;
    flightEvents = [
      {
        id: `${id}-pilot-${pilotId}-orig`,
        title: registrationAbbreviated,
        resourceId: `${kebabCase(pilotFullName)}-orig`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isUnavailable),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: pilotFullName,
          contactColor: pilotEmployeeColor || '#3a87ad',
          isFlight,
          isUnavailable,
        },
      },
    ];
    if (withRosteredBookings) {
      flightEvents.push({
        id: `${id}-pilot-${pilotId}-test`,
        title: registrationAbbreviated,
        resourceId: `${kebabCase(pilotFullName)}-test`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isUnavailable),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: pilotFullName,
          contactColor: pilotEmployeeColor || '#3a87ad',
          isFlight,
          isUnavailable,
        },
      });
    }
    if (copilot) {
      const {
        id: copilotId,
        fullName: copilotFullName,
        employee_color: copilotEmployeeColor,
      } = copilot;
      flightEvents.push({
        id: `${id}-copilot-${copilotId}-orig`,
        title: registrationAbbreviated,
        resourceId: `${kebabCase(copilotFullName)}-orig`,
        start: startAt,
        end: endAt,
        classNames: getBookingEventClasses(booking, isFlight, isUnavailable),
        textColor: 'inherit',
        extendedProps: {
          eventType: 'Booking',
          bookingId: id,
          adminComplete,
          flightSegmentSummaries,
          tiaTogProfile,
          chargeableFullNames,
          contactFullName: copilotFullName,
          contactColor: copilotEmployeeColor || '#3a87ad',
          isFlight,
          isUnavailable,
        },
      });
      if (withRosteredBookings) {
        flightEvents.push({
          id: `${id}-copilot-${copilotId}-test`,
          title: registrationAbbreviated,
          resourceId: `${kebabCase(copilotFullName)}-test`,
          start: startAt,
          end: endAt,
          classNames: getBookingEventClasses(booking, isFlight, isUnavailable),
          textColor: 'inherit',
          extendedProps: {
            eventType: 'Booking',
            bookingId: id,
            adminComplete,
            flightSegmentSummaries,
            tiaTogProfile,
            chargeableFullNames,
            contactFullName: copilotFullName,
            contactColor: copilotEmployeeColor || '#3a87ad',
            isFlight,
            isUnavailable,
          },
        });
      }
    }
  }
  return compact(flightEvents);
};

const getBookingEvent = (booking, aircraftIds, calendarTypeFlight, calendarView) => {
  const { employees: bookingEmployees } = booking;
  let events = [];
  if (isCalendarType(calendarTypeFlight, booking)) {
    events = getBookingFlightEvent(booking, true, aircraftIds);
  }
  if (bookingEmployees.length > 0) {
    events = getBookingEmployeeEvent(booking, false, calendarView);
  }
  return compact(flatten(events));
};

const getEvents = (
  bookings,
  dutyEvents,
  aircraftGroup,
  currentSettingsBookingCollectionView,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingCollectionEndDate,
  currentSettingsBookingCalendarTypeFlight
) => {
  const aircraftIds = aircraftGroup.aircrafts.map((a) => a.id);
  const bookingCalendarEvents = compact(
    flatten(
      bookings.map((booking) =>
        getBookingEvent(
          booking,
          aircraftIds,
          currentSettingsBookingCalendarTypeFlight,
          currentSettingsBookingCollectionView
        )
      )
    )
  );
  const unavailableDutyEvents = dutyEvents.filter(
    (de) => withRosteredBookings && !de.contact_available
  );
  const unavailableDutyEventCalendarEvents = compact(
    flatten(
      unavailableDutyEvents.map((dutyEvent) =>
        getUnavailableDutyEventCalendarEvent(dutyEvent)
      )
    )
  );
  const availableDutyEvents = dutyEvents.filter(
    (de) => withRosteredBookings && de.contact_available
  );
  const availableDutyEventCalendarEvents = compact(
    flatten(
      availableDutyEvents.map((dutyEvent) =>
        getAvailableDutyEventCalendarEvent(
          dutyEvent,
          currentSettingsBookingCollectionStartDate,
          currentSettingsBookingCollectionEndDate
        )
      )
    )
  );
  const flightEvents = [
    ...bookingCalendarEvents,
    ...unavailableDutyEventCalendarEvents,
    ...availableDutyEventCalendarEvents,
  ].map((flightEvent) => {
    const hash = objectHash(flightEvent);
    return {
      ...flightEvent,
      id: `${flightEvent.id}-${hash}`,
    };
  });
  return flightEvents;
};

export default getEvents;
