import { Component } from 'react';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import { LinkContainer } from 'react-router-bootstrap';
import { connect } from 'react-redux';
import { Row, Col, Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap';

import get from 'lodash.get';
import last from 'lodash.last';

import Confirm from '../components/confirm';
import Loader from '../components/loader';
import DlHorizontal from '../components/dl_horizontal';
import Title from '../components/title';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import aircraftConfigurationQuery from '../queries/aircraft_configuration_query';
import aircraftConfigurationDeleteMutation from '../mutations/aircraft_configuration_delete_mutation';

import { queriesReady } from '../lib/utils';

class AircraftConfigurationShow extends Component {
  constructor(props) {
    super(props);
    this.handleDeleteClick = this.handleDeleteClick.bind(this);
  }

  handleDeleteClick(e) {
    this.props.mutationSet(true);
    const aircraftConfigurationId = last(e.currentTarget.id.split('-'));
    this.props
      .aircraftConfigurationDeleteMutation({
        variables: {
          id: aircraftConfigurationId,
        },
      })
      .then(() => {
        this.props.mutationSuccess('Aircraft Configuration delete');
        this.props.navigate('/aircraft_configurations');
      })
      .catch((err) => {
        this.props.mutationFailure(err);
      });
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const testProps = props || this.props;
    return !queriesReady(
      testProps.aircraftTypeListQuery,
      testProps.aircraftConfigurationQuery
    );
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return undefined;
  }

  renderData() {
    if (this.isLoaded()) {
      const {
        id,
        name,
        description,
        aircraftType: { name: aircraftTypeName },
        wbLongitudinalLimit: { name: wbLongitudinalLimitName },
        wbLateralLimit: { name: wbLateralLimitName },
        wbExternalLateralLimit,
        wbExternalLongitudinalLimit,
        seatConfiguration: { name: seatConfigurationName },
        holdConfiguration: { name: holdConfigurationName },
        tankConfiguration: { name: tankConfigurationName },
        aircraftAssetAircraftConfigurations,
        externalLoadPoint,
      } = this.props.aircraftConfigurationQuery.data;
      return (
        <>
          <Row className="mt-4 mb-3">
            <Col sm="auto">
              <Title show fullName={name}>
                Aircraft Configuration
              </Title>
            </Col>
            <Col>
              <Row className="justify-content-end g-0">
                <Col sm="auto">
                  <ButtonToolbar>
                    <ButtonGroup size="sm" className="mx-1">
                      <Confirm
                        confirmId={`aircraft-configuration-delete-${id}`}
                        onConfirm={this.handleDeleteClick}
                        title="Delete Aircraft Configuration"
                        body="Are you sure you want to delete this Aircraft Configuration?"
                        confirmText="Confirm"
                      >
                        <Button variant="danger">Delete</Button>
                      </Confirm>
                    </ButtonGroup>
                    <ButtonGroup size="sm" className="mx-1">
                      <LinkContainer to={`/aircraft_configurations/${id}/edit`}>
                        <Button variant="primary">Edit</Button>
                      </LinkContainer>
                      <LinkContainer to="/aircraft_configurations">
                        <Button variant="primary">All Aircraft Configurations</Button>
                      </LinkContainer>
                    </ButtonGroup>
                  </ButtonToolbar>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <hr />
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Header>Aircraft Configuration Details</Card.Header>
                <Card.Body>
                  <DlHorizontal dt="Name" dd={name} />
                  <DlHorizontal dt="Description" dd={description} />
                  <DlHorizontal dt="Aircraft Type" dd={aircraftTypeName} />
                  <DlHorizontal dt="WB Long Limit" dd={wbLongitudinalLimitName} />
                  <DlHorizontal dt="WB Lat Limit" dd={wbLateralLimitName} />
                  <DlHorizontal
                    dt="WB Ext Long Limit"
                    dd={get(wbExternalLongitudinalLimit, 'name', '-')}
                  />
                  <DlHorizontal
                    dt="WB Ext Lat Limit"
                    dd={get(wbExternalLateralLimit, 'name', '-')}
                  />
                  <DlHorizontal dt="Seat Configuration" dd={seatConfigurationName} />
                  <DlHorizontal dt="Hold Configuration" dd={holdConfigurationName} />
                  <DlHorizontal dt="Tank Configuration" dd={tankConfigurationName} />
                  <DlHorizontal
                    dt="External Load Point"
                    dd={get(externalLoadPoint, 'name', '-')}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Card>
                <Card.Header>Aircraft Asset Details</Card.Header>
                <Card.Body>
                  {aircraftAssetAircraftConfigurations.map((aircraftAsset) => {
                    const {
                      aircraftAsset: { name: aircraftAssetName },
                    } = aircraftAsset;
                    return <DlHorizontal dt="-" dd={aircraftAssetName} />;
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
    return undefined;
  }

  render() {
    return (
      <>
        {this.renderOverlay()}
        {this.renderData()}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSettingsMutating: state.currentSettings.mutating,
  };
}

export default compose(
  graphql(aircraftConfigurationDeleteMutation, {
    name: 'aircraftConfigurationDeleteMutation',
  }),
  graphql(aircraftTypeListQuery, {
    name: 'aircraftTypeListQuery',
  }),
  graphql(aircraftConfigurationQuery, {
    name: 'aircraftConfigurationQuery',
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'cache-and-network',
    }),
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  })
)(AircraftConfigurationShow);
