import { renderToStaticMarkup } from 'react-dom/server';
import { Popover } from 'bootstrap';

import { renderMultlilineText } from '../../lib/utils';

const renderDescription = (description, dutyNotes) => (
  <>
    <p>{description}</p>
    {dutyNotes && <p>{renderMultlilineText(dutyNotes)}</p>}
  </>
);

const renderEventPopover = (info, ROSTER_POPOVERS_HTML_ID) => {
  const {
    event: {
      title,
      extendedProps: { description, dutyNotes },
    },
    el,
  } = info;

  const content = renderDescription(description, dutyNotes);

  const popOver = new Popover(el, {
    html: true,
    title,
    placement: 'bottom',
    content: renderToStaticMarkup(content),
    trigger: 'hover',
    container: `#${ROSTER_POPOVERS_HTML_ID}`,
  });

  return popOver;
};

export default renderEventPopover;
