import { Row, Col, Form, Button, ButtonGroup } from 'react-bootstrap';
import { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { graphql } from '@apollo/client/react/hoc';
import moment from 'moment';
import { LinkContainer } from 'react-router-bootstrap';

import { reduxForm, Field, FieldArray, getFormValues } from 'redux-form';

import cloneDeep from 'lodash.clonedeep';
import get from 'lodash.get';
import pick from 'lodash.pick';

import {
  mutationSet,
  mutationSuccess,
  mutationFailure,
} from '../actions/mutation_actions';

import { contactsData } from '../selectors';

import Loader from '../components/loader';
import Glyphicon from '../components/glyphicon';
import InputField from '../components/form/input_field';

import BookingCreators from '../components/flight_form/booking_creators';
import BookingTitle from '../components/flight_form/booking_title';

import EmployeeInputField from '../components/flight_form/employee_input_field';
import StartDateAndTimeInputFields from '../components/flight_form/start_date_and_time_input_fields';
import EndDateAndTimeInputFields from '../components/flight_form/end_date_and_time_input_fields';

import { defaultNonFlightBooking } from '../defaults';

import bookingUpdateMutation from '../mutations/booking_update_mutation';
import bookingCreateMutation from '../mutations/booking_create_mutation';

import {
  queriesReady,
  queryReady,
  queryJustReady,
  typeInput,
  pickValues,
  mapOmitValues,
  getSelectable,
} from '../lib/utils';

import aircraftListQuery from '../queries/aircraft_list_query';
import bookingQuery from '../queries/booking_query';
import contactListQuery from '../queries/contact_list_query';

moment.updateLocale('en-nz');

let isInitialised = false;

class NonFlightForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: !!this.props.params.id,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderEmployeesFieldArray = this.renderEmployeesFieldArray.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.props.bookingQuery &&
      queryJustReady(this.props.bookingQuery, nextProps.bookingQuery)
    ) {
      if (
        get(nextProps.bookingQuery, 'data.audit_created_at') &&
        this.state.tabKey === 2
      ) {
        this.setState({
          tabKey: 3,
        });
      }
    }
  }

  componentWillUnmount() {
    isInitialised = false;
  }

  isLoaded(props) {
    return !this.isLoading(props || this.props);
  }

  isLoading(props) {
    const newProps = props || this.props;
    return !queriesReady(
      [newProps.bookingQuery, true], // ignore if undefined
      newProps.aircraftListQuery,
      newProps.employeeListQuery
    );
  }

  handleSubmit(data) {
    const submitData = cloneDeep(data);
    const adminId = this.props.currentContact.id;
    if (this.state.updating) {
      submitData.updated_by_admin_id = adminId;
    } else {
      submitData.employee_ids = submitData.booking_employees_attributes.map(
        (bea) => bea.employee_id
      );
      delete submitData.booking_employees_attributes;
      submitData.updated_by_admin_id = adminId;
      submitData.created_by_admin_id = adminId;
    }
    // console.log('submit')
    // console.log(submitData)
    // return

    this.props.mutationSet(true);
    let mutation;
    let mutationMessage;
    const mutationData = { variables: { input: typeInput(submitData) } };
    if (this.state.updating) {
      mutation = this.props.bookingUpdateMutation;
      mutationMessage = 'Booking update';
      mutationData.variables.id = this.props.params.id;
    } else {
      mutation = this.props.bookingCreateMutation;
      mutationMessage = 'Booking create';
    }
    return mutation(mutationData)
      .then(() => {
        this.props.mutationSuccess(mutationMessage);
        this.props.navigate(this.props.currentSettingsReturnRoute);
      })
      .catch((err) => this.props.mutationFailure(err, true));
  }

  getSelectableAircrafts() {
    const aircrafts = get(this.props, 'aircraftListQuery.data', []);
    return getSelectable(
      aircrafts,
      get(this.props, 'formValues.aircraft_id'),
      'registration_abbreviated'
    );
  }

  renderEmployeesFieldArray(bookingEmployees) {
    return (
      <EmployeeInputField
        change={this.props.change}
        employees={this.props.employeeListQuery.data}
        bookingEmployees={bookingEmployees}
        formValues={pick(this.props.formValues, ['booking_employees_attributes'])}
      />
    );
  }

  statusesFor() {
    switch (get(this.props, 'formValues.calendar_type')) {
      case 'meeting':
        return [
          ['confirmed', 'Confirmed'],
          ['to_be_confirmed', 'To be confirmed'],
          ['cancelled', 'Cancelled'],
        ];
      case 'leave':
        return [
          ['tentative', 'Request'],
          ['confirmed', 'Confirmed'],
          ['cancelled', 'Cancelled'],
        ];
      case 'rostered':
        return [
          ['confirmed', 'Priority'],
          ['to_be_confirmed', 'Planned'],
        ];
      case 'banner':
        return [
          ['confirmed', 'Confirmed'],
          ['to_be_confirmed', 'To be confirmed'],
        ];
      default:
        return [];
    }
  }

  renderStatus() {
    if (get(this.props, 'formValues.calendar_type') !== 'maintenance') {
      return (
        <Row>
          <Field
            type="text"
            name="status"
            size="sm"
            labelWidth={0}
            inputWidth={12}
            noTab
            defaultSelectOption={false}
            component={InputField}
            asElement="select"
            selectOptions={this.statusesFor().map((status) => ({
              id: status[0],
              name: status[1],
            }))}
          >
            Status
          </Field>
        </Row>
      );
    }
    return null;
  }

  renderAircraft() {
    return (
      <Row>
        <Field
          type="text"
          name="aircraft_id"
          size="sm"
          labelWidth={0}
          inputWidth={12}
          noTab
          component={InputField}
          asElement="select"
          selectOptions={this.getSelectableAircrafts()}
        >
          Aircraft
        </Field>
      </Row>
    );
  }

  renderEmployees() {
    return (
      <FieldArray
        name="booking_employees_attributes"
        component={this.renderEmployeesFieldArray}
      />
    );
  }

  renderTarget() {
    if (get(this.props, 'formValues.calendar_type') === 'maintenance') {
      return this.renderAircraft();
    }
    if (get(this.props, 'formValues.calendar_type') !== 'banner') {
      return this.renderEmployees();
    }
    return null;
  }

  renderOverlay() {
    if (this.props.currentSettingsMutating || this.isLoading()) {
      return <Loader />;
    }
    return null;
  }

  renderData() {
    if (
      isInitialised &&
      this.isLoaded() &&
      (!this.state.updating || get(this.props, 'formValues.id'))
    ) {
      const { handleSubmit, submitting, error } = this.props;

      return (
        <Form onSubmit={handleSubmit(this.handleSubmit)}>
          <BookingTitle
            updating={this.state.updating}
            reference={get(this.props, 'bookingQuery.data.reference')}
            startAt={get(this.props, 'bookingQuery.data.start_at')}
            calendarType={get(this.props, 'formValues.calendar_type')}
          />
          {error && <strong>{error}</strong>}
          <Row>
            <Col xs={3}>
              {this.renderStatus()}
              {this.renderTarget()}
            </Col>
            <Col xs={9}>
              <Field
                blurOnly
                type="text"
                name="job_notes"
                component={InputField}
                size="sm"
                labelWidth={0}
                inputWidth={12}
                asElement="textarea"
                rows={4}
                noTab
              >
                Job Notes
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Field
                type="text"
                name="start_at"
                component={StartDateAndTimeInputFields}
                {...pick(this.props.formValues, ['start_at'])}
                withoutTime={
                  get(this.props, 'formValues.calendar_type') ===
                  this.props.currentSettingsBookingCalendarTypeBanner
                }
              >
                Start Date
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Field
                type="text"
                name="end_at"
                component={EndDateAndTimeInputFields}
                {...pick(this.props.formValues, ['end_at', 'start_at'])}
                withoutTime={
                  get(this.props, 'formValues.calendar_type') ===
                  this.props.currentSettingsBookingCalendarTypeBanner
                }
              >
                End Date
              </Field>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              {this.state.updating && (
                <BookingCreators
                  contactsDataSelector={this.props.contactsDataSelector}
                  {...pick(get(this.props.bookingQuery, 'data', {}), [
                    'created_by_admin_id',
                    'created_at',
                    'updated_by_admin_id',
                    'updated_at',
                  ])}
                />
              )}
            </Col>
            <Col sm="auto">
              <ButtonGroup>
                <LinkContainer to={this.props.currentSettingsReturnRoute}>
                  <Button type="reset" variant="danger" disabled={submitting}>
                    Cancel
                  </Button>
                </LinkContainer>
                <Button type="submit" variant="primary" disabled={submitting}>
                  {submitting && <Glyphicon glyph="refresh" spin />}{' '}
                  {this.state.updating ? 'Update' : 'Create'}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form>
      );
    }
    return null;
  }

  render() {
    return (
      <Row className="mt-3">
        <Col sm={12} className="m-0 p-0">
          {this.renderOverlay()}
          {this.renderData()}
        </Col>
      </Row>
    );
  }
}

const bookingWhiteList = [
  'aircraft_id',
  'calendar_type',
  'end_at',
  'id',
  'job_notes',
  'status',
  'start_at',
];

function validate() {
  return {};
}

function pickInitialValues(
  bookingQueryInitial,
  currentSettingsBookingCollectionStartDate,
  currentSettingsBookingRequestedCalendarType,
  updating
) {
  if (!isInitialised) {
    if (!updating) {
      isInitialised = true;
      return defaultNonFlightBooking(
        currentSettingsBookingCollectionStartDate,
        currentSettingsBookingRequestedCalendarType
      );
    }
    if (queryReady(bookingQueryInitial)) {
      isInitialised = true;
      const bookingQueryInitialData = cloneDeep(bookingQueryInitial.data);
      const booking = pickValues(bookingQueryInitialData, bookingWhiteList);
      booking.booking_employees_attributes = mapOmitValues(
        get(bookingQueryInitialData, 'bookingEmployees')
      );
      return booking;
    }
  }
  return undefined;
}

function mapStateToProps(state, props) {
  const initialValues = pickInitialValues(
    props.bookingQuery,
    state.currentSettings.bookingCollectionStartDate,
    state.currentSettings.bookingRequestedCalendarType,
    !!props.params.id
  );
  return {
    initialValues,
    currentContact: state.currentContact,
    currentSettingsBookingCalendarTypeBanner:
      state.currentSettings.booking_calendar_type_banner,
    currentSettingsReturnRoute: state.currentSettings.returnRoute,
    currentSettingsMutating: state.currentSettings.mutating,
    contactsDataSelector: contactsData(props),
    formValues: getFormValues('NonFlightForm')(state),
  };
}

export default compose(
  graphql(bookingCreateMutation, {
    name: 'bookingCreateMutation',
  }),
  graphql(bookingUpdateMutation, {
    name: 'bookingUpdateMutation',
  }),
  graphql(bookingQuery, {
    name: 'bookingQuery',
    skip: (props) => !props.params.id,
    options: (props) => ({
      variables: { id: props.params.id },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(aircraftListQuery, {
    name: 'aircraftListQuery',
  }),
  graphql(contactListQuery, {
    name: 'employeeListQuery',
    options: { variables: { role: 'employee' } },
  }),
  connect(mapStateToProps, {
    mutationSuccess,
    mutationFailure,
    mutationSet,
  }),
  reduxForm({
    form: 'NonFlightForm',
    validate,
  })
)(NonFlightForm);
