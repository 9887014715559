import { createSelector } from 'reselect';

import find from 'lodash.find';
// import flatten from 'lodash.flatten';
import get from 'lodash.get';
// import uniqBy from 'lodash.uniqby';
// import { apolloClient } from '../index';
import apolloClient from '../lib/apollo_client';

import aircraftListQuery from '../queries/aircraft_list_query';
import aircraftCandidateHobbListQuery from '../queries/aircraft_candidate_hobb_list_query';
import aircraftEngineListQuery from '../queries/aircraft_engine_list_query';
import aircraftTypeListQuery from '../queries/aircraft_type_list_query';
import contactListQuery from '../queries/contact_list_query';
import flightTypeListQuery from '../queries/flight_type_list_query';
import flightTypeCategoryListQuery from '../queries/flight_type_category_list_query';
import fuelBowserListQuery from '../queries/fuel_bowser_list_query';
import fuelTankerListQuery from '../queries/fuel_tanker_list_query';
import locationListQuery from '../queries/location_min_list_query';

const defaultArray = [];

function apolloQuery(query, variables = {}) {
  try {
    return JSON.stringify(
      get(apolloClient.readQuery({ query, variables }), 'data', defaultArray)
    );
  } catch (err) {
    return JSON.stringify(defaultArray);
  }
}

const aircrafts = () => apolloQuery(aircraftListQuery);
const aircraftCandidateHobbs = () => apolloQuery(aircraftCandidateHobbListQuery);
const aircraftEngines = () => apolloQuery(aircraftEngineListQuery);
const aircraftTypes = () => apolloQuery(aircraftTypeListQuery);
const flightTypes = () => apolloQuery(flightTypeListQuery);
const flightTypeCategories = () => apolloQuery(flightTypeCategoryListQuery);
const fuelTankers = () => apolloQuery(fuelTankerListQuery);
const fuelBowsers = () => apolloQuery(fuelBowserListQuery);
const locations = () => apolloQuery(locationListQuery);

// const chargeables = (props) => apolloQuery(contactListQuery, { role: 'chargeable' });
// const employees = (props) => apolloQuery(contactListQuery, { role: 'employee' });
// const managers = (props) => apolloQuery(contactListQuery, { role: 'manager' });
// const passengers = (props) => apolloQuery(contactListQuery, { role: 'passenger' });
// const providers = (props) => apolloQuery(contactListQuery, { role: 'provider' });
// const pilots = (props) => apolloQuery(contactListQuery, { role: 'pilot' });

const contacts = () => apolloQuery(contactListQuery);

export const aircraftCandidateHobbsData = createSelector(
  [aircraftCandidateHobbs],
  (aircraftCandidateHobbs) =>
    JSON.parse(aircraftCandidateHobbs).reduce((result, a) => {
      result[a.id] = {
        starts: a.candidates.filter((c) => c[0]).map((c) => c[0]),
        ends: a.candidates.filter((c) => c[1]).map((c) => c[1]),
      };
      return result;
    }, {})
);

export const aircraftsData = createSelector(
  [aircrafts, aircraftTypes],
  (aircrafts, aircraftTypes) => {
    aircraftTypes = JSON.parse(aircraftTypes);
    return JSON.parse(aircrafts).reduce((result, a) => {
      const t = find(aircraftTypes, { id: a.aircraft_type_id });
      result[a.id] = {
        registrationAbbreviated: get(a, 'registration_abbreviated'),
        aircraftTypeName: get(t, 'name'),
        aircraftTypeMultiengine: get(t, 'multiengine', false),
      };
      return result;
    }, {});
  }
);

export const aircraftEnginesData = createSelector([aircraftEngines], (aircraftEngines) =>
  JSON.parse(aircraftEngines).reduce((result, a) => {
    result[a.id] = {
      name: get(a, 'name'),
    };
    return result;
  }, {})
);

// export const contactsData = createSelector(
//   [chargeables, employees, managers, passengers, providers, pilots],
//   (chargeables, employees, managers, passengers, providers, pilots) => {
//     const contacts = uniqBy(
//       flatten(
//         [chargeables, employees, managers, passengers, providers, pilots]
//           .map((c) => JSON.parse(c))
//           .filter((c) => c.length > 0)
//       ),
//       'id'
//     );
//     return contacts.reduce((result, a) => {
//       result[a.id] = {
//         fullName: get(a, 'fullName'),
//         employeeColor: get(a, 'employee_color'),
//         weight: get(a, 'weight'),
//       };
//       return result;
//     }, {});
//   }
// );

export const contactsData = createSelector([contacts], (contacts) =>
  JSON.parse(contacts).reduce((result, a) => {
    result[a.id] = {
      fullName: get(a, 'fullName'),
      employeeColor: get(a, 'employee_color'),
      weight: get(a, 'weight'),
    };
    return result;
  }, {})
);

export const flightTypesData = createSelector([flightTypes], (flightTypes) =>
  JSON.parse(flightTypes).reduce((result, a) => {
    result[a.id] = {
      name: get(a, 'name'),
    };
    return result;
  }, {})
);

export const flightTypeCategoriesData = createSelector(
  [flightTypeCategories],
  (flightTypeCategories) =>
    JSON.parse(flightTypeCategories).reduce((result, a) => {
      result[a.id] = {
        name: get(a, 'name'),
      };
      return result;
    }, {})
);

export const fuelTankersData = createSelector([fuelTankers], (fuelTankers) =>
  JSON.parse(fuelTankers).reduce((result, a) => {
    result[a.id] = {
      name: get(a, 'name'),
    };
    return result;
  }, {})
);

export const fuelBowsersData = createSelector([fuelBowsers], (fuelBowsers) =>
  JSON.parse(fuelBowsers).reduce((result, a) => {
    result[a.id] = {
      name: get(a, 'name'),
    };
    return result;
  }, {})
);

export const locationsData = createSelector([locations], (locations) =>
  JSON.parse(locations).reduce((result, a) => {
    result[a.id] = {
      longName: get(a, 'long_name'),
      fullName: get(a, 'fullName'),
      shorthandName: get(a, 'shorthand_name'),
      latitude: get(a, 'latitude'),
      longitude: get(a, 'longitude'),
      landingFee: get(a, 'landing_fee'),
    };
    return result;
  }, {})
);
