import { Modal, Button } from 'react-bootstrap';
import { Component } from 'react';

import InputField from '../form/input_field';

class PassengerAddModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      weight: 0,
    };
    this.handleFirstNameChange = this.handleFirstNameChange.bind(this);
    this.handleLastNameChange = this.handleLastNameChange.bind(this);
    this.handleWeightChange = this.handleWeightChange.bind(this);

    this.handleAddCancel = this.handleAddCancel.bind(this);
    this.handleAddSubmit = this.handleAddSubmit.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.show && nextProps.show) {
      this.setState({
        firstName: nextProps.label.split(' ')[0],
        lastName: nextProps.label.split(' ')[1] || 'LastName',
        weight: nextProps.weight || 0,
      });
    }
  }

  handleAddCancel() {
    this.resetState();
    if (this.props.handleAddPassengerCancel) {
      this.props.handleAddPassengerCancel();
    }
  }

  handleAddSubmit() {
    if (this.props.handleAddPassengerSubmit) {
      this.props.handleAddPassengerSubmit(this.state);
    }
    this.resetState();
  }

  handleFirstNameChange(e) {
    this.setState({
      firstName: e.currentTarget.value,
    });
  }

  handleLastNameChange(e) {
    this.setState({
      lastName: e.currentTarget.value,
    });
  }

  handleWeightChange(e) {
    this.setState({
      weight: e.currentTarget.value,
    });
  }

  resetState() {
    this.setState({
      firstName: '',
      lastName: '',
      weight: 0,
    });
  }

  render() {
    const { show, index, flightSegmentIndex, label } = this.props;

    return (
      <Modal
        id={`booking-flight-segment-${flightSegmentIndex}-add-passenger-modal-${index}`}
        show={show}
        onHide={this.handleAddCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title>{`Create new passenger ${label}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputField
            type="text"
            size="sm"
            labelWidth={3}
            inputWidth={9}
            input={{
              name: `booking-flight-segment-${flightSegmentIndex}-add-passenger-modal-${index}-firstname`,
              value: this.state.firstName,
              onChange: this.handleFirstNameChange,
            }}
          >
            First Name
          </InputField>
          <InputField
            type="text"
            size="sm"
            labelWidth={3}
            inputWidth={9}
            input={{
              name: `booking-flight-segment-${flightSegmentIndex}-add-passenger-modal-${index}-lastname`,
              value: this.state.lastName,
              onChange: this.handleLastNameChange,
            }}
          >
            Last Name
          </InputField>
          <InputField
            type="text"
            size="sm"
            labelWidth={3}
            inputWidth={9}
            input={{
              name: `booking-flight-segment-${flightSegmentIndex}-add-passenger-modal-${index}-weight`,
              value: this.state.weight,
              onChange: this.handleWeightChange,
            }}
          >
            Weight
          </InputField>
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" variant="danger" onClick={this.handleAddCancel}>
            Cancel
          </Button>
          <Button
            type="button"
            variant="primary"
            disabled={!this.state.firstName}
            onClick={this.handleAddSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default PassengerAddModal;
